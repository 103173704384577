import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "../../styles/App.scss";

const PaymentFailure = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const closeWindow = () => {
      window.close();
    };

    const timer = setTimeout(closeWindow, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={'payment'}>
      <i className="pi pi-times-circle" style={{ fontSize: '120px', color: 'red' }} />
      <h1 style={{ marginTop: '20px', fontSize: '30px'}}>{t('payment.paymentFailed')}</h1>
    </div>
  );
};

export default PaymentFailure;
