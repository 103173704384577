import React, { ReactNode } from "react";

type LayoutProps = {
  children: ReactNode;
};

const MainLayout: React.FC<LayoutProps> = ({ children }) => {
  return <div className="container mx-auto min-h-[580px]">{children}</div>;
};

export default MainLayout;
