import React, { useLayoutEffect } from "react";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { useEffect, useRef, useState } from "react";
import {
  CreateOrder,
  addCoupon,
  addToCart,
  avilableCoupon,
  removeCoupon,
  showCart,
} from "../services";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { CreateOrderDTO } from "../modules/Retailer.modules";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { Tag } from "primereact/tag";
import TextResult from "../components/TextResult";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import { CREATE_ORDERS } from "../permissions/permissions";
import CartCard from "./Cards/CartCard";
type Props = {
  cartItems: any[];
  deleteFromStoreCart: (data: any) => void;
  increaseCartStore: (data: any) => void;
  decreaseCartStore: (data: any) => void;
};
const Ncart = ({
  cartItems,
  deleteFromStoreCart,
  increaseCartStore,
  decreaseCartStore,
}: Props) => {
  const [cartData, setCartdata] = useState<any>(cartItems ?? []);
  const [Cart, setCart] = useState<any>();
  const [quantity, setquant] = useState<any>(3);
  const [cartID, setCartid] = useState<any>();
  const [subProductID, setsubProductid] = useState<any>();
  const [ProductId, setProductId] = useState<number>();
  const [copuondata, setCopuondata] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [propEmpty, setPropEmpty] = useState<any>();
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const Cartform = useFormik<CreateOrderDTO>({
    initialValues: new CreateOrderDTO(),
    validateOnChange: true,
    onSubmit: () => {
      Cartform.values.cart_id = cartID;
      CreateOrder(Cartform.values);
      setShow(false);
    },
  });

  const getCoupon = (proId: any) => {
    let data = {
      product_ids: proId,
    };
    avilableCoupon(data).then((res: any) => setCopuondata(res));
  };
  const addYourCoupon = (code: string) => {
    let data = {
      coupon_code: code,
      connect_store_id: parseInt(id as any),
    };
    // When check with coupon maybe addCoupon should replaced
    addCoupon(data);
  };
  useEffect(() => {
    showCart(parseInt(id as any)).then((res: any) => {
      if (typeof res.data === "string" && res !== null) {
        setPropEmpty(res.data);
      } else {
        //setCartdata(res?.cartItems);
        setCartid(res?.id);
        setCart(res);
        const proId = res?.cartItems?.map((data: any) => data.product_id);
        getCoupon(proId);
      }
    });
  }, []);
  useLayoutEffect(() => {}, [cartData]);
  const renderGridItem = (data: any) => {
    return (
      <CartCard
        item={data}
        increaseCartStore={increaseCartStore}
        decreaseCartStore={decreaseCartStore}
        deleteFromStoreCart={deleteFromStoreCart}
      />
    );
  };

  const itemTemplate = (product: any, layout: any) => {
    if (!product) {
      return;
    }

    if (layout === "list") return renderGridItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };
  const handleUseCoupon = (code: string) => {
    addYourCoupon(code);
  };
  const handleRemoveCoupon = (code: string) => {
    let Coupondata = {
      connect_store_id: parseInt(id as any),
    };
    removeCoupon(Coupondata);
  };
  return (
    <MainLayout>
      <div className="cart-layout">
        {propEmpty && (
          <>
            <div className="mx-auto h-full">
              <p className="m-4 py-5 text-sm font-normal text-center">
                {propEmpty}
              </p>
            </div>
          </>
        )}
        {!propEmpty && cartData.length > 0 && (
          <div>
            {copuondata?.length > 0 ? (
              <div className="mt-2">
                {t("cartPage.TheseAvailableCouponsToBeUsed") ??
                  "These available Coupons to be used:"}
              </div>
            ) : (
              <></>
            )}

            <div className="grid mt-2 gap-3">
              {copuondata?.map((coupon: any) => (
                <React.Fragment key={coupon.code}>
                  {Cart.coupon_code === coupon.code ? (
                    <div className="warning-copun">
                      <Tag className="code-coupon" value={coupon.code}></Tag>
                      <h6 onClick={() => handleRemoveCoupon(coupon.code)}>
                        {t("cartPage.Unset") ?? "Unset"}
                      </h6>
                    </div>
                  ) : (
                    <div className="warning-copun">
                      <Tag className="code-coupon" value={coupon.code}></Tag>
                      <h6 onClick={() => handleUseCoupon(coupon.code)}>
                        {" "}
                        {t("cartPage.Set") ?? "Set"}
                      </h6>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="card cart-view">
              <DataView
                value={cartItems}
                itemTemplate={itemTemplate}
                layout={"list"}
                className="flex"
              />
              <div className="formgrid grid">
                <div className="field col">
                  <label className="m-2" htmlFor="TotalAmount">
                    {t("cartPage.TotalAmount") ?? "Total Amount"}
                  </label>
                  <InputNumber
                    placeholder={t("cartPage.TotalAmount") ?? "Total Amount"}
                    value={Math?.round(Cart?.total_amount)}
                    readOnly
                  />
                </div>
                <div className="field col">
                  <label className="m-2" htmlFor="DeliveryFee">
                    {t("cartPage.DeliveryFee") ?? "Delivery Fee"}
                  </label>
                  <InputNumber
                    placeholder={t("cartPage.DeliveryFee") ?? "Delivery Fee"}
                    value={Math?.round(Cart?.delivery_fee)}
                    readOnly
                  />
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label className="m-2" htmlFor="TotalVolume">
                    {" "}
                    {t("cartPage.TotalVolume") ?? "Total Volume"}
                  </label>
                  <InputNumber
                    placeholder={t("cartPage.TotalVolume") ?? "Total Volume"}
                    value={Math?.round(Cart?.total_volume)}
                    readOnly
                    disabled
                  />
                </div>
                <div className="field col ">
                  <label className="m-2" htmlFor="TotalWeight">
                    {t("cartPage.TotalWeight") ?? "Total Weight"}
                  </label>
                  <InputNumber
                    placeholder={t("cartPage.TotalWeight") ?? "Total Weight"}
                    value={Math?.round(Cart?.total_weight)}
                    readOnly
                  />
                </div>
              </div>
              <div className="formgrid grid">
                {Cart?.cost_before_coupon > 0 ? (
                  <div className="field col">
                    <label className="mb-2" htmlFor="Status">
                      {t("cartPage.CostBeforeCoupon") ?? "Cost Before Coupon"}
                    </label>
                    <InputNumber
                      placeholder={t("cartPage.DeliveryFee") ?? "Delivery Fee"}
                      value={Math?.round(Cart?.cost_before_coupon)}
                      readOnly
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {CREATE_ORDERS() ? (
                <Button
                  className="mt-3 change-color-btn"
                  label={t("cartPage.CreateOrder") ?? "Create Order"}
                  onClick={() => setShow(true)}
                ></Button>
              ) : (
                <></>
              )}
            </div>
            <></>
          </div>
        )}

        <Dialog
          header={t("cartPage.CreateOrder") ?? "Create Order"}
          visible={show}
          className="	md:w-35rem lg:w-35rem"
          onHide={() => setShow(false)}
          footer={
            <>
              <div>
                <Button
                  label={t("cartPage.Save") ?? "Save"}
                  severity="danger"
                  size="small"
                  onClick={() => Cartform.handleSubmit()}
                  className="mt-4"
                ></Button>
                <Button
                  label={t("cartPage.Cancel") ?? "Cancel"}
                  severity="danger"
                  outlined
                  size="small"
                  onClick={() => setShow(false)}
                  className="mt-4"
                ></Button>
              </div>
            </>
          }
        >
          <div className="grid formgrid">
            <div className="field md:col-6 lg:col-4">
              <label className="m-2" htmlFor="CustomerFullName">
                {t("cartPage.CustomerFullName") ?? "Customer Full Name"}
              </label>
              <InputText
                placeholder={
                  t("cartPage.CustomerFullName") ?? "Customer Full Name"
                }
                name="end_customer_full_name"
                value={Cartform?.values?.end_customer_full_name}
                onChange={(e) =>
                  Cartform.setFieldValue(
                    "end_customer_full_name",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="field md:col-6 lg:col-4">
              <label className="m-2" htmlFor="CustomerEmail">
                {t("cartPage.CustomerEmail") ?? "Customer Email"}
              </label>
              <InputText
                placeholder={t("cartPage.CustomerEmail") ?? "Customer Email"}
                name="end_customer_email"
                value={Cartform?.values?.end_customer_email}
                onChange={(e) =>
                  Cartform.setFieldValue("end_customer_email", e.target.value)
                }
              />
            </div>
            <div className="field md:col-6 lg:col-4">
              <label className="m-2" htmlFor="CustomerPhoneNumber">
                {t("cartPage.CustomerPhoneNumber") ?? "Customer Phone Number"}
              </label>
              <InputText
                placeholder={
                  t("cartPage.CustomerPhoneNumber") ?? "Customer Phone Number"
                }
                name="end_customer_phone_number"
                value={Cartform?.values?.end_customer_phone_number}
                onChange={(e) =>
                  Cartform.setFieldValue(
                    "end_customer_phone_number",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
          <div className="grid formgrid mt-2">
            <div className="field md:col-6 lg:col-4">
              <label className="m-2" htmlFor="CustomerAddress">
                {t("cartPage.CustomerAddress") ?? "Customer Address"}
              </label>
              <InputText
                placeholder={
                  t("cartPage.CustomerAddress") ?? "Customer Address"
                }
                name="end_customer_address"
                value={Cartform?.values?.end_customer_address}
                onChange={(e) =>
                  Cartform.setFieldValue("end_customer_address", e.target.value)
                }
              />
            </div>
            <div className="field md:col-6 lg:col-4">
              <label className="m-2" htmlFor="CustomerNotes">
                {" "}
                {t("cartPage.CustomerNotes") ?? "Customer Notes"}
              </label>
              <InputText
                placeholder={t("cartPage.CustomerNotes") ?? "Customer Notes"}
                name="end_customer_notes"
                value={Cartform?.values?.end_customer_notes}
                onChange={(e) =>
                  Cartform.setFieldValue("end_customer_notes", e.target.value)
                }
              />
            </div>
          </div>
        </Dialog>
      </div>
    </MainLayout>
  );
};

export default Ncart;
