import { htmlToText } from "html-to-text";

export function concatenateAndMoveDimensionsToEnd(array1, array2) {
  const concatenatedArray = array1.concat(array2);

  const dimensionsIndex = concatenatedArray.indexOf("dimensions");
  if (dimensionsIndex !== -1) {
    concatenatedArray.splice(dimensionsIndex, 1);
  }
  concatenatedArray.push("dimensions");

  return concatenatedArray;
}

export function trimText(text) {
  if (text.length > 35) {
    return { trimmedText: text.substring(0, 35) + "...", isTrimmed: true };
  } else {
    return { trimmedText: text, isTrimmed: false };
  }
}

// Function to convert an object to a query string
export function objectToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (Array.isArray(value)) {
        keyValuePairs.push(
          encodeURIComponent(key) +
            "=" +
            encodeURIComponent(JSON.stringify(value))
        );
      } else {
        keyValuePairs.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(value)
        );
      }
    }
  }
  return keyValuePairs.join("&");
}

// The function convert xml to string
const parseXML = (xmlString) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");
  return xmlDoc.documentElement.textContent || "";
};

// Function to determine if a string is HTML
const isHTML = (str) => {
  const div = document.createElement("div");
  div.innerHTML = str;
  return div.childNodes.length > 0 && div.childNodes[0].nodeType === 1;
};

// Function to determine format of incoming text and process accordingly to get the plain text result
export const processProductInfo = (info) => {
  const isXMLFormat = info.trim().startsWith("<") && info.trim().endsWith(">");

  if (isXMLFormat) {
    return parseXML(info) ?? "";
  }
  return htmlToText(info, { wordwrap: 130 });
};
