import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import {
  connectProductToLive,
  getImportedList,
  listStores,
  removeFromImportList,
  removeAllProductFromImportList,
  saveChangeProduct,
  pushAllProductsToStore,
} from "../services/index";
import SearchImport from "../components/SearchVeFilters/SearchImport";
import ImportListCard from "../components/Cards/ImportListCard";
import TextResult from "../components/TextResult";
import {
  Category,
  FilterDTO,
  IPagination,
  Item,
} from "../modules/Retailer.modules";
import PaginationTemplate from "../components/templates/PaginationTemplate";
import MainLayout from "../containers/MainLayout";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../components/Loading";
import { useFormik } from "formik";

function ImportList() {
  const [items, setItems] = useState<Item[]>([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [stores, setStores] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState<Item>();
  const [propEmpty, setPropEmpty] = useState<any>();
  const [propEmptySearch, setPropEmptySearch] = useState<any>();
  const [storeItem, setStoreItem] = useState<any>();
  const [first, setFirst] = useState(0);
  const [pagination, setPagination] = useState<IPagination>();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const [loading, setLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [searchedProducts, setSearchedProducts] = useState<any>([]);
  const [selectedCategoriesEnd, setSelectedCategoriesEnd] = useState<
    Category[]
  >([]);
  const [selectedMinPrice, setSelectedMinPrice] = useState<number>(0);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState<number>(0);
  const [selectedAvailableProduct, setSelectedAvailableProduct] =
    useState<number>(0);
  const [selectedPublishedOnStore, setSelectedPublishedOnStore] =
    useState<number>(0);
  const [visibleFilter, setVisibleFilter] = useState(false);
  let basePage = 1;
  const initialValues: FilterDTO = {
    categories: [],
    minPrice: 0,
    maxPrice: 0,
    available: 0,
    publishedOnStore: 0,
  };
  const filterImportFormik = useFormik<FilterDTO>({
    initialValues: initialValues,
    validateOnChange: true,
    onSubmit: (values) => {
      let selectedCategories = filterImportFormik.values.categories;
      if (filterImportFormik.values.categories!.length > 0) {
        selectedCategories = filterImportFormik.values.categories!.map(
          (cat: any) => cat.id
        );
      } else {
        selectedCategories = [];
      }
      let dataToFilter =
        selectedCategories.length > 0
          ? {
              categories: selectedCategories,
              minPrice: values.minPrice,
              maxPrice: values.maxPrice,
              available: values.available,
              publishedOnStore: values.publishedOnStore,
            }
          : {
              minPrice: values.minPrice,
              maxPrice: values.maxPrice,
              available: values.available,
              publishedOnStore: values.publishedOnStore,
            };

      setSelectedMinPrice(filterImportFormik.values.minPrice ?? 0);
      setSelectedMaxPrice(filterImportFormik.values.maxPrice ?? 0);
      setSelectedAvailableProduct(filterImportFormik.values.available ?? 0);
      setSelectedPublishedOnStore(
        filterImportFormik.values.publishedOnStore ?? 0
      );
      setSelectedCategoriesEnd(filterImportFormik.values.categories ?? []);
      getImportedList(basePage, undefined, dataToFilter).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }

            setPropEmpty("");
            setPropEmptySearch("");
            setItems(res);
            setFilteredProducts(res);
          }
        }
      });
    },
  });
  const onHideFilter = () => {
    setPropEmpty("");
    setPropEmptySearch("");
    setVisibleFilter(false);

    getImportedList(basePage).then((res: any) => {
      if ("image" in res && "message" in res) {
        setPropEmpty(res);
      } else {
        if (res.pagination) {
          setPagination(res.pagination);
        }

        setItems(res);
        setFilteredProducts(res);
      }
    });
  };
  useEffect(() => {
    setLoading(true);

    getImportedList(basePage).then((res: any) => {
      if ("image" in res && "message" in res) {
        setPropEmpty(res);
      } else {
        if (res.pagination) {
          setPagination(res.pagination);
        }
        setItems(res);
      }
      setLoading(false);
    });

    listStores()
      .then((res: any) => {
        setStores(res);
      })
      .catch((err) => console.error(err));
  }, []);

  const onPageChange = (text: string) => {
    let currentPage = 1;
    switch (text) {
      case "next":
        currentPage = pagination!.current_page + 1;
        break;
      case "prev":
        currentPage = pagination!.current_page - 1;
        break;
      case "first":
        currentPage = 1;
        break;
      case "last":
        currentPage = pagination!.total_pages;
        break;

      default:
      // Default case
    }

    getImportedList(currentPage).then((newProducts) => {
      setItems(newProducts);
      setPagination(newProducts!.pagination);
      setFirst(currentPage);
    });
  };
  const openModal = (item: any) => {
    setSelectedItemId(item.id);
    setSelectedItem(item);
    setVisible(true);
  };
  function removeProduct(data: any) {
    let productToDelete = { product_id: data.id };

    removeFromImportList(productToDelete)
      .then((res) => {
        if (res) {
          const remainingItems = items.filter((e) => e.id !== data.id);
          setItems((prevItems: Item[]) =>
            prevItems.filter((e) => e.id !== data.id)
          );
          if (remainingItems.length === 0) {
            setPropEmpty(res);
          }
        }
      })
      .catch((err) => {});
  }
  function removeAll() {
    removeAllProductFromImportList().then((res) => {
      setItems([]);
      setPropEmpty(res);
    });
  }
  const pushToStore = (item: any) => {
    setVisible(false);
    const dataToPush = {
      connect_store_id: selectedStore,
      product_id: selectedItem?.id,
    };
    setStoreItem(item);

    saveChangeProduct(dataToPush)
      .then(() => {
        connectProductToLive(dataToPush).then((res) => {
          if (res !== undefined) {
            const index = items.findIndex(
              (item: any) => item.id === selectedItem?.id
            );
            if (index !== -1) {
              let updatedItems = [...items];
              updatedItems[index] = res;
              setItems(updatedItems);
            }
          }
        });
      })
      .catch((err) => {});
  };
  const footerContent = (stores: any) => (
    <div className="mt-3">
      <Button
        label={t("importListPage.Cancel") ?? "Cancel"}
        icon="pi pi-times"
        onClick={() => {
          setVisible(false);
        }}
        severity="danger"
        iconPos={isRTL ? "right" : "left"}
        outlined
      />
      <Button
        label={t("importListPage.Push") ?? "Push"}
        icon="pi pi-check"
        severity="danger"
        iconPos={isRTL ? "right" : "left"}
        onClick={() => {
          pushToStore(stores);
        }}
        autoFocus
        disabled={stores.length > 0 ? false : true}
      />{" "}
    </div>
  );
  const pushOptions = (stores: any, selectedItem?: Item) => {
    const integrationTypes = Array.isArray(stores)
      ? stores.map((store: any) => ({
          name: store?.integrationSupport?.type,
          value: store?.id,
        }))
      : [];
    const publishedIntegrationTypes =
      selectedItem?.published_in_stores?.map(
        (publishedStore: any) => publishedStore.id
      ) || [];
    const availableIntegrationTypes =
      integrationTypes?.filter(
        (integrationType: any) =>
          !publishedIntegrationTypes?.includes(integrationType.value)
      ) || [];

    return availableIntegrationTypes;
  };
  const itemListWithRatio = items?.map((item) => {
    let tempInfo =
      item?.retailer_products_temporary?.sub_products_temporary || [];
    const subProductsWithRatio = item.subProducts?.map((subProduct, index) => {
      let ratio =
        tempInfo.length > 0
          ? tempInfo[index]?.ratio
          : subProduct.ratio > 0
          ? subProduct.ratio
          : item.ratio;
      const updatedSubProduct = { ...subProduct };

      let salesPrice =
        tempInfo.length > 0
          ? tempInfo[index]?.price
          : subProduct.recommended_price;

      const { height, width, length, weight, ...rest } = updatedSubProduct;

      const dimensions = { height, width, length, weight };

      const updatedSubProductWithRatioAndDimension = {
        ...rest,
        ratio: ratio,
        recommended_price: salesPrice,
        dimensions: dimensions,
      };

      return updatedSubProductWithRatioAndDimension;
    });

    return {
      ...item,
      subProducts: subProductsWithRatio,
    };
  });
  const handleSearch = (search: any) => {
    if (search.trim() !== "") {
      getImportedList(basePage, search, undefined).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }

            setItems(res);
            setSearchedProducts(res);
          }
        }
      });
    } else {
      getImportedList(basePage).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }

            setItems(res);
            setSearchedProducts(res);
          }
        }
      });
    }
  };
  const pushAllExistedProductsToStore = (data: any) => {
    pushAllProductsToStore(data).then((res) => {
      if (res) {
        setItems(res);
      }
    });
  };
  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmptySearch && (
            <div className="mt-4 p-2 space-y-4">
              <SearchImport
                removeAll={removeAll}
                storesOptions={pushOptions(stores)}
                handleSearch={handleSearch}
                handleFilter={filterImportFormik.handleSubmit}
                handleChange={filterImportFormik.setFieldValue}
                initialMinPrice={selectedMinPrice}
                initialMaxPrice={selectedMaxPrice}
                initialAvailableProduct={selectedAvailableProduct}
                initialPublishedOnStore={selectedPublishedOnStore}
                initialCategories={selectedCategoriesEnd}
                pushAllExistedProductsToStore={pushAllExistedProductsToStore}
                visibleFilter={visibleFilter}
                onHideFilter={onHideFilter}
                setVisibleFilter={setVisibleFilter}
              />
              <div className="p-10 justify-content-center align-items-center mx-auto">
                <p className="mt-4 text-sm font-normal text-center">
                  {propEmptySearch.message === "No search found."
                    ? t("main.NoResultsFound") ?? "No search found."
                    : t("main.FilterResultNotFound") ??
                      "Filter result not found."}
                </p>
              </div>
            </div>
          )}

          {propEmpty && <TextResult body={propEmpty} />}
          {!propEmptySearch && !propEmpty && items.length > 0 && (
            <div className="mt-4 p-2 space-y-4">
              <SearchImport
                removeAll={removeAll}
                storesOptions={pushOptions(stores)}
                handleSearch={handleSearch}
                handleFilter={filterImportFormik.handleSubmit}
                handleChange={filterImportFormik.setFieldValue}
                initialMinPrice={selectedMinPrice}
                initialMaxPrice={selectedMaxPrice}
                initialAvailableProduct={selectedAvailableProduct}
                initialPublishedOnStore={selectedPublishedOnStore}
                initialCategories={selectedCategoriesEnd}
                pushAllExistedProductsToStore={pushAllExistedProductsToStore}
                visibleFilter={visibleFilter}
                onHideFilter={onHideFilter}
                setVisibleFilter={setVisibleFilter}
              />

              {itemListWithRatio?.length > 0 &&
                itemListWithRatio?.map((item: Item, index: number) => {
                  return (
                    <ImportListCard
                      key={index}
                      item={item}
                      index={index}
                      itemtemporary={item?.retailer_products_temporary}
                      removeProduct={removeProduct}
                      openModal={openModal}
                      labelBtn={`${
                        stores?.length > 0
                          ? t("importListPage.PushToStore") ?? "Push To Store"
                          : t("importListPage.MakeStoreFirst") ??
                            "Make store first"
                      }`}
                      actionBtn={`${
                        stores?.length > 0
                          ? "Push To Store"
                          : "Connect to Store"
                      }`}
                    />
                  );
                })}
              {pagination && (
                <PaginationTemplate
                  onPageChange={onPageChange}
                  dataPagination={pagination!}
                />
              )}
            </div>
          )}
        </>
      )}

      <Dialog
        header={
          t("importListPage.PleaseSelectTheStoreToConnect") ??
          "Please Select the Store to connect"
        }
        visible={visible}
        onHide={() => setVisible(false)}
        footer={footerContent(pushOptions(stores, selectedItem))}
        key={selectedItemId}
      >
        <div className="col-12" style={{ textAlign: isRTL ? "right" : "left" }}>
          <Dropdown
            value={selectedStore}
            onChange={(e) => setSelectedStore(e.value)}
            options={pushOptions(stores, selectedItem)}
            optionLabel="name"
            placeholder={t("importListPage.PushToStore") ?? "Push To Store"}
            className="w-full md:w-14rem"
            disabled={
              pushOptions(stores, selectedItem).length > 0 ? false : true
            }
          />
        </div>
        <p
          className={
            pushOptions(stores, selectedItem).length > 0
              ? "hidden"
              : "text-rose-400 text-sm mt-2 ml-2"
          }
        >
          {" "}
          {t(
            "importListPage.YouHaveAlreadyPushedThisItemToAllOfAvailableStores"
          ) ?? "You have already pushed this item to all of available stores."}
          <br />
          {t("importListPage.PleaseCreateANewStore") ??
            "Please create a new store"}
        </p>
      </Dialog>
    </MainLayout>
  );
}

export default ImportList;
