import { useEffect, useState } from "react";
import {
  authLogout,
  deleteUser,
  editUser,
  getCities,
  getCountries,
  getMnicipalities,
  getNeighborhoods,
  getUser,
  updatePassword,
} from "../services";
import { useFormik } from "formik";
import { UserDTO } from "../modules/Retailer.modules";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import FileUploader from "../components/FileUploader";
import LoadingComponent from "../components/Loading";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { permissions } from "../permissions/permissions";
import { Password } from "primereact/password";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [Country, setCountry] = useState<any>();
  const [City, setCity] = useState<any>();
  const [Municipality, setMunicipality] = useState<any>();
  const [Neighborhood, setNeighborhood] = useState<any>();
  const [show, setshow] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState<any>();
  const [cover, setCover] = useState<any>();
  const [inputEmail, setInputEmail] = useState("");
  const [isMatched, setIsMatched] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const navigate = useNavigate();

  const isRTL = i18n.language === "ar";
  const validationSchema = Yup.object({
    current_password: Yup.string().required(
      t("register.PasswordIsRequired") ?? "Password is required"
    ),
    new_password: Yup.string()
      .required(t("register.PasswordIsRequired") ?? "Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,
        t("register.PasswordMustHaveAtLeast8CharactersIncluding") ??
          "Password must have at least 8 characters, including one uppercase letter and at least one symbol"
      ),
    password_confirmation: Yup.string()
      .min(
        8,
        t("register.PasswordMustBeAtLeast8Characters") ??
          "Password must be at least 8 characters"
      )
      .required(
        t("register.ConfirmationPasswordIsRequired") ??
          "Confirmation Password is required"
      )
      .oneOf(
        [Yup.ref("new_password")],
        t("register.PasswordsMustMatch") ?? "Passwords must match"
      ),
  });
  const Profileform = useFormik<UserDTO>({
    initialValues: new UserDTO(),
    validateOnChange: true,
    onSubmit: () => {
      const formData = new FormData();
      if (file) {
        formData.append("supplier_cover", file);
      }
      if (cover) {
        formData.append("supplier_logo", cover);
      }

      if (Profileform.values.address?.country) {
        formData.append("countries", Profileform.values.address?.country?.id);
        if (Profileform.values.address?.city?.id) {
          formData.append("cities", Profileform.values.address?.city?.id);
          if (Profileform.values.address?.municipality?.id) {
            formData.append(
              "municipalities",
              Profileform.values.address?.municipality?.id
            );
            if (Profileform.values.address?.neighborhood?.id) {
              formData.append(
                "neighborhoods",
                Profileform.values.address?.neighborhood?.id
              );
            }
          }
        }
      }
      if (Profileform.values.address?.street) {
        formData.append("street", Profileform.values.address?.street);
      }
      if (Profileform.values.address?.apartment) {
        formData.append("apartment", Profileform.values.address?.apartment);
      }
      if (Profileform.values.address?.floor) {
        formData.append("floor", Profileform.values.address?.floor);
      }
      if (Profileform.values.address?.building) {
        formData.append("building", Profileform.values.address?.building);
      }
      if (Profileform.values.address?.more_details) {
        formData.append(
          "more_details",
          Profileform.values.address?.more_details
        );
      }
      if (Profileform.values.address?.postal_code) {
        formData.append("postal_code", Profileform.values.address?.postal_code);
      }
      if (Profileform.values.retailer?.contact_number) {
        formData.append(
          "retailer.contact_number",
          Profileform.values.retailer?.contact_number
        );
      }
      formData.append("retailer_name", Profileform.values.retailer?.name);
      formData.append("email", Profileform.values.email);
      formData.append("first_name", Profileform.values.first_name);
      formData.append("last_name", Profileform.values.last_name);
      //formData.append("status", Profileform.values.status);
      if (Profileform.values.password) {
        formData.append("password", Profileform.values.password);
      }
      editUser(formData);
    },
  });
  const passwordForm = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      password_confirmation: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: async (data: any) => {
      let dataToSend = {
        email: Profileform.values.email,
        password: passwordForm.values.new_password,
        password_confirmation: passwordForm.values.password_confirmation,
      };

      try {
        const response = await updatePassword(dataToSend);
        if (response) {
          setVisiblePassword(false);
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  const GetUserdata = () => {
    getUser().then((res: any) => {
      if (res.address) {
        if (res.address.country && res.address.country.id !== "") {
          getCities(res.address.country.id).then((res) => setCity(res));
        }
        if (res.address.city && res.address.city.id !== "") {
          getMnicipalities(res.address.city.id).then((res) => {
            setMunicipality(res);
          });
        }
        if (res.address.municipality && res.address.municipality?.id !== "") {
          getNeighborhoods(res.address.municipality?.id).then((res) =>
            setNeighborhood(res)
          );
        }
      }
      Profileform?.setValues(res);
      setLoading(false);
    });

    getCountries().then((res) => setCountry(res));
  };
  useEffect(() => {
    setLoading(true);

    GetUserdata();
  }, []);

  const onCountryChange = async (e: any) => {
    const value = e.target.value as number;
    Profileform.setFieldValue("address.country.id", value);
    setshow(true);
    await getCities(e.target.value).then((res) => setCity(res));
  };

  const onCityChange = async (e: any) => {
    const value = e.target.value as number;
    Profileform.setFieldValue("address.city.id", value);
    await getMnicipalities(e.target.value).then((res) => setMunicipality(res));
  };

  const onMunicipalityChange = async (e: any) => {
    const value = e.target.value as number;
    Profileform.setFieldValue("address.municipality.id", value);
    await getNeighborhoods(e.target.value).then((res) => setNeighborhood(res));
  };
  const onNeighborhoodChange = (e: any) => {
    const value = e.target.value as number;
    Profileform.setFieldValue("address.neighborhood.id", value);
  };

  function handleChange(event: any) {
    setFile(event.target.files[0]);
  }
  function handleCoverChange(event: any) {
    setCover(event.target.files[0]);
  }

  const getAllPermissions = () => {
    return permissions.reduce((acc: any, permission: any) => {
      const keys = Object.keys(permission);
      keys.forEach((key) => {
        if (permission[key] && !acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []);
  };
  const permissionsList = getAllPermissions();
  const hasPermission = (keys: any) => {
    return keys.every((key: any) =>
      permissions.some((permission: any) => permission[key])
    );
  };
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputEmail(value);
    setIsMatched(value === Profileform.values.email);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isMatched) {
      deleteUser().then(async (res) => {
        if (res !== undefined) {
          await authLogout();
          setVisibleDelete(false);
          navigate("/");
        }
      });
    }
  };
  const deleteDialogFooter = (
    <div className="mt-3">
      <Button
        label={t("main.Cancel") ?? "Cancel"}
        icon="pi pi-times"
        outlined
        onClick={() => setVisibleDelete(false)}
        iconPos={isRTL ? "right" : "left"}
        className="p-new-outlined-btn py-2 m-2"
      />
      <Button
        label={t("profilePage.Delete") ?? "Delete"}
        icon="pi pi-check"
        type="submit"
        iconPos={isRTL ? "right" : "left"}
        onClick={handleSubmit}
        disabled={!isMatched}
        className="p-button-danger py-2 m-2"
      />
    </div>
  );
  const passwordDialogFooter = (
    <div className="mt-3">
      <Button
        label={t("main.Cancel") ?? "Cancel"}
        icon="pi pi-times"
        outlined
        onClick={() => setVisiblePassword(false)}
        iconPos={isRTL ? "right" : "left"}
        className="p-new-outlined-btn py-2 m-2"
      />
      <Button
        label={t("profilePage.ChangePassword") ?? "Change Password"}
        icon="pi pi-check"
        type="submit"
        iconPos={isRTL ? "right" : "left"}
        onClick={() => passwordForm.handleSubmit()}
        className="p-button-danger py-2 m-2"
      />
    </div>
  );
  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {/* Permission Dialog */}
          <Dialog
            header={t("profilePage.ViewPermissions") ?? ""}
            visible={visible}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => setVisible(false)}
          >
            {
              <div>
                <DataTable
                  value={permissions}
                  stripedRows
                  showGridlines
                  className="mt-4"
                  resizableColumns
                  rows={10}
                  rowsPerPageOptions={[10, 15, 20, 50]}
                  filterDisplay="menu"
                  paginator
                  rowHover
                  sortMode="multiple"
                >
                  <Column
                    field="title"
                    header={t("profilePage.Title") ?? "Title"}
                    sortable
                  ></Column>
                  <Column
                    field="description"
                    header={t("notificationPage.Description") ?? "Description"}
                    sortable
                  ></Column>
                  <Column
                    field=""
                    header={t("notificationPage.Status") ?? "Status"}
                    body={() =>
                      hasPermission(permissionsList) === true ? (
                        <i
                          className="pi pi-check"
                          style={{ fontSize: "1.2rem", color: "green" }}
                        />
                      ) : (
                        <i
                          className="pi pi-times"
                          style={{ fontSize: "1.2rem", color: "red" }}
                        />
                      )
                    }
                    sortable
                  ></Column>
                </DataTable>
              </div>
            }
          </Dialog>
          <ConfirmDialog />
          {/* Delete Account */}
          <Dialog
            header={
              t("profilePage.DoYouWantToDeleteThisAccount") ??
              "Do you want to delete this account?"
            }
            visible={visibleDelete}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => setVisibleDelete(false)}
            footer={deleteDialogFooter}
          >
            <h3 className="text-black font-semibold my-2">
              {t("profilePage.EnterInTheFieldYourEmail") ??
                "Enter in the field Your Email"}
            </h3>
            <p className="text-sm"> {Profileform.values?.email}</p>
            <form
              className="flex flex-column gap-2 mt-3"
              onSubmit={handleSubmit}
            >
              <div className="col-12">
                <label htmlFor="email">
                  {t("profilePage.Email") ?? "Email"}
                </label>
                <InputText
                  id="email"
                  name="email"
                  required
                  value={inputEmail}
                  onChange={handleInputChange}
                />

                {!isMatched && inputEmail && (
                  <small style={{ color: "red" }}>
                    {t("profilePage.EmailDoesNotMatch") ??
                      "Email does not match"}
                  </small>
                )}
              </div>
            </form>
          </Dialog>
          {/* Change Password */}
          <Dialog
            header={t("profilePage.ChangePassword") ?? "Change Password?"}
            visible={visiblePassword}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            onHide={() => setVisiblePassword(false)}
            footer={passwordDialogFooter}
          >
            <h3 className="text-black font-semibold my-2">
              {t("profilePage.PleaseFillAllTheFields") ??
                "Please Fill All The Fields"}
            </h3>
            <form
              className="flex flex-column gap-2 mt-3"
              // onSubmit={passwordForm.handleSubmit}
            >
              <div className="p-fluid">
                {/* <div className="p-field mb-2">
                  <label htmlFor="current_password" className="mb-2">
                    {t("profilePage.CurrentPassword") ?? "Current Password"}
                  </label>
                  <Password
                    id="current_password"
                    value={passwordForm.values.current_password}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    name="current_password"
                    toggleMask
                    feedback={false}
                  />
                  {passwordForm.errors.current_password && (
                    <small className="p-error">
                      {passwordForm.errors.current_password}
                    </small>
                  )}
                </div> */}
                <div className="p-field mb-2">
                  <label className="mb-2" htmlFor="current_password">
                    {t("profilePage.CurrentPassword") ?? "Current Password"}
                  </label>
                  <Password
                    id="current_password"
                    name="current_password"
                    value={passwordForm.values.current_password}
                    onChange={passwordForm.handleChange}
                    promptLabel={
                      t("profilePage.CurrentPassword") ?? "Current Password"
                    }
                    weakLabel={t("register.Weak") ?? "Weak"}
                    mediumLabel={t("register.Medium") ?? "Medium"}
                    strongLabel={t("register.Strong") ?? "Strong"}
                    toggleMask
                  />
                  {passwordForm.errors.current_password && (
                    <small className="p-error">
                      {passwordForm.errors.current_password}
                    </small>
                  )}
                </div>
                <div className="p-field mb-2">
                  <label className="mb-2" htmlFor="newPassword">
                    {t("profilePage.NewPassword") ?? "New Password"}
                  </label>
                  <Password
                    id="new_password"
                    name="new_password"
                    value={passwordForm.values.new_password}
                    onChange={passwordForm.handleChange}
                    promptLabel={t("profilePage.NewPassword") ?? "New Password"}
                    weakLabel={t("register.Weak") ?? "Weak"}
                    mediumLabel={t("register.Medium") ?? "Medium"}
                    strongLabel={t("register.Strong") ?? "Strong"}
                    toggleMask
                  />
                  {passwordForm.errors.new_password && (
                    <small className="p-error">
                      {passwordForm.errors.new_password}
                    </small>
                  )}
                </div>
                <div className="p-field mb-2">
                  <label htmlFor="confirmPassword" className="mb-2">
                    {t("profilePage.ConfirmNewPassword") ??
                      "Confirm New Password"}
                  </label>
                  <Password
                    id="password_confirmation"
                    type="password"
                    value={passwordForm.values.password_confirmation}
                    onChange={passwordForm.handleChange}
                    name="password_confirmation"
                    toggleMask
                    feedback={false}
                  />
                  {passwordForm.errors.password_confirmation &&
                    passwordForm.touched.password_confirmation && (
                      <small className="p-error">
                        {passwordForm.errors.password_confirmation}
                      </small>
                    )}
                </div>
              </div>
            </form>
          </Dialog>
          {/* Profile  */}
          <div className=" mt-4 p-3">
            <Panel
              header={
                t("profilePage.PersonalInformation") ?? "Personal Information"
              }
            >
              <div className="formgrid grid">
                <div className="col-12 md:col-4 lg:col-4">
                  <label className="mb-2" htmlFor="first_name">
                    {" "}
                    {t("profilePage.FirstName") ?? "First Name"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.FirstName") ?? "First Name"}
                    value={Profileform.values.first_name}
                    onChange={Profileform.handleChange}
                    name="first_name"
                  />
                </div>
                <div className="col-12 md:col-4 lg:col-4">
                  <label className="mb-2" htmlFor="last_name">
                    {" "}
                    {t("profilePage.LastName") ?? "Last Name"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.LastName") ?? "Last Name"}
                    value={Profileform.values.last_name}
                    onChange={Profileform.handleChange}
                    name="last_name"
                  />
                </div>
                <div className="col-12 md:col-4 lg:col-4">
                  <label className="mb-2" htmlFor="Email">
                    {" "}
                    {t("profilePage.Email") ?? "Email"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.Email") ?? "Email"}
                    value={Profileform.values.email}
                    onChange={Profileform.handleChange}
                    name="email"
                  />
                </div>
              </div>
            </Panel>

            <Panel
              header={
                t("profilePage.AddressInformation") ?? "Address Information"
              }
              className="mt-4"
            >
              <div className="formgrid grid">
                <div className="col-12 md:col-4 lg:col-4  ">
                  <label className="mb-2" htmlFor="Country">
                    {" "}
                    {t("profilePage.SelectCountry") ?? "Select Country"}{" "}
                  </label>
                  <Dropdown
                    value={Profileform.values.address?.country?.id}
                    onChange={onCountryChange}
                    name="country"
                    options={Country}
                    optionValue="id"
                    optionLabel="name"
                    className="w-full"
                    placeholder={
                      t("profilePage.SelectCountry") ?? "Select Country"
                    }
                  />
                </div>
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="City">
                    {" "}
                    {t("profilePage.SelectCity") ?? "SelectCity"}{" "}
                  </label>
                  {show === true ? (
                    <Dropdown
                      key={JSON.stringify(City)}
                      value={Profileform.values.address?.city?.id}
                      onChange={onCityChange}
                      name="city"
                      options={City}
                      optionValue="id"
                      optionLabel="name"
                      className="w-full"
                      placeholder={
                        t("profilePage.SelectCity") ?? "Select a City"
                      }
                    />
                  ) : (
                    <Dropdown
                      key={JSON.stringify(City)}
                      value={Profileform.values.address?.city?.id}
                      onChange={onCityChange}
                      name="city"
                      options={City}
                      optionValue="id"
                      optionLabel="name"
                      className="w-full"
                      placeholder={
                        t("profilePage.SelectCity") ?? "Select a City"
                      }
                    />
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="Municipality">
                    {" "}
                    {t("profilePage.SelectMunicipality") ??
                      "Select Municipality"}{" "}
                  </label>
                  {show === true ? (
                    <Dropdown
                      key={JSON.stringify(Municipality)}
                      value={Profileform.values.address?.municipality?.id}
                      onChange={onMunicipalityChange}
                      name="municipality"
                      options={Municipality}
                      optionValue="id"
                      optionLabel="name"
                      className="w-full"
                      placeholder={
                        t("profilePage.SelectMunicipality") ??
                        "Select a Municipality"
                      }
                    />
                  ) : (
                    <Dropdown
                      key={JSON.stringify(Municipality)}
                      value={Profileform.values.address?.municipality?.id}
                      onChange={onMunicipalityChange}
                      name="municipality"
                      options={Municipality}
                      optionValue="id"
                      optionLabel="name"
                      className="w-full"
                      placeholder={
                        t("profilePage.SelectMunicipality") ??
                        "Select a Municipality"
                      }
                    />
                  )}
                </div>
              </div>
              <div className="formgrid grid mt-4">
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="Neighborhood">
                    {" "}
                    {t("profilePage.SelectNeighborhood") ??
                      "Select Neighborhood"}{" "}
                  </label>
                  <Dropdown
                    value={Profileform.values.address?.neighborhood?.id}
                    onChange={onNeighborhoodChange}
                    name="neighborhood"
                    options={Neighborhood}
                    optionValue="id"
                    optionLabel="name"
                    className="w-full"
                    placeholder={
                      t("profilePage.SelectNeighborhood") ??
                      "Select Neighborhood"
                    }
                  />
                </div>
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="building">
                    {" "}
                    {t("profilePage.Building") ?? "Building"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.Building") ?? "Building"}
                    value={Profileform.values.address?.building}
                    onChange={(e) =>
                      Profileform.setFieldValue(
                        "address.building",
                        e.target.value
                      )
                    }
                    name="building"
                  />
                </div>
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="Floor">
                    {" "}
                    {t("profilePage.Floor") ?? "Floor"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.Floor") ?? "Floor"}
                    value={Profileform.values.address?.floor}
                    onChange={(e) =>
                      Profileform.setFieldValue("address.floor", e.target.value)
                    }
                    name="floor"
                  />
                </div>
              </div>
              <div className="formgrid grid mt-4">
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="Apartment">
                    {" "}
                    {t("profilePage.Apartment") ?? "Apartment"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.Apartment") ?? "Apartment"}
                    value={Profileform.values.address?.apartment}
                    onChange={(e) =>
                      Profileform.setFieldValue(
                        "address.apartment",
                        e.target.value
                      )
                    }
                    name="apartment"
                  />
                </div>
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="street">
                    {" "}
                    {t("profilePage.Street") ?? "Street"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.Street") ?? "Street"}
                    value={Profileform.values.address?.street}
                    onChange={(e) =>
                      Profileform.setFieldValue(
                        "address.street",
                        e.target.value
                      )
                    }
                    name="street"
                  />
                </div>
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="postal_code">
                    {" "}
                    {t("profilePage.PostalCode") ?? "PostalCode"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.PostalCode") ?? "PostalCode"}
                    value={Profileform.values.address?.postal_code}
                    onChange={(e) =>
                      Profileform.setFieldValue(
                        "address.postal_code",
                        e.target.value
                      )
                    }
                    name="postal_code"
                    id="postal_code"
                  />
                </div>
              </div>
              <div className="formgrid grid mt-4">
                <div className="col-12 md:col-4 lg:col-4 ">
                  <label className="mb-2" htmlFor="Details">
                    {" "}
                    {t("profilePage.Details") ?? "Details"}{" "}
                  </label>
                  <InputText
                    placeholder={t("profilePage.MoreDetails") ?? "More Details"}
                    value={Profileform.values.address?.more_details}
                    onChange={(e) =>
                      Profileform.setFieldValue(
                        "address.more_details",
                        e.target.value
                      )
                    }
                    name="more_details"
                    id="more_details"
                  />
                </div>
              </div>
            </Panel>
            <Panel
              header={
                t("profilePage.RetailerInformation") ?? "Retailer Information"
              }
              className="mt-4"
            >
              <div className="grid gap-4">
                <div className="md:col-5 lg:col-5 ">
                  <label className="mb-2" htmlFor="retailer.name">
                    {" "}
                    {t("profilePage.RetailerName") ?? "Retailer Name"}{" "}
                  </label>
                  <InputText
                    placeholder={
                      t("profilePage.RetailerName") ?? "Retailer Name"
                    }
                    value={Profileform.values.retailer?.name}
                    onChange={(e) =>
                      Profileform.setFieldValue("retailer.name", e.target.value)
                    }
                    name="retailer.name"
                  />
                </div>
                <div className="md:col-5 lg:col-5 ">
                  <label className="mb-2" htmlFor="contact_number">
                    {" "}
                    {t("profilePage.ContactNumber") ?? "Contact Number"}{" "}
                  </label>
                  <InputText
                    placeholder={
                      t("profilePage.ContactNumber") ?? "Contact Number"
                    }
                    value={Profileform.values.retailer?.contact_number}
                    onChange={(e) =>
                      Profileform.setFieldValue(
                        "retailer.contact_number",
                        e.target.value
                      )
                    }
                    name="retailer.contact_number"
                    id="retailer.contact_number"
                  />
                </div>
              </div>
              <div className="grid gap-4">
                <div className="md:col-5 lg:col-5 ">
                  <label className="mb-2" htmlFor="Logo">
                    {" "}
                    {t("profilePage.Logo") ?? "Logo"}{" "}
                  </label>
                  <FileUploader onChange={handleChange} name="" />
                </div>
                <div className="md:col-5 lg:col-5 ">
                  <label className="mb-2" htmlFor="Cover">
                    {" "}
                    {t("profilePage.Cover") ?? "Cover"}{" "}
                  </label>
                  <FileUploader onChange={handleCoverChange} name="" />
                </div>
              </div>
            </Panel>

            <div className="grid  gap-4 mx-4 mt-6">
              <Button
                className="mb-4 change-color-btn-outlined"
                label={t("profilePage.ViewPermissions") ?? ""}
                severity="info"
                outlined
                icon="pi pi-lock-open"
                iconPos={isRTL ? "right" : "left"}
                onClick={() => setVisible(true)}
              />
              <Button
                className="mb-4 change-color-btn"
                label={t("profilePage.ChangePassword") ?? "Change Password"}
                icon="pi pi-check"
                iconPos={isRTL ? "right" : "left"}
                onClick={() => setVisiblePassword(true)}
              />
              <Button
                className="mb-4"
                severity="danger"
                outlined
                label={t("profilePage.UpdateProfile") ?? "Update Profile"}
                icon="pi pi-check"
                iconPos={isRTL ? "right" : "left"}
                onClick={() => Profileform.handleSubmit()}
              />
              <Button
                className="mb-4"
                label={t("profilePage.DeleteAccount") ?? "Delete Account"}
                severity="danger"
                icon="pi pi-times"
                iconPos={isRTL ? "right" : "left"}
                onClick={() => setVisibleDelete(true)}
              />
            </div>
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default Profile;
