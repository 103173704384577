import React, { useEffect, useState } from "react";
import TextResult from "../components/TextResult";
import { listNotifications } from "../services";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { useTranslation } from "react-i18next";
import { IPagination } from "../modules/Retailer.modules";
import MainLayout from "../containers/MainLayout";
import LoadingComponent from "../components/Loading";

function AppNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [propEmpty, setPropEmpty] = useState<any>();
  const [pagination, setPagination] = useState<IPagination>();
  const [first, setFirst] = useState(0);
  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState(10);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  // Fetch Notifications data
  useEffect(() => {
    setLoading(true);

    listNotifications().then((res) => {
      if (res !== undefined) {
        if ("image" in res && "message" in res) {
          setPropEmpty(res);
        } else {
          if (res.pagination) {
            setPagination(res.pagination);
          }
          setNotifications(res);
        }
        setLoading(false);
      }
    });
  }, [setNotifications]);

  const onPageChange = (text: string) => {
    let currentPage = 1;
    switch (text) {
      case "next":
        currentPage = pagination!.current_page + 1;
        break;
      case "prev":
        currentPage = pagination!.current_page - 1;
        break;
      case "first":
        currentPage = 1;
        break;
      case "last":
        currentPage = pagination!.total_pages;
        break;

      default:
      // Default case
    }
    listNotifications(currentPage).then((newProducts) => {
      setNotifications(newProducts.slice(0, -1));
      setPagination(newProducts!.pagination);
      setFirst(currentPage);
    });
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmpty && <TextResult body={propEmpty} />}
          {!propEmpty && (
            <div className="card m-4">
              <h1 className="font-medium text-xl p-4">
                {" "}
                {t("notificationPage.YourListofNotifications") ??
                  "Your List of Notifications"}
              </h1>
              <DataTable
                value={notifications}
                paginator
                tableStyle={{ minWidth: "50rem" }}
                stripedRows
                showGridlines
                className="mt-4"
                resizableColumns
                rows={10}
              >
                <Column field="id" header="ID"></Column>
                <Column
                  field="type"
                  header={t("notificationPage.Type") ?? "Type"}
                ></Column>
                <Column
                  field="title"
                  header={t("notificationPage.Title") ?? "Title"}
                ></Column>
                <Column
                  field="description"
                  header={t("notificationPage.Description") ?? "Description"}
                ></Column>
                <Column
                  field="read_at"
                  header={t("notificationPage.ReadAt") ?? "Read At"}
                ></Column>
                <Column
                  field="status"
                  header={t("notificationPage.Status") ?? "Status"}
                ></Column>
                <Column
                  field="created_at"
                  header={t("main.CreatedAt") ?? "Created At"}
                ></Column>
              </DataTable>
            </div>
          )}
        </>
      )}
    </MainLayout>
  );
}

export default AppNotifications;
