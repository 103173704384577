import React, { useEffect, useState } from "react";
import LayoutComponent from "./routes/Layout";
import "./styles/App.scss";
import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthContext/AuthContext";
import i18n from "./i18n";
import AppPusher from "./pusher-notification/config";
import i18next from "i18next";
import { SharedDataProvider } from "./services/SharedData";

function App() {
  useEffect(() => {
    AppPusher();
    let lan = i18n.language.toLowerCase();
    if (lan.startsWith("ar")) {
      lan = "ar";
    }
    
    import(`./styles/${lan}.scss`)
      .then((styleModule) => {
        const dir = i18n.dir(lan);
        document.documentElement.dir = dir;
      })
      .catch((error) => {
        console.error(`Failed to load style file for ${i18n.language}:`, error);
      });
  }, [i18n, i18n.language]);

  return (
    <AuthProvider>
      <SharedDataProvider>
        <BrowserRouter>
          <PrimeReactProvider>
            <LayoutComponent />
          </PrimeReactProvider>
        </BrowserRouter>
      </SharedDataProvider>
    </AuthProvider>
  );
}

export default App;
