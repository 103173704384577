import axios from "axios";
import { objectToQueryString } from "../helpers/helper";
import { handleResponse, handleError } from "./handleResponse";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Api-Secret": process.env.REACT_APP_API_PASSWORD,
    Authorization: `Bearer ${
      typeof window !== "undefined" ? localStorage.getItem("token") : ""
    }`,
    locale:
      typeof window !== "undefined"
        ? localStorage.getItem("i18nextLng") || "en"
        : "en",
  },
});

const apiFormData = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Api-Secret": process.env.REACT_APP_API_PASSWORD,
    Authorization: `Bearer ${
      typeof window !== "undefined" ? localStorage.getItem("token") : ""
    }`,
    locale:
      typeof window !== "undefined"
        ? localStorage.getItem("i18nextLng") || "en"
        : "en",
  },
});

export const authRegister = async (registerData: any) => {
  try {
    const response = await api.post("auth/register", registerData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const otpVerify = async (otpVerifyData: any) => {
  try {
    const response = await api.post("auth/otp-verify", otpVerifyData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};
export const resendOtp = async (email: any) => {
  try {
    const response = await api.post("auth/resend-otp-verify-code", email);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const authLogin = async (loginData: any) => {
  try {
    const response = await api.post("auth/login", loginData);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const authLogout = async () => {
  try {
    const response = await api.post("auth/logout");
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const forgotPassword = async (email: any) => {
  try {
    const response = await api.post("auth/forgot_password", email);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};
export const updatePassword = async (data: any) => {
  try {
    const response = await api.post("auth/update-password", data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const resetPassword = async (resetData: any) => {
  try {
    const response = await api.post("auth/reset_password", resetData);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const editUser = async (editData: any) => {
  try {
    const response = await apiFormData.post("user/edit", editData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const getUser = async () => {
  try {
    const response = await api.get("user/info");
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const deleteUser = async () => {
  try {
    const response = await api.delete("user/send_a_delete_request");
    return handleResponse(response, "", "Delete");
  } catch (error) {
    handleError(error);
  }
};

export const getCountries = async () => {
  try {
    const response = await api.get("address/countries");
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getCities = async (country_id: number) => {
  try {
    const response = await api.get(`address/cities?country_id=${country_id}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getMnicipalities = async (city_id: number) => {
  try {
    const response = await api.get(`address/municipalities?city_id=${city_id}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getNeighborhoods = async (municipality_id: number) => {
  try {
    const response = await api.get(
      `address/neighborhoods?municipality_id=${municipality_id}`
    );
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getAllProducts = async (
  page?: number,
  search?: string,
  filter?: {}
) => {
  try {
    if (search !== undefined) {
      const response = await api.get(
        `product/find_products?page=${page}&search=${search}`
      );
      return handleResponse(response, "search");
    } else if (filter !== undefined) {
      let queryString = objectToQueryString(filter);
      const response = await api.get(
        `product/find_products?page=${page}&${queryString}`
      );
      return handleResponse(response, "filter");
    } else {
      const response = await api.get(`product/find_products?page=${page}`);
      return handleResponse(response, "products");
    }
  } catch (error) {
    handleError(error);
  }
};

export const getProduct = async (id: any) => {
  try {
    const response = await api.get(`product/show_product?product_id=${id}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getImportedList = async (
  page?: number,
  search?: string,
  filter?: {}
) => {
  try {
    if (search !== undefined) {
      const response = await api.get(
        `product/import_list?page=${page}&search=${search}`
      );
      return handleResponse(response, "search");
    } else if (filter !== undefined) {
      let queryString = objectToQueryString(filter);
      const response = await api.get(
        `product/import_list?page=${page}&${queryString}`
      );
      return handleResponse(response, "filter");
    } else {
      const response = await api.get(`product/import_list?page=${page}`);
      return handleResponse(response, "importlist");
    }
  } catch (error) {
    handleError(error);
  }
};

export const addToImportList = async (data: any) => {
  try {
    const response = await api.post("product/add_to_import_list", data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const removeFromImportList = async (data: any) => {
  try {
    const response = await api.post("product/remove_from_import_list", data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const removeAllProductFromImportList = async () => {
  try {
    const response = await api.post("product/remove_all");
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const getCategories = async () => {
  try {
    const response = await api.get("product/categories");
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const saveChangeProduct = async (data: any) => {
  try {
    const response = await api.post(
      "connect_store/change_product_details",
      data
    );
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const tempSaveChange = async (data: any) => {
  try {
    const response = await api.post(
      "product/temporary_change_product_details",
      data
    );
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const getTempChange = async (data: any) => {
  try {
    const response = await api.get(
      `product/show_temporary_change_product?products_temporary_id=${data}`
    );
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const listStores = async () => {
  try {
    const response = await api.get("connect_store/your_stores");
    return handleResponse(response, "connect_store");
  } catch (error) {
    handleError(error);
  }
};

export const liveProducts = async (
  connectStoreId: any,
  page?: number,
  search?: string,
  filter?: {}
) => {
  try {
    if (search !== undefined) {
      const response = await api.get(
        `connect_store/live_products?connect_store_id=${connectStoreId}&page=${page}&search=${search}`
      );
      return handleResponse(response, "search");
    } else if (filter !== undefined) {
      let queryString = objectToQueryString(filter);
      const response = await api.get(
        `connect_store/live_products?connect_store_id=${connectStoreId}&page=${page}&${queryString}`
      );
      return handleResponse(response, "filter");
    } else {
      const response = await api.get(
        `connect_store/live_products?connect_store_id=${connectStoreId}&page=${page}`
      );
      return handleResponse(response, "liveproduct");
    }
  } catch (error) {
    handleError(error);
  }
};

export const createStore = async (data: any) => {
  try {
    const response = await api.post("connect_store/make_connect_store", data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const restoreProductToImportList = async (data: any) => {
  try {
    const response = await api.post(
      "connect_store/remove_from_live_products",
      data
    );
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const connectProductToLive = async (data: any) => {
  try {
    const response = await api.post("connect_store/add_to_live_products", data);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const pushAllProductsToStore = async (data: any) => {
  try {
    const response = await api.post(
      "connect_store/push_all_to_connect_store_from_import_list",
      data
    );
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const listSupportedIntegrations = async () => {
  try {
    const response = await api.get("integration_support/list");
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const showStore = async (storeId: any) => {
  try {
    const response = await api.get(`connect_store/show_store/${storeId}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getOrder = async () => {
  try {
    const response = await api.get("order/list");
    return handleResponse(response, "orders");
  } catch (error) {
    handleError(error);
  }
};

export const OrderDetailes = async (OrderCode: number) => {
  try {
    const response = await api.get(`order/show/${OrderCode}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const OrderStatus = async (OrderCode: any, status: string) => {
  try {
    const response = await api.put(
      `order/change_status?order_code=${OrderCode}&status=${status}`
    );
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const CreateOrder = async (CreateOrderdata: any) => {
  try {
    const response = await api.post(`order/create`, CreateOrderdata);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const WalletInfo = async () => {
  try {
    const response = await api.get(`wallet/show`);
    return handleResponse(response, "wallet");
  } catch (error) {
    handleError(error);
  }
};

export const ChargeWallet = async (ChargeInfo: any) => {
  try {
    const response = await api.post(`wallet/charge_wallet`, ChargeInfo);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const BanksInfo = async () => {
  try {
    const response = await api.get(`bank_account/list`);
    return handleResponse(response, "banks");
  } catch (error) {
    handleError(error);
  }
};

export const CreateBankAccount = async (BankInfo: any) => {
  try {
    const response = await api.post(`bank_account/create`, BankInfo);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const DetailsBankAccount = async (BankId: number) => {
  try {
    const response = await api.get(`bank_account/show/${BankId}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const UpdateBankAccount = async (BankInfo: any, BankId: any) => {
  try {
    const response = await api.post(`bank_account/update/${BankId}`, BankInfo);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const DeleteBankAccount = async (BankId: number) => {
  try {
    const response = await api.delete(`bank_account/delete/${BankId}`);
    return handleResponse(response, "", "Delete");
  } catch (error) {
    handleError(error);
  }
};

export const CurrencySystem = async () => {
  try {
    const response = await api.get(`system/currencies_supports_in_system`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const Subscription = async () => {
  try {
    const response = await api.get(`subscription/your_subscription`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const PlansList = async () => {
  try {
    const response = await api.get(`subscription/plans_list`);
    return handleResponse(response, "plans");
  } catch (error) {
    handleError(error);
  }
};

export const newSubscription = async (data: any) => {
  try {
    const response = await api.post(`subscription/new_subscription`, data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const upgradeSubscription = async (planData: any) => {
  try {
    const response = await api.post(
      `subscription/upgrade_subscription`,
      planData
    );
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const getComplaint = async () => {
  try {
    const response = await api.get(`complaint/list`);
    return handleResponse(response, "complaints");
  } catch (error) {
    handleError(error);
  }
};

export const getComplaintDefinitions = async () => {
  try {
    const response = await api.get(`complaint/definitions`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const createComplaint = async (complaintData: any) => {
  try {
    const response = await apiFormData.post(`complaint/create`, complaintData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const UpdateComplaint = async (
  complaintId: number,
  complaintData: any
) => {
  try {
    const response = await apiFormData.post(
      `complaint/update/${complaintId}`,
      complaintData
    );
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const exportCatalogue = async (data: any) => {
  try {
    const response = await api.post("export/product", data);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const cartList = async () => {
  try {
    const response = await api.get(`cart/list`);
    return handleResponse(response, "cart");
  } catch (error) {
    handleError(error);
  }
};

export const showCart = async (storeId: number) => {
  try {
    const response = await api.get(`cart/show?connect_store_id=${storeId}`);
    return handleResponse(response, "ShowCart");
  } catch (error) {
    handleError(error);
  }
};

export const addToCart = async (cartData: any) => {
  try {
    const response = await api.post(`cart/add_item`, cartData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};
export const increaseCartItem = async (cartData: any) => {
  try {
    const response = await api.post(`cart/increase_item`, cartData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};
export const decreaseCartItem = async (cartData: any) => {
  try {
    const response = await api.post(`cart/decrease_item`, cartData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};
export const removeCartItem = async (cartData: any) => {
  try {
    const response = await api.post(`cart/remove_item`, cartData);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const ClearCart = async (data: any) => {
  try {
    const response = await api.post(`cart/clear_cart`, data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const subProductsWithPossibility = async (
  product_id: number,
  connect_store_id: number
) => {
  try {
    const response = await api.get(
      `product/sub_products_with_possibility/${product_id}/${connect_store_id}`
    );
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getCoupon = async () => {
  try {
    const response = await api.get(`coupon/list`);
    return handleResponse(response, "coupons");
  } catch (error) {
    handleError(error);
  }
};

export const avilableCoupon = async (data: any) => {
  try {
    const response = await api.post(`cart/get_available_coupons`, data);
    return handleResponse(response, "", "");
  } catch (error) {
    handleError(error);
  }
};

export const addCoupon = async (data: any) => {
  try {
    const response = await api.post(`cart/add_coupon`, data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const removeCoupon = async (data: any) => {
  try {
    const response = await api.post(`cart/remove_coupon`, data);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const listCustomers = async () => {
  try {
    const response = await api.get("endCustomer/list");
    return handleResponse(response, "customers");
  } catch (error) {
    handleError(error);
  }
};

export const listEmployees = async () => {
  try {
    const response = await api.get("employee/list");
    return handleResponse(response, "employees");
  } catch (error) {
    handleError(error);
  }
};
export const addEmployee = async (employeeInfo: any) => {
  try {
    const response = await api.post(`employee/create`, employeeInfo);
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};
export const detailsEmployee = async (employeeId: number) => {
  try {
    const response = await api.get(`employee/show/${employeeId}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const updateEmployee = async (employeeInfo: any, employeeId: any) => {
  try {
    const response = await api.post(
      `employee/update/${employeeId}`,
      employeeInfo
    );
    return handleResponse(response, "", "Post");
  } catch (error) {
    handleError(error);
  }
};

export const deleteEmployee = async (employeeId: any) => {
  try {
    const response = await api.delete(`employee/delete/${employeeId}`);
    return handleResponse(response, "", "Delete");
  } catch (error) {
    handleError(error);
  }
};
// Roles
export const listRoles = async () => {
  try {
    const response = await api.get(`employee/list_of_roles_to_employee`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

// Notification
export const listNotifications = async (page?: number) => {
  try {
    const response = await api.get(`notification/list?page=${page}`);
    return handleResponse(response, "Get");
  } catch (error) {
    handleError(error);
  }
};

// Analytics

export const getAnalytics = async (startDate?: any , endDate?:any) => {
  try {
    const response = await api.get(`/analysis?start_date=${startDate}&end_date=${endDate}`);
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export default api;
