import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { OrderDetailes, OrderStatus, getOrder } from "../services";
import { Dialog } from "primereact/dialog";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { Galleria } from "primereact/galleria";
import TextResult from "../components/TextResult";
import MainLayout from "../containers/MainLayout";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../components/Loading";
import { UPDATE_ORDERS } from "../permissions/permissions";

const Orders = () => {
  const [order, setOrder] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [showDetails, setShowDetailes] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [propEmpty, setPropEmpty] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  useEffect(() => {
    setLoading(true);

    getOrder().then((res: any) => {
      if (res !== undefined) {
        if ("image" in res && "message" in res) {
          setPropEmpty(res);
        } else {
          setOrder(res);
        }
      }
      setLoading(false);
    });
  }, []);
  const GetDetails = (code: number) => {
    OrderDetailes(code).then((res) => setOrderDetails(res));
    setShowDetailes(true);
  };
  const SubmitStatus = (id: number) => {
    OrderStatus(id, selectedStatus);
  };
  const statusOptions =
    orderDetails?.next_status?.map((status: any) => ({
      label: status,
      value: status,
    })) || [];

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmpty && <TextResult body={propEmpty} />}
          {!propEmpty && (
            <div className="card pb-16 px-2 md:px-8 lg:px-16">
              {order?.map((orderdata: any) => (
                <>
                  <Card
                    className="mt-5"
                    header={
                      <div className="flex flex-nowrap pt-4">
                        <div>
                          <h4 className={isRTL ? "mr-5" : "ml-5"}>
                            {" "}
                            {t("orderPage.OrderNo") ?? "Order No"}{" "}
                            {orderdata?.code}
                          </h4>
                          <h4 className={isRTL ? "mr-5" : "ml-5"}>
                            {" "}
                            {t("orderPage.OrderDate") ?? "Order Date :"}{" "}
                            {orderdata?.created_at}
                          </h4>
                        </div>
                        <div className={isRTL ? "mr-5" : "ml-5"}>
                          <h4 className={isRTL ? "mr-5" : "ml-5"}>
                            {" "}
                            {t("orderPage.TotalAmount") ?? "Total Amount :"}
                            {orderdata?.total_amount}
                          </h4>
                          <h4 className={isRTL ? "mr-5" : "ml-5"}>
                            {" "}
                            {t("orderPage.TotalWeight") ?? "Total Weight :"}
                            {orderdata?.total_weight}
                          </h4>
                        </div>
                        <div className={isRTL ? "mr-5" : "ml-5"}>
                          <h4 className={isRTL ? "mr-5" : "ml-5"}>
                            {" "}
                            {t("orderPage.TotalVolume") ?? "Total Volume :"}
                            {orderdata?.total_volume}
                          </h4>
                          <h4 className={isRTL ? "mr-5" : "ml-5"}>
                            {t("orderPage.Status") ?? "Status :"}

                            {orderdata?.status}
                          </h4>
                        </div>
                        <div
                          className={isRTL ? "ml-5 mr-auto" : "mr-5 ml-auto"}
                        >
                          <h4 className={isRTL ? "mr-5" : "ml-5"}>
                            {" "}
                            {t("orderPage.DueDate") ?? "Due Date :"}
                            {orderdata?.due_date}
                          </h4>
                          <Button
                            label={
                              t("orderPage.CustomerInfo") ?? "Customer Info"
                            }
                            className="mt-3 Secondery-btn"
                            onClick={() => setShow(true)}
                            rounded
                            size="small"
                          ></Button>
                        </div>
                      </div>
                    }
                  >
                    <DataTable
                      value={orderdata?.order_items}
                      stripedRows
                      showGridlines
                      tableStyle={{ minWidth: "50rem" }}
                      dir={isRTL ? "ltr" : "ltr"}
                    >
                      <Column
                        field="subProduct.product_title"
                        header={t("orderPage.ProductName") ?? "Product Name"}
                      ></Column>
                      <Column
                        field="subProduct.attribute_text_style"
                        header={t("orderPage.Attribute") ?? "Attribute"}
                      ></Column>
                      <Column
                        field="quantity"
                        header={t("orderPage.Quantity") ?? "Quantity"}
                      ></Column>
                      <Column
                        field="price"
                        header={t("orderPage.Price") ?? "Price"}
                      ></Column>
                      <Column
                        field="amount"
                        header={t("orderPage.Amount") ?? "Amount"}
                        body={(rowData: any) =>
                          Math.round(rowData.price * rowData.quantity)
                        }
                      ></Column>
                    </DataTable>
                    {UPDATE_ORDERS()? <Button
                      label={t("orderPage.ChangeStatus") ?? "Change Status"}
                      onClick={() => GetDetails(orderdata?.code)}
                      severity="danger"
                      size="small"
                      rounded
                      className="mt-4"
                    ></Button>:<></>}
                  </Card>
                  <Dialog
                    header={t("orderPage.CustomerInfo") ?? "Customer Info"}
                    visible={show}
                    style={{ width: "50vw" }}
                    onHide={() => setShow(false)}
                  >
                    <Row>
                      <div className="formgrid grid">
                        <div className="field col">
                          <label>
                            {" "}
                            {t("orderPage.FullName") ?? "Full Name"}
                          </label>
                          <InputText
                            value={orderdata?.end_customer.full_name}
                            readOnly
                          />
                        </div>
                        <div className="field col">
                          <label> {t("orderPage.Email") ?? "Email"}</label>
                          <InputText
                            value={orderdata?.end_customer.email}
                            readOnly
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="formgrid grid">
                        <div className="field col">
                          <label> {t("orderPage.Address") ?? "Address"}</label>
                          <InputText
                            value={orderdata?.end_customer.address}
                            readOnly
                          />
                        </div>
                        <div className="field col">
                          <label>
                            {" "}
                            {t("orderPage.PhoneNumber") ?? "Phone Number"}
                          </label>
                          <InputText
                            value={orderdata?.end_customer.phone_number}
                            readOnly
                          />
                        </div>
                      </div>
                    </Row>
                  </Dialog>
                  <></>
                  <Dialog
                    header={t("orderPage.DetailsInfo") ?? "Details Info"}
                    visible={showDetails}
                    style={{ width: "25vw" }}
                    onHide={() => setShowDetailes(false)}
                  >
                    <div className="" style={{ padding: "20px" }}>
                    <Row className="">
                          <Tag
                            severity="warning"
                            value={orderDetails?.status}
                            rounded
                          ></Tag>
                        </Row>
                        <Row>
                          <div className="formgrid grid">
                            <div className="field col">
                              <Dropdown
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.value)}
                                options={statusOptions}
                                optionLabel="label"
                                optionValue="value"
                                editable
                                placeholder={
                                  t("orderPage.SelectANewStatus") ??
                                  "Select a new status"
                                }
                                className="w-full md:w-14rem mt-3"
                              />
                            </div>
                          </div>
                        </Row>
                        <Row>
                        <Button
                                onClick={
                                  selectedStatus === undefined
                                    ? () => {}
                                    : () => SubmitStatus(orderDetails?.code)
                                }
                                severity="info"
                                className="mt-1"
                                label={
                                  t("orderPage.SubmitNewStatus") ??
                                  "Submit New Status"
                                }
                              ></Button>
                        </Row>
                    </div>
                    <Row>
                      <div className="flex flex-nowrap gap-4"></div>
                    </Row>
                  </Dialog>
                </>
              ))}
            </div>
          )}
        </>
      )}
    </MainLayout>
  );
};

export default Orders;
