import { Card } from "primereact/card";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";

import Charts from "../components/Charts";
import MainLayout from "../containers/MainLayout";
import DoughnutChartDemo from "../components/Charts/DoughnutChartDemo";
import StackedBarDemo from "../components/Charts/StackedBarDemo";
import BasicDemo from "../components/Charts/BasicDemo";
import AppWidgetSummary from "../components/SummeryCard";
import { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { getAnalytics } from "../services";
import { Button } from "primereact/button";

const Home = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>();
  const [dates, setDates] = useState<any>(null);
console.log(dates , 'hello')
  useEffect(()=>{
    getAnalytics().then((res)=>setData(res?.analysis))
  },[])
   const search = () =>{
    getAnalytics(dates[0], dates[1]).then((res)=>setData(res?.analysis))

   }
  return (
    <MainLayout>
      <div className="p-4 home">
      <div className="grid gap-3">
      <h1 className="ml-2 text-2xl mt-2	font-semibold	"> Dashboard</h1>
      <div className="card flex ">
                      <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput 
                      showIcon
                      showButtonBar
                      showMinMaxRange
                      className="calender"
                      />
            </div>
            <div>
            <Button className="ghost" id="signUp" severity="danger" onClick={()=>search()}>
                  {t("main.search")}
                </Button>
            </div>
            </div>
      <div className="grid  mt-4 gap-0 mx-0">
      <div className="lg:col-4 md:col-4">
        <AppWidgetSummary
          title="Product In Import List"
          total={data?.importLists}
          color="success"
          icon={<img src="https://i.pinimg.com/originals/62/40/1f/62401f1991ac75f6e9cdc0258cfd8ae3.gif" 
           style={{height:'180px' , width:'200px'}}
          />}
        />
</div>
<div className="lg:col-4 md:col-4">
        <AppWidgetSummary
          title="Weekly Sales"
          total={data?.totals.weekly_sales}
          color="info"
          icon={<img src="https://i.pinimg.com/originals/0a/f3/c9/0af3c9613761d2d2394d99312aeba397.gif" />}
        />
</div>
<div className="lg:col-4 md:col-4">
        <AppWidgetSummary
          title="Weekly Orders"
          total={data?.totals.weekly_orders}
          color="warning"
          icon={<img src="https://media.tenor.com/4dKr3x9KxBcAAAAM/hungry-hangry.gif" />}
        />
        </div>
      </div>
        <div className="my-4 ml-1 mr-1 grid gap-2">
          <div className="md:col-5 lg:col-5">
            <Card className=" w-full overflow-hidden custom-box-shadow">
              <h1 className="text-xl font-semibold">
                {" "}
                {t("homePage.TotalProductsChartsForAllStores") ??
                  "Total products charts for all stores"}{" "}
              </h1>

              <DoughnutChartDemo done={data?.complaints?.DONE.TECHNICAL_ISSUES}
               progress = {data?.complaints?.IN_PROGRESS.TECHNICAL_ISSUES}
               pending = {data?.complaints?.PENDING.TECHNICAL_ISSUES}
              classNameProp="w-full h-[400px]" />
            </Card>
            </div>
            <div className="md:col-6 lg:col-6">
            <Card className=" w-full  overflow-hidden custom-box-shadow">
              <h1 className="text-xl font-semibold">
                {" "}
                {t("homePage.TotalOrdersChartsForAllStores") ??
                  "Total Orders charts for all stores"}{" "}
              </h1>

              <StackedBarDemo classNameProp="w-full h-[400px]"/>
            </Card>
            </div>
        </div>

        <div className="my-4 ml-1 mr-1 grid gap-2">
        <div className="md:col-6 lg:col-6">
            <Card className=" w-full  overflow-hidden custom-box-shadow">
              <h1 className="text-xl font-semibold">
                {" "}
                {t("homePage.SalesByQuarterForAllStores") ??
                  "Sales by Quarter for all stores"}{" "}
              </h1>

              <BasicDemo classNameProp="w-full h-[400px]"/>
            </Card>
</div>
<div className="md:col-5 lg:col-5">
            <Card className=" w-full  overflow-hidden custom-box-shadow">
              <h1 className="text-xl font-semibold">
                {" "}
                {t("homePage.SalesByMonthInAllStores") ??
                  "Sales by month in all stores"}{" "}
              </h1>

              <Charts classNameProp="w-full h-[400px]"/>
            </Card>
            </div>
        </div>

      </div>
    </MainLayout>
  );
};

export default Home;
