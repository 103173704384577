import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Link, useParams } from "react-router-dom";
import { Item } from "../../modules/Retailer.modules";
import ProductTemplate from "../templates/ProductTemplate";
import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Galleria } from "primereact/galleria";
import { InputNumber } from "primereact/inputnumber";
import { Tag } from "primereact/tag";
import { addToCart, subProductsWithPossibility } from "../../services";
import { useTranslation } from "react-i18next";
import { trimText, processProductInfo } from "../../helpers/helper";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";

type Props = {
  item?: Item;
  DeleteFromStore: (data: any) => void;
  addToStoreCart: (data: any) => void;
};
function StoreCard({ item, DeleteFromStore, addToStoreCart }: Props) {
  const params = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any>({});
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [displayedPrice, setDisplayedPrice] = useState<any>(null);
  const [quantity, setQuantity] = useState<any>();
  const [productPrice, setProductPrice] = useState<any>();
  const [Attributes, setAttributes] = useState<any>([]);
  const [possibility, setPossibility] = useState<any>([]);
  const { t, i18n } = useTranslation();
  let { trimmedText, isTrimmed } = trimText(
    item?.retailer_products?.title ?? item?.title
  );
  const [textToDisplay, setTextToDisplay] = useState(
    processProductInfo(
      item?.retailer_products?.description ?? item?.description ?? ""
    )
  );

  useEffect(() => {
    subProductsWithPossibility(item?.id, parseInt(params.id as any)).then(
      (res) => {
        setAttributes(res?.attribuits);
        setPossibility(res?.possibility);
      }
    );
  }, [params.product_id]);

  useEffect(() => {
    const matchedSubProduct =
      possibility &&
      possibility.find((subProduct: any) => {
        return subProduct!.combinations.every((subCombination: any) => {
          return Object.entries(selectedValues).some(([attrId, attrValue]) => {
            return (
              subCombination!.attribute_name_id === parseInt(attrId) &&
              subCombination!.attribute_value_id === attrValue
            );
          });
        });
      });

    if (matchedSubProduct) {
      setSelectedProductId(matchedSubProduct.sub_products_id);
      setDisplayedPrice(matchedSubProduct.sales_price);
    } else {
      setSelectedProductId(null);
      setDisplayedPrice(null);
    }
  }, [selectedValues, possibility]);

  const handleChange = (id: any, value: any) => {
    setSelectedValues((prevValues: any) => ({
      ...prevValues,
      [id]: value,
    }));
  };
  const itemTemplate = (image: any) => {
    return (
      <img
        src={image}
        alt={image}
        style={{ width: "10rem", display: "flex" }}
      />
    );
  };
  const addToCart = () => {
    let data = {
      connect_store_id: parseInt(params.id as any),
      due_date: "2024-12-5",
      sub_product_id: selectedProductId ?? item?.subProducts[0]?.id,
      quantity: quantity ?? item?.subProducts[0]?.quantity,
    };

    addToStoreCart(data);
    let arr = [];
    arr.push(displayedPrice);
    const calorieTotal = arr?.reduce((acc: any, sum: any) => acc + sum, 0);

    setProductPrice(displayedPrice);
  };
  return (
    <>
      <></>
      {
        <Dialog
          header={t("storePage.ProductDetails") ?? "Product Details"}
          visible={show}
          style={{ width: "70vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setShow(false)}
          footer={
            <div className="mt-3">
              <Button
                label={t("storePage.AddToCart") ?? "Add To Cart"}
                size="small"
                icon="pi pi-bold pi-shopping-cart"
                severity="danger"
                onClick={() => addToCart()}
                className="mt-4"
                disabled={item?.quantity && item?.quantity > 0 ? false : true}
              ></Button>
              <Button
                label={t("storePage.Cancel") ?? "Cancel"}
                severity="danger"
                outlined
                size="small"
                onClick={() => setShow(false)}
                className="mt-4"
              ></Button>
            </div>
          }
        >
          <section className="body-page overflow-hidden bg-[#eff3f8] font-poppins dark:bg-gray-800">
            <div>
              <div className="flex flex-wrap justify-between">
                <div className="mt-0 w-full md:w-1/2 bg-white  dark:bg-gray-800">
                  <div className="grid">
                    <div className="col-6 pb-0">
                      {item && (
                        <Galleria
                          style={{ maxWidth: "100%" }}
                          value={item?.images}
                          numVisible={1}
                          item={itemTemplate}
                          showThumbnails={false}
                          showIndicators
                          className="mt-2"
                        />
                      )}
                    </div>
                    <div className="col-6 mt-4" style={{ display: "grid" }}>
                      <div className="grid">
                        <div className="col">
                          <span className="mt-5">
                            {item?.quantity && item?.quantity > 0 ? (
                              <Tag
                                severity="success"
                                icon="pi pi-check"
                                value={t("storePage.Available") ?? "Available"}
                              ></Tag>
                            ) : (
                              <Tag
                                severity="warning"
                                value={
                                  t("storePage.OutOfStock") ?? "Out of stock"
                                }
                              ></Tag>
                            )}
                          </span>
                        </div>
                        <div className="grid">
                          <div></div>
                        </div>
                      </div>
                      <h2 className="text-lg">
                        {" "}
                        {item?.retailer_products?.title ?? item?.title}
                      </h2>

                      <h4 className="text-md">
                        {t("storePage.Category") ?? "Category:"}
                        <Tag
                          severity="info"
                          value={`${item?.category.title}`}
                          rounded
                          className="mx-2"
                        ></Tag>
                      </h4>

                      <h3 className="mb-2 mt-0 text-md font-bold dark:text-gray-400">
                        {t("storePage.ProductDescription") ??
                          "Product Description:"}

                        <span className="font-medium  text-sm tracking-tight text-black mt-1 ml-2">
                          {textToDisplay}
                        </span>
                      </h3>

                      <h2 className="text-md font-bold dark:text-gray-300">
                        {t("storePage.Price") ?? "Price:"}
                        <span className="inline-block  ml-2 	text-cyan-900	 ">
                          ${" "}
                          {displayedPrice
                            ? displayedPrice
                            : item?.retailer_products?.price
                            ? item?.retailer_products?.price
                            : item?.listing_price}
                        </span>
                      </h2>
                    </div>
                  </div>
                  <hr className="mt-5 mb-2"></hr>
                  <div className="col mt-0">
                    <div>
                      <div className="flex items-center justify-between">
                        <div className="grid mb-2 mt-2 gap-4">
                          {Attributes &&
                            Attributes?.map((v: any) => (
                              <div key={v?.id}>
                                <Dropdown
                                  id={v?.id}
                                  value={selectedValues[v?.id] || null}
                                  options={v?.values}
                                  optionLabel="value"
                                  optionValue="id"
                                  onChange={(e) => handleChange(v.id, e.value)}
                                  placeholder={`Select ${v?.name}`}
                                  className="w-full"
                                />
                              </div>
                            ))}
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                 {item?.quantity && item?.quantity > 0 ? <div className="">
                   <div className="col">
                      <label className="mb-2" htmlFor="quantity">
                        {" "}
                        {t("storePage.Quantity:") ?? "Quantity:"}{" "}
                      </label>
                    </div>
                    <div className="col change-color-inputs">
                      <InputNumber
                        placeholder={t("storePage.Quantity") ?? "Quantity:"}
                        showButtons
                        value={quantity}
                        onChange={(e) => setQuantity(e.value)}
                        name="quantity"
                        required
                      />
                    </div>
                  </div> :   <Message  content={t('emptyMessage.quantityMsg')} severity="info" />}
                </div>
              </div>
            </div>
          </section>
        </Dialog>
      }
      <></>
      <div className="bg-white w-full rounded border m-2">
        <div className="relative w-full mb-2" onClick={() => setShow(true)}>
          <div>
            <div className="product-grid-item-content">
              {item?.images && item?.images?.length > 0 && (
                <Carousel
                  value={item.images}
                  numScroll={1}
                  numVisible={1}
                  itemTemplate={ProductTemplate}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mb-6  mx-2">
          <div className="h-0 ">
            <div className="group relative  flex justify-center">
              <h3 className="text-md font-semibold dark:text-gray-300">
                {trimmedText}
              </h3>
              {isTrimmed ? (
                <span className="absolute bottom-5 scale-0 transition-all rounded  border border-[#f50c44] bg-white p-2 text-xs text-black group-hover:scale-100">
                  ✨{item?.retailer_products?.title ?? item?.title}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mb-0 mx-2">
          <div className="grid p-2 mt-4">
            <h2 className="text-md font-medium dark:text-gray-300">
              {t("storePage.Price") ?? "Price:"}
            </h2>

            <span className="inline-block ml-2 font-medium text-md text-gray-700 dark:text-gray-400">
              {item?.retailer_products &&
              item?.retailer_products?.sub_products &&
              item?.retailer_products?.sub_products?.length >= 1
                ? item?.retailer_products?.sub_products[0]?.price
                : item?.retailer_products?.price ?? item?.listing_price}{" "}
              $
            </span>
          </div>
        </div>
        <div className="-mt-px pt-2 flex justify-between   ">
          <Button
            className="flex  mb-4 mr-3 ml-2 p-2 text-sm font-semibold "
            severity="danger"
            icon="pi pi-eye"
            label={t("storePage.ViewOnStore") ?? "View On Store"}
            outlined
          ></Button>
          <Button
            className="flex mr-2 mb-4 ml-3  py-2 px-1 text-sm font-semibold  "
            severity="danger"
            outlined
            icon="pi pi-trash pi-bold"
            onClick={() => DeleteFromStore(item)}
          ></Button>
        </div>
      </div>
    </>
  );
}

export default StoreCard;
