import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { useAuth } from "../AuthContext/AuthContext";
import { LoginDTO, RegisterDTO } from "../modules/Retailer.modules";
import { handleSuccess } from "../services/handleResponse";
import { authRegister, forgotPassword, resendOtp } from "../services";

import "../styles/login.scss";
import { Link } from "react-router-dom";
import VerifyDialog from "../components/VerifyDialog";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";

const Login = () => {
  const { login } = useAuth();

  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const [visible, setVisible] = useState(false);
  const [visibleCode, setVisibleCode] = useState(false);
  const [visibleVerify, setVisibleVerify] = useState<boolean>(false);
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("login.InvalidEmailAddress") ?? "Invalid email address")
      .required(t("login.EmailIsRequired") ?? "Email is required"),
    password: Yup.string()
      .required(t("login.PasswordIsRequired") ?? "password is required")
      .matches(
        /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,
        t("login.PasswordMustHaveAtLeast8CharactersIncluding") ??
          "Password must be at least 8 characters long and contain one uppercase letter and one or more symbols"
      ),
  });

  const loginform = useFormik<LoginDTO>({
    initialValues: new LoginDTO(),
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        const result = await login(loginform.values);
        if (result) {
          window.location.href = "/home";
        }
      } catch (error) {
        loginform.resetForm();
      }
    },
  });
  const [inputEmail, setInputEmail] = useState<string>(
    loginform.values.email ?? ""
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    onSubmit: async (data: any) => {
      try {
        const response = await forgotPassword(data);
        if (response) {
          handleSuccess(response.data);
          setVisible(false);
        }
      } catch (e) {
        console.error(e);
      }
    },
  });
  const getNewCodeFormik = useFormik({
    initialValues: {
      email: "",
    },

    onSubmit: async (data: any) => {
      try {
        const response = await resendOtp(data);
        if (response) {
          handleSuccess(response.data);
          setVisible(false);
        }
      } catch (e) {
        console.error(e);
      }
    },
  });
  const togglePanel = () => {
    setIsSignUpActive(!isSignUpActive);
  };
  const register = useFormik<RegisterDTO>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },

    validateOnBlur: true,
    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      try {
        const result = await authRegister(register.values);
        if (result) {
          setInputEmail(values.email);
          setVisibleVerify(true);
        }
      } catch (err) {
      }
    },
  });
  return (
    <>
  <div className="loginPage">
      <div className="App">
        <div id="container" className={`container ${isSignUpActive ? "right-panel-active" : ""}`}>
          <div className="form-container sign-up-container">
            <form
              onSubmit={register.handleSubmit}
              className="!grid grid-cols-2  h-auto content-center"
            >
              <img src="https://doshtu-app.com/public/assets/project-logo.svg" className="h-100 w-100" alt="main_logo"/>
              <h1 className="col-span-2">{t("createaccount")}</h1>

              <div className="flex flex-col text-start">
                <InputText
                  type="text"
                  // placeholder={t("firstname")}
                  defaultValue={register.values.first_name}
                  name="first_name"
                  value={register.values.first_name}
                  onChange={register.handleChange}
                  className={classNames({
                    "p-error":
                      register.touched.first_name && register.errors.first_name,
                  })}
                />
                <small className="p-error">{register.errors.first_name}</small>
              </div>

              <div className="flex flex-col text-start">
                <InputText
                  type="text"
                  // placeholder={t("lastname")}
                  name="last_name"
                  value={register.values.last_name}
                  onChange={register.handleChange}
                  className={classNames({
                    "p-error":
                      register.touched.last_name && register.errors.last_name,
                  })}
                />
                <small className="p-error">{register.errors.last_name}</small>
              </div>

              <div className="flex flex-col text-start col-span-2">
                <InputText
                  type="email"
                  name="email"
                  // placeholder={t("email")}
                  defaultValue={register.values.email}
                  onChange={register.handleChange}
                  className={classNames({
                    "p-error": register.touched.email && register.errors.email,
                  })}
                />
                <small className="p-error">{register.errors.email}</small>
              </div>

              <div className="flex flex-col text-start">
              <Password
                    placeholder={t("login.Password") ?? "Your Password"}
                    type="password"
                    value={register.values.password}
                    onChange={register.handleChange}
                    name="password"
                    onBlur={register.handleBlur}
                    onFocus={register.handleBlur}
                    toggleMask
                    promptLabel={
                      t("register.ConfirmPassword") ?? "Confirm Password"
                    }
                  />
              </div>

              <div className="flex flex-col text-start">
              <Password
                    placeholder={
                      t("register.ConfirmPassword") ?? "Confirm Password"
                    }
                    type="password"
                    value={register.values.password_confirmation}
                    onChange={register.handleChange}
                    name="password_confirmation"
                    onBlur={register.handleBlur}
                    onFocus={register.handleBlur}
                    toggleMask
                    promptLabel={
                      t("register.ConfirmPassword") ?? "Confirm Password"
                    }
                    weakLabel={t("register.Weak") ?? "Weak"}
                    mediumLabel={t("register.Medium") ?? "Medium"}
                    strongLabel={t("register.Strong") ?? "Strong"}
                  />
              </div>

              <div className="col-span-2">
                <Button type="submit" disabled={register.isSubmitting}
               label={t("register.Register") ?? "Register"}
                >
                </Button>
              </div>
            </form>
          </div>

          {visibleVerify && (
          <VerifyDialog
            passEmail={inputEmail}
            isOpen={visibleVerify}
            onOpenChange={() => setVisibleVerify(false)}
          />
        )}


          <div className="form-container sign-in-container">
            <form onSubmit={loginform.handleSubmit}>
            <img src="https://doshtu-app.com/public/assets/project-logo.svg" className="h-100 w-100" alt="main_logo"/>
              <h1>{t("signin")}</h1>
              <div className="flex flex-col text-start w-2/3 p-float-label">
                <InputText
                  type="email"
                  name="email"
                  // placeholder={t("email")}
                  defaultValue={loginform.values.email}
                  onChange={loginform.handleChange}
                  className={classNames({
                    "p-error": loginform.touched.email && loginform.errors.email,
                  })}
                />
                <label className="p-error">Email {loginform.errors.email}</label>
              </div>

              <div className="flex flex-col text-start w-2/3 p-float-label">
              <InputText
                  type="password"
                  placeholder={t("login.Password") ?? "Your Password"}
                  name="password"
                  value={loginform.values.password}
                  onChange={loginform.handleChange}
                />
              </div>

              <div className="">
                    <div>
                <a
                  onClick={() => setVisible(true)}
                  className="font-bold text-[rgb(245,12,68)] pt-2 hover:border-b hover:border-[#f50c44]  hover:cursor-pointer"
                >
                  {t("login.ForgotPassword?") ?? "Forgotten your password?"}
                </a>
              </div>
              <div>
                <a
                  onClick={() => setVisibleCode(true)}
                  className="font-bold text-[#0E1530] pt-2 hover:border-b hover:border-[#f50c44]  hover:cursor-pointer"
                >
                  {t("otpVerify.ReorderOTP") ?? "Get a New Code"}
                </a>
              </div>
              </div>

              <Button type="submit" disabled={loginform.isSubmitting}>
                {
                  t("signin")
                }
              </Button>
            </form>
          </div>

          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>{t("welcomemsg")}</h1>
                <p>{t("loginmsg")}</p>
                <Button className="ghost" id="signIn" onClick={togglePanel}>
                  {t("signin")}
                </Button>
              </div>

              <div className="overlay-panel overlay-right">
                <h1>{t("HelloSignIn")}</h1>
                <p>{t("Entermsg")}</p>
                <Button className="ghost" id="signUp" onClick={togglePanel}>
                  {t("signUp")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <Dialog
        header={t("login.PleaseEnterYourEmail") ?? "Please Enter your email"}
        visible={visible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
          <span className="p-float-label">
            <InputText
              id="email"
              name="email"
              required
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
              }}
            />
            <label htmlFor="input_value">{t("login.Email") ?? "E-mail"} </label>
          </span>

          <Button
            type="submit"
            severity="danger"
            label={t("login.Submit") ?? "Submit"}
          />
        </form>
      </Dialog>
      <Dialog
        header={t("login.PleaseEnterYourEmail") ?? "Please Enter your email"}
        visible={visibleCode}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setVisibleCode(false)}
      >
        <form
          onSubmit={getNewCodeFormik.handleSubmit}
          className="flex flex-column gap-2"
        >
          <span className="p-float-label">
            <InputText
              id="email"
              name="email"
              required
              value={getNewCodeFormik.values.email}
              onChange={(e) => {
                getNewCodeFormik.setFieldValue("email", e.target.value);
              }}
            />
            <label htmlFor="input_value">{t("login.Email") ?? "E-mail"} </label>
          </span>

          <Button
            type="submit"
            severity="danger"
            label={t("login.Submit") ?? "Submit"}
          />
        </form>
      </Dialog>
    </>
  );
};

export default Login;
