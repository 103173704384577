import React, { useEffect, useState } from "react";
import TextResult from "../components/TextResult";
import { listCustomers } from "../services";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import LoadingComponent from "../components/Loading";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

export default function EndCustomers() {
  const [customers, setCustomers] = useState([]);
  const [propEmpty, setPropEmpty] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    full_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone_number: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    address: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    order_status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  // Fetch customers data
  useEffect(() => {
    setLoading(true);

    listCustomers().then((res) => {
      if (res !== undefined) {
        if ("image" in res && "message" in res) {
          setPropEmpty(res);
        } else {
          setCustomers(res);
        }
        setLoading(false);
      }
    });
  }, [setCustomers]);

  const statusBodyTemplate = (rowData: any) => {
    return (
      <Tag
        value={rowData?.order?.status}
        style={{ backgroundColor: `#${rowData?.order?.code}` }}
      />
    );
  };
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <h1>
          {" "}
          {t("endCustomer.YourListofCustomers") ?? "Your List of Customers"}
        </h1>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={t("endCustomer.KeywordSearch") ?? "Keyword Search"}
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmpty && <TextResult body={propEmpty} />}
          {!propEmpty && (
            <div className="card new-data-table">
              <DataTable
                value={customers}
                paginator
                rows={10}
                tableStyle={{ minWidth: "50rem" }}
                breakpoint="960px"
                className="m-4"
                filters={filters}
                header={header}
                stripedRows
                showGridlines
                resizableColumns
                rowsPerPageOptions={[10, 15, 20, 50]}
                filterDisplay="menu"
                rowHover
                sortMode="multiple"
              >
                <Column
                  field="full_name"
                  header={t("endCustomer.FullName") ?? "Full Name"}
                  style={{ width: "20%" }}
                  filter
                  filterPlaceholder="Search by name"
                ></Column>
                <Column
                  field="email"
                  header={t("endCustomer.Email") ?? "Email"}
                  style={{ width: "20%" }}
                  filter
                  filterPlaceholder="Search by email"
                ></Column>
                <Column
                  field="phone_number"
                  header={t("endCustomer.PhoneNumber") ?? "Phone Number"}
                  style={{ width: "20%" }}
                  filter
                  filterPlaceholder="Search by number"
                ></Column>
                <Column
                  field="address"
                  header={t("endCustomer.Address") ?? "Address"}
                  style={{ width: "20%" }}
                  filter
                  filterPlaceholder="Search by adress"
                ></Column>
                <Column
                  field="order_status"
                  header={t("endCustomer.OrderStatus") ?? "Order status"}
                  style={{ width: "20%" }}
                  body={statusBodyTemplate}
                  filter
                  filterPlaceholder="Search by status"
                ></Column>
              </DataTable>
            </div>
          )}
        </>
      )}
    </MainLayout>
  );
}
