import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  listEmployees,
  getCities,
  getCountries,
  getMnicipalities,
  getNeighborhoods,
  addEmployee,
  listRoles,
  deleteEmployee,
  updateEmployee,
} from "../services";
import {
  Employee,
  EmployeeDTO,
  IPagination,
} from "../modules/Retailer.modules";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import LoadingComponent from "../components/Loading";
import {
  CREATE_EMPLOYEES,
  DELETE_EMPLOYEES,
  UPDATE_EMPLOYEES,
} from "../permissions/permissions";
import { FilterMatchMode } from "primereact/api";
import * as Yup from "yup";
import { classNames } from "primereact/utils";

function Employees() {
  let emptyEmployee = new Employee();
  const [employees, setEmployees] = useState<any>([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteEmployeeDialog, setDeleteEmployeeDialog] = useState(false);
  const [employee, setEmployee] = useState(emptyEmployee);
  const [submitted, setSubmitted] = useState(false);
  const [propEmpty, setPropEmpty] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    last_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
  });
  const { t, i18n } = useTranslation();
  const [Country, setCountry] = useState<any>();
  const [City, setCity] = useState<any>();
  const [Municipality, setMunicipality] = useState<any>();
  const [Neighborhood, setNeighborhood] = useState<any>();
  const [rolesList, setRolesList] = useState<any>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const isRTL = i18n.language === "ar";

  const toast = useRef(null);
  const dt = useRef(null);
  const validationSchema = Yup.object({
    first_name: Yup.string().required(
      t("register.FirstNameIsRequired") ?? "First Name is required"
    ),
    email: Yup.string()
      .email(t("register.InvalidEmailAddress") ?? "Invalid email address")
      .required(t("register.EmailIsRequired") ?? "Email is required"),
    password: Yup.string()
      .required(t("register.PasswordIsRequired") ?? "Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,
        t("register.PasswordMustHaveAtLeast8CharactersIncluding") ??
          "Password must have at least 8 characters, including one uppercase letter and at least one symbol"
      ),
  });
  function filterEmptyValues(obj: any) {
    const newObj: any = {};
    for (const key in obj) {
      if (obj[key] !== "" && obj[key] !== 0) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
  const saveProduct = () => {
    setSubmitted(true);
    if (employeeForm.values.first_name.trim()) {
      let _employees = [...employees];
      let newData = filterEmptyValues(employeeForm.values);

      if (newData.id) {
        const index = findIndexById(newData.id);
        _employees[index] = newData;
        updateEmployee(newData, newData.id).then((res) => {
          if (res) {
            const index = employees.findIndex(
              (emp: any) => emp.id === newData.id
            );
            if (index !== -1) {
              let updatedEmployees = [...employees];
              updatedEmployees[index] = newData;
              setEmployees(updatedEmployees);
            }
          }
          employeeForm.resetForm();
        });
      } else {
        addEmployee(newData).then((res) => {
          if (res) {
            setEmployees((prevItems: any) => [...prevItems, res]);
            setShow(false);
            employeeForm.resetForm();
            setProductDialog(false);
          }
        });
      }

      setProductDialog(false);
    }
  };
  const employeeForm = useFormik<EmployeeDTO>({
    initialValues: new EmployeeDTO(),
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: saveProduct,
  });
  useEffect(() => {
    setLoading(true);

    listEmployees().then((res) => {
      if ("image" in res && "message" in res) {
        setPropEmpty(res);
      } else {
        setEmployees(res);
      }
      setLoading(false);
      getCountries().then((res) => setCountry(res));
      listRoles().then((res) => setRolesList(res));
    });
  }, []);

  const options = rolesList?.map((role: any) => ({
    label: role,
    value: role,
  }));
  const statusOptions = [
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "BLOCKED", value: "BLOCKED" },
  ];

  const onCountryChange = async (e: any) => {
    const value = e.target.value as number;
    employeeForm.setFieldValue("country_id", value);
    setShow(true);
    await getCities(e.target.value).then((res) => setCity(res));
  };

  const onCityChange = async (e: any) => {
    const value = e.target.value as number;
    employeeForm.setFieldValue("city_id", value);
    await getMnicipalities(e.target.value).then((res) => setMunicipality(res));
  };

  const onMunicipalityChange = async (e: any) => {
    const value = e.target.value as number;
    employeeForm.setFieldValue("municipality_id", value);
    await getNeighborhoods(e.target.value).then((res) => setNeighborhood(res));
  };
  const onNeighborhoodChange = (e: any) => {
    const value = e.target.value as number;
    employeeForm.setFieldValue("neighborhood_id", value);
  };
  const openNew = () => {
    setEmployee(emptyEmployee);
    setIsEditMode(false);
    setSubmitted(false);
    setProductDialog(true);
  };
  const hideDialog = () => {
    setEmployee(emptyEmployee);
    const test1: EmployeeDTO = { ...emptyEmployee };

    employeeForm.setValues(test1);
    setSubmitted(false);
    setProductDialog(false);
  };
  const findIndexById = (email: any) => {
    let index = -1;

    for (let i = 0; i < employees.length; i++) {
      if (employees[i].email === email) {
        index = i;
        break;
      }
    }

    return index;
  };

  const employeeDialogFooter = (
    <React.Fragment>
      <Button
        label={t("main.Cancel") ?? "Cancel"}
        icon="pi pi-times"
        outlined
        severity="danger"
        onClick={hideDialog}
        iconPos={isRTL ? "right" : "left"}
        className="m-2"
      />
      <Button
        label={t("main.Save") ?? "Save"}
        icon="pi pi-check"
        iconPos={isRTL ? "right" : "left"}
        type="submit"
        severity="danger"
        onClick={() => {
          employeeForm.handleSubmit();
          {
            isEditMode && saveProduct();
          }
        }}
        className="m-2"
      />
    </React.Fragment>
  );
  const editEmployee = (employee: any) => {
    setEmployee({ ...employee });
    const test: EmployeeDTO = { ...employee };
    employeeForm.setValues(test);
    setIsEditMode(true);
    setProductDialog(true);
  };
  const deleteTheEmployee = () => {
    deleteEmployee(employee.id).then((res) => {
      let _employees = employees.filter((emp: any) => emp.id !== employee.id);
      setEmployees(_employees);
      setEmployee(emptyEmployee);
      setDeleteEmployeeDialog(false);
    });
  };
  const hideDeleteEmployeeDialog = () => {
    setDeleteEmployeeDialog(false);
  };
  const confirmDeleteEmployee = (employee: any) => {
    setEmployee(employee);
    setDeleteEmployeeDialog(true);
  };

  const deleteEmployeeDialogFooter = (
    <div className="mt-2">
      <Button
        label={t("main.No") ?? "No"}
        icon="pi pi-times"
        iconPos={isRTL ? "right" : "left"}
        outlined
        severity="danger"
        onClick={hideDeleteEmployeeDialog}
      />
      <Button
        label={t("main.Yes") ?? "Yes"}
        icon="pi pi-check"
        iconPos={isRTL ? "right" : "left"}
        severity="danger"
        onClick={deleteTheEmployee}
      />
    </div>
  );

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        {UPDATE_EMPLOYEES() ? (
          <Button
            icon="pi pi-pencil"
            rounded
            outlined
            className={`change-color-btn-outlined ${isRTL ? "ml-2" : "mr-2"}`}
            onClick={() => editEmployee(rowData)}
          />
        ) : (
          <></>
        )}
        {DELETE_EMPLOYEES() ? (
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            severity="danger"
            onClick={() => confirmDeleteEmployee(rowData)}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <div>
          {CREATE_EMPLOYEES() ? (
            <Button
              label={t("employeePage.AddNewEmployee") ?? "Add New Employee"}
              icon="pi pi-plus"
              iconPos={isRTL ? "right" : "left"}
              severity="danger"
              size="small"
              onClick={openNew}
            />
          ) : (
            <></>
          )}
        </div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={t("bankPage.KeywordSearch") ?? "Keyword Search"}
            id="search"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Toast ref={toast} />
          <div className="card p-4 new-data-table">
            <DataTable
              ref={dt}
              value={employees!}
              dataKey="id"
              breakpoint="960px"
              filters={filters}
              header={header}
            >
              <Column field="id" header="ID"></Column>
              <Column
                field="first_name"
                header={t("profilePage.FirstName") ?? "First Name"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="last_name"
                header={t("profilePage.LastName") ?? "Last Name"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="email"
                header={t("profilePage.Email") ?? "Email"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="type"
                header={t("employeePage.Role") ?? "Role"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="status"
                header={t("profilePage.Status") ?? "Status"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="created_at"
                header={t("main.CreatedAt") ?? "Created At"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>

              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "12rem" }}
              ></Column>
            </DataTable>
          </div>
          <Dialog
            visible={productDialog}
            style={{ width: "40rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header={
              t("employeePage.EmployeeInformation") ?? "Employee Information"
            }
            modal
            className=""
            footer={employeeDialogFooter}
            onHide={hideDialog}
          >
            <div className="formgrid grid">
              <div className="field col">
                <label className="mb-2" htmlFor="first_name">
                  {" "}
                  {t("profilePage.FirstName") ?? "First Name"}
                </label>
                <InputText
                  placeholder={t("profilePage.FirstName") ?? "First Name"}
                  name="first_name"
                  id="first_name"
                  value={employeeForm?.values?.first_name}
                  onChange={(e) =>
                    employeeForm.setFieldValue("first_name", e.target.value)
                  }
                  validateOnly={employeeForm.validateOnChange}
                  className={classNames({
                    "p-error":
                      employeeForm.touched.first_name &&
                      employeeForm.errors.first_name,
                  })}
                />
                {employeeForm.errors.first_name && (
                  <small className="p-error">
                    {employeeForm.errors.first_name}
                  </small>
                )}
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="last_name">
                  {" "}
                  {t("profilePage.LastName") ?? "Last Name"}{" "}
                </label>
                <InputText
                  placeholder={t("profilePage.LastName") ?? "Last Name"}
                  name="last_name"
                  id="last_name"
                  value={employeeForm?.values?.last_name}
                  onChange={(e) =>
                    employeeForm.setFieldValue("last_name", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label className="mb-2" htmlFor="email">
                  {" "}
                  {t("profilePage.Email") ?? "Email"}{" "}
                </label>
                <InputText
                  placeholder={t("profilePage.Email") ?? "Email"}
                  name="email"
                  id="email"
                  value={employeeForm?.values?.email}
                  onChange={(e) =>
                    employeeForm.setFieldValue("email", e.target.value)
                  }
                  validateOnly={employeeForm.validateOnChange}
                  className={classNames({
                    "p-error": isEditMode && employeeForm.touched.email,
                  })}
                />
                {!isEditMode && employeeForm.touched.email && (
                  <small className="p-error">{employeeForm.errors.email}</small>
                )}
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="password">
                  {t("employeePage.Password") ?? "Password"}{" "}
                </label>
                <InputText
                  placeholder={t("employeePage.Password") ?? "Password"}
                  name="password"
                  id="password"
                  value={employeeForm?.values?.password}
                  onChange={(e) =>
                    employeeForm.setFieldValue("password", e.target.value)
                  }
                  validateOnly={employeeForm.validateOnChange}
                  className={classNames({
                    "p-error": !isEditMode && employeeForm.touched.password,
                  })}
                />
                {!isEditMode && employeeForm.errors.password && (
                  <small className="p-error">
                    {employeeForm.errors.password}
                  </small>
                )}
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label className="mb-2" htmlFor="status">
                  {" "}
                  {t("profilePage.Status") ?? "Status"}{" "}
                </label>

                <Dropdown
                  value={employeeForm?.values?.status}
                  onChange={(e) =>
                    employeeForm.setFieldValue("status", e.target.value)
                  }
                  options={statusOptions}
                  optionLabel="label"
                  optionValue="value"
                  name="status"
                  id="status"
                  placeholder={t("profilePage.Status") ?? "Select Status"}
                  className="w-full"
                />
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="role">
                  {" "}
                  {t("employeePage.Role") ?? "Role"}
                </label>
                <Dropdown
                  value={employeeForm?.values?.role}
                  onChange={(e) =>
                    employeeForm.setFieldValue("role", e.target.value)
                  }
                  options={options}
                  optionLabel="label"
                  optionValue="value"
                  name="role"
                  id="role"
                  placeholder={t("employeePage.SelectARole") ?? "Select A Role"}
                  className="w-full"
                />
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label className="mb-2" htmlFor="country">
                  {" "}
                  {t("profilePage.SelectCountry") ?? "Select Country"}{" "}
                </label>
                <Dropdown
                  value={employeeForm.values.country_id}
                  onChange={onCountryChange}
                  name="country"
                  id="country"
                  options={Country}
                  optionValue="id"
                  optionLabel="name"
                  className="w-full"
                  placeholder={
                    t("profilePage.SelectCountry") ?? "Select Country"
                  }
                />
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="city">
                  {" "}
                  {t("profilePage.SelectCity") ?? "SelectCity"}{" "}
                </label>
                {show === true ? (
                  <Dropdown
                    key={JSON.stringify(City)}
                    value={employeeForm.values.city_id}
                    onChange={onCityChange}
                    name="city"
                    id="city"
                    options={City}
                    optionValue="id"
                    optionLabel="name"
                    className="w-full"
                    placeholder={t("profilePage.SelectCity") ?? "Select a City"}
                  />
                ) : (
                  <InputText
                    value={
                      employeeForm.values.city_id
                        ? employeeForm.values.city_id!.toString()
                        : ""
                    }
                    disabled
                  />
                )}
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label className="mb-2" htmlFor="municipality">
                  {" "}
                  {t("profilePage.SelectMunicipality") ??
                    "Select Municipality"}{" "}
                </label>
                {show === true ? (
                  <Dropdown
                    value={employeeForm.values.municipality_id}
                    onChange={onMunicipalityChange}
                    name="municipality"
                    id="municipality"
                    options={Municipality}
                    optionValue="id"
                    optionLabel="name"
                    className="w-full"
                    placeholder={
                      t("profilePage.SelectMunicipality") ??
                      "Select a Municipality"
                    }
                  />
                ) : (
                  <InputText
                    value={
                      employeeForm.values.municipality_id
                        ? employeeForm.values.municipality_id!.toString()
                        : ""
                    }
                    disabled
                  />
                )}
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="neighborhood">
                  {" "}
                  {t("profilePage.SelectNeighborhood") ??
                    "Select Neighborhood"}{" "}
                </label>
                <Dropdown
                  value={employeeForm.values.neighborhood_id}
                  onChange={onNeighborhoodChange}
                  name="neighborhood"
                  id="neighborhood"
                  options={Neighborhood}
                  optionValue="id"
                  optionLabel="name"
                  className="w-full"
                  placeholder={
                    t("profilePage.SelectNeighborhood") ?? "Select Neighborhood"
                  }
                />
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label className="mb-2" htmlFor="street">
                  {" "}
                  {t("profilePage.Street") ?? "Street"}{" "}
                </label>
                <InputText
                  placeholder={t("profilePage.Street") ?? "Street"}
                  name="street"
                  id="street"
                  value={employeeForm?.values?.street}
                  onChange={(e) =>
                    employeeForm.setFieldValue("street", e.target.value)
                  }
                />
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="building">
                  {t("profilePage.Building") ?? "Building"}
                </label>
                <InputText
                  placeholder={t("profilePage.Building") ?? "Building"}
                  name="building"
                  id="building"
                  value={employeeForm?.values?.building}
                  onChange={(e) =>
                    employeeForm.setFieldValue("building", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label className="mb-2" htmlFor="floor">
                  {t("profilePage.Floor") ?? "Floor"}
                </label>
                <InputText
                  placeholder={t("profilePage.Floor") ?? "Floor"}
                  name="floor"
                  id="floor"
                  value={employeeForm?.values?.floor}
                  onChange={(e) =>
                    employeeForm.setFieldValue("floor", e.target.value)
                  }
                />
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="apartment">
                  {" "}
                  {t("profilePage.Apartment") ?? "Apartment"}
                </label>
                <InputText
                  placeholder={t("profilePage.Apartment") ?? "Apartment"}
                  name="apartment"
                  id="apartment"
                  value={employeeForm?.values?.apartment}
                  onChange={(e) =>
                    employeeForm.setFieldValue("apartment", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="formgrid grid ">
              <div className="field col">
                <label className="mb-2" htmlFor="PostalCode">
                  {" "}
                  {t("profilePage.PostalCode") ?? "Postal Code"}
                </label>
                <InputText
                  placeholder={t("profilePage.PostalCode") ?? "Postal Code"}
                  name="postal_code"
                  id="postal_code"
                  value={employeeForm?.values?.postal_code}
                  onChange={(e) =>
                    employeeForm.setFieldValue("postal_code", e.target.value)
                  }
                />
              </div>
              <div className="field col">
                <label className="mb-2" htmlFor="more_details">
                  {" "}
                  {t("profilePage.MoreDetails") ?? "More Details"}
                </label>
                <InputText
                  placeholder={t("profilePage.MoreDetails") ?? "More Details"}
                  name="more_details"
                  id="more_details"
                  value={employeeForm?.values?.more_details}
                  onChange={(e) =>
                    employeeForm.setFieldValue("more_details", e.target.value)
                  }
                />
              </div>
            </div>
          </Dialog>
          {/* Delete */}
          <Dialog
            visible={deleteEmployeeDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header={t("main.Confirm") ?? "Confirm"}
            modal
            footer={deleteEmployeeDialogFooter}
            onHide={hideDeleteEmployeeDialog}
          >
            <div className="confirmation-content mt-2">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {employee && (
                <span>
                  {t("employeePage.AreYouSureYouWantToDelete") ??
                    "Are you sure you want to delete"}
                  <b className="p-1">{employee.first_name}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </>
      )}
    </MainLayout>
  );
}

export default Employees;
