import { t } from "i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

type Props = {
  handleSearch: (searchInput: any) => void;
};
function SearchBar({ handleSearch }: Props) {
  const [searchInput, setSearchInput] = useState("");

  const handleSubmit = (event: any) => {
    handleSearch(searchInput);
  };

  const handleSearchInputChange = (e: any) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSubmit(inputValue);
  };
  return (
    <div className="flex flex-row w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
      <form
        className="relative form-search "
        style={{ width: "inherit" }}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="p-inputgroup ">
          <InputText
            placeholder={t("main.SearchPlaceholder") ?? "Enter key words"}
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <Button
            className="p-button-danger"
            label={t("main.search") ?? "Search"}
            outlined
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
