import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { InputText } from "primereact/inputtext";

import React, { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Item, ItemTemp, ProductEditDTO } from "../../modules/Retailer.modules";
import { useTranslation } from "react-i18next";

type Props = {
  item: Item;
  index: number;
  itemtemporary?: ItemTemp;
  //handleChange: (values: any) => void;
  initialValues: ProductEditDTO;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};
const ListCard = ({
  item,

  initialValues,
  handleChange,
  itemtemporary,
}: Props) => {
  const [value, setValue] = useState(itemtemporary?.title ?? item?.title);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";

  return (
    <div
      key={item.id}
      className="flex flex-col rounded-lg bg-white  md:flex-row"
    >
      <div
        className={`relative flex flex-col justify-start p-6 ${
          isRTL ? "ml-4" : "mr-4"
        } `}
        key={`{rel-${item.id}}`}
      >
        {
          <>
            <img
              className=" h-80 w-48 rounded-t-lg object-cover md:h-auto md:!rounded-none md:!rounded-l-lg"
              src={`${item.images[0]} `}
              alt=""
            />
            <span
              className={`absolute top-0  px-2 py-1
             mt-2 rounded shadow-sm text-xs
              bg-white border-0 border-gray-600
              ${isRTL ? "ml-2 right-3" : "mr-2 left-3"}
              `}
            >
              {item.published_in_stores && (
                <AvatarGroup>
                  {item.published_in_stores?.map((store, index) => (
                    <Avatar image={store.logo} shape="circle" key={index} />
                  ))}
                </AvatarGroup>
              )}
            </span>
          </>
        }
      </div>
      <div className="flex flex-col justify-start p-2">
        <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
          {itemtemporary?.title ?? item.title}
        </h5>

        <div className="mt-2 flex flex-row">
          <Link
            to={{ pathname: `/products/${item.id}` }}
            className="cursor-pointer z-10"
          >
            {t("importListCard.ViewProduct") ?? "View Product"}
          </Link>
        </div>

        <h6 className="my-2">
          {t("importListCard.ProductName") ?? "Product Name"}
        </h6>

        <InputText
          placeholder={itemtemporary?.title ?? item?.title}
          name="title"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            handleChange(e);
          }}
          id="title"
        />
        <h6 className="my-2">
          {t("importListCard.ProductCategory") ?? "Product Category"}
        </h6>
        <InputText
          placeholder={item.category.title}
          name="category"
          value={item.category.title}
          id="category"
          readOnly
          disabled
        />
      </div>
    </div>
  );
};

export default ListCard;
