import React, { ChangeEvent, useContext, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { Category, FilterDTO } from "../../modules/Retailer.modules";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import SharedDataContext from "../../services/SharedData";
import { useFormik } from "formik";

type Props = {
  visible: boolean;
  onHide: () => void;
  handleFilter: (searchInput: any) => void;
  setFilterNum?: (filterNm: string) => void;
  handleChange: (
    name: string,
    val: string | number | boolean | Category[]
  ) => void;
  initialMinPrice?: number;
  initialMaxPrice?: number;
  initialAvailableProduct?: number;
  initialPublishedOnStore?: number;
  initialCategories?: Category[];
  visibleFilter?: boolean;
  onHideFilter?: () => void;
};
function Filter({
  visible,
  onHide,
  handleFilter,
  handleChange,
  setFilterNum,
  onHideFilter,
  initialMinPrice,
  initialMaxPrice,
  initialAvailableProduct,
  initialPublishedOnStore,
  initialCategories,
  visibleFilter,
}: Props) {
  const { t, i18n } = useTranslation();
  const [value1, setValue1] = useState<number>(initialMinPrice ?? 0);
  const [value3, setValue3] = useState<number>(initialMaxPrice ?? 0);
  const [checked, setChecked] = useState<number>(initialPublishedOnStore ?? 0);
  const [availableChecked, setAvailableChecked] = useState<number>(
    initialAvailableProduct ?? 0
  );
  const isRTL = i18n.language === "ar";
  const [valuesChanged, setValuesChanged] = useState<number>(0);
  const { categories } = useContext(SharedDataContext);
  const [filterCategories, setFilterCategories] = useState<Category[]>(
    categories || []
  );
  const [selectedCategories, setSelectedCategories] = useState(
    initialCategories ?? []
  );
  const selectedItemTemplate = (option: any) => {
    if (option && option.title) {
      return <span>{option.title} , </span>;
    } else {
      return null;
    }
  };
  const categoriesTemplate = (option: Category) => {
    return (
      <div className="flex align-items-center gap-2">
        <img alt={option.title} src={option.logo} width="32" />
        <span>{option.title} </span>
      </div>
    );
  };
  const panelFooterTemplate = () => {
    const length = selectedCategories ? selectedCategories.length : 0;

    return (
      <div
        className="py-2 px-3"
        style={{ display: visible ? "block" : "none" }}
      >
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };
  const publishOptions = [
    { name: t("main.All") ?? "All", key: "A", value: 0 },
    { name: t("main.Published") ?? "Published", key: "P", value: 1 },
    { name: t("main.NotPublished") ?? "Unpublished", key: "N", value: 2 },
  ];
  const availableOptions = [
    { name: t("main.All") ?? "All", key: "L", value: 0 },
    { name: t("main.Available") ?? "Available", key: "A", value: 1 },
    { name: t("main.OutOfStock") ?? "Out Of Stock", key: "O", value: 2 },
  ];
  const [selectedPublishOption, setSelectedPublishOption] = useState(
    publishOptions[initialPublishedOnStore ?? 0]
  );
  const [selectedAvailableOption, setSelectedAvailableOption] = useState(
    availableOptions[initialAvailableProduct ?? 0]
  );
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let totalSelectedFields = selectedCategories.length;

    if (availableChecked > -1) {
      totalSelectedFields++;
    }

    if (checked > -1) {
      totalSelectedFields++;
    }

    setValuesChanged(totalSelectedFields);

    if (setFilterNum) {
      setFilterNum(totalSelectedFields.toString());
    }

    handleFilter(selectedCategories);
    onHide();
  };
  const footerContent = (
    <div className="mt-4 add-margin-right">
      <Button
        label={t("main.Cancel") ?? "Cancel"}
        icon="pi pi-times"
        onClick={onHideFilter}
        className="p-button-text mx-2"
        severity="danger"
        outlined
      />
      <Button
        label={t("main.Apply") ?? "Apply"}
        type="submit"
        icon="pi pi-check"
        severity="danger"
        onClick={handleSubmit}
        autoFocus
      />
    </div>
  );
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col ">
          <div className="card flex justify-content-between change-color-inputs">
            <p className="font-bold block mb-2">
              {t("main.ChooseCategory") ?? "Choose Category"}
            </p>
            <div className="change-color-inputs">
              <MultiSelect
                value={selectedCategories}
                options={categories!}
                itemTemplate={categoriesTemplate}
                optionLabel="categories"
                placeholder={t("main.SelectCategory") ?? "Select Category"}
                onChange={(e: MultiSelectChangeEvent) => {
                  setSelectedCategories(e.value);
                  handleChange("categories", e.value);
                  if (e.value.length != "") {
                    setValuesChanged((prev) => prev + 1);
                  }
                }}
                selectedItemTemplate={selectedItemTemplate}
                panelFooterTemplate={panelFooterTemplate}
                className="w-full md:w-20rem change-color-inputs"
                display="chip"
              />
            </div>
          </div>
          <div className="card flex  justify-content-between mt-4">
            <p className="font-bold block mb-2">{t("main.Price") ?? "Price"}</p>
            <div className="card flex flex-wrap gap-3 ">
              <div className="flex-auto w-1/4 change-color-inputs">
                <label htmlFor="minPrice" className="font-bold block mb-2">
                  {t("main.Min") ?? "Min"}
                </label>
                <InputNumber
                  inputId="minPrice"
                  value={value1}
                  onValueChange={(e: any) => {
                    setValue1(e.value || 0);
                    handleChange("minPrice", e.value);
                    if (e.value) {
                      setValuesChanged((prev) => prev + 1);
                    }
                  }}
                  showButtons
                  mode="decimal"
                />
              </div>
              <div className="flex-auto w-1/4 change-color-inputs">
                <label htmlFor="maxPrice" className="font-bold block mb-2">
                  {t("main.Max") ?? "Max"}
                </label>
                <InputNumber
                  inputId="maxPrice"
                  value={value3}
                  onValueChange={(e: any) => {
                    setValue3(e.value || 0);
                    handleChange("maxPrice", e.value);
                    if (e.value) {
                      setValuesChanged((prev) => prev + 1);
                    }
                  }}
                  mode="decimal"
                  showButtons
                  min={0}
                />
              </div>
            </div>
          </div>
          <div className="card flex justify-content-between mt-4">
            <label htmlFor="stacked-buttons" className="font-bold block mb-2">
              {t("main.AvailableOnStore") ?? "Available"}
            </label>
            <div className="card flex justify-content-center">
              <div className="flex flex-wrap gap-3">
                {availableOptions.map((option) => {
                  return (
                    <div
                      key={option.key}
                      className="flex align-items-center change-color-inputs"
                    >
                      <RadioButton
                        inputId={option.key}
                        name="available"
                        value={option}
                        onChange={(e) => {
                          setSelectedAvailableOption(e.value);
                          setAvailableChecked(e.value.value);
                          handleChange("available", e.value.value);
                        }}
                        checked={selectedAvailableOption.key === option.key}
                      />
                      <label
                        htmlFor={option.key}
                        className={isRTL ? "mr-2" : "ml-2"}
                      >
                        {option.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="card flex justify-content-between mt-4">
            <label htmlFor="stacked-buttons" className="font-bold block mb-2">
              {t("main.PublishedOnStores") ?? "Published on Stores"}
            </label>
            <div className="card flex justify-content-center">
              <div className="flex flex-wrap gap-3">
                {publishOptions.map((option) => {
                  return (
                    <div
                      key={option.key}
                      className="flex align-items-center change-color-inputs"
                    >
                      <RadioButton
                        inputId={option.key}
                        name="publishedOnStore"
                        value={option}
                        onChange={(e) => {
                          setSelectedPublishOption(e.value);
                          setChecked(e.value.value);
                          handleChange("publishedOnStore", e.value.value);
                        }}
                        checked={selectedPublishOption.key === option.key}
                      />
                      <label
                        htmlFor={option.key}
                        className={isRTL ? "mr-2" : "ml-2"}
                      >
                        {option.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex justify-end items-center my-4 gap-x-2">
        {footerContent}
      </div>
    </div>
  );
}

export default Filter;
