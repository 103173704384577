import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { otpVerfiyDTO } from "../modules/Retailer.modules";
import { otpVerify } from "../services";
import { Dialog } from "primereact/dialog";
type Props = {
  isOpen: boolean;
  passEmail: string;
  onOpenChange: () => void;
};
const VerifyDialog = ({ isOpen, passEmail, onOpenChange }: Props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  let basicValues = {
    email: passEmail,
    code: "",
  };
  const validationSchemaVerify = Yup.object({
    email: Yup.string()
      .email(t("otpVerify.InvalidEmailAddress") ?? "Invalid email address")
      .required(t("otpVerify.EmailIsRequired") ?? "Email is required"),
    code: Yup.string()
      .required(t("otpVerify.CodeIsRequired") ?? "Code is required")
      .length(6, t("otpVerify.CodeMust6widgit") || "Code must be 6 digits!"),
  });
  const otpVerfiyForm = useFormik<otpVerfiyDTO>({
    initialValues: basicValues,
    validateOnChange: true,
    validationSchema: validationSchemaVerify,
    onSubmit: async () => {
      try {
        const result = await otpVerify(otpVerfiyForm.values);
        if (result) {
          onOpenChange();
        }
      } catch (error) {
        otpVerfiyForm.resetForm();
      }
    },
  });
  const verifyDialogFooter = (
    <div className="mt-3 add-margin-right">
      <Button
        label={t("main.Cancel") ?? "Cancel"}
        icon="pi pi-times"
        outlined
        onClick={onOpenChange}
        className="p-new-outlined-btn py-2 m-2"
      />
      <Button
        label={t("otpVerify.verify") ?? "Verify"}
        icon="pi pi-check"
        type="submit"
        className="p-button-danger py-2 m-2"
        onClick={() => {
          otpVerfiyForm.handleSubmit();
        }}
      />
    </div>
  );
  return (
    <Dialog
      header={t("otpVerify.OpenOTPModal") ?? "Account verify!"}
      visible={isOpen}
      style={{ width: "35vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={onOpenChange}
      footer={verifyDialogFooter}
    >
      <form className="" onSubmit={otpVerfiyForm.handleSubmit}>
        <div className="">
          <div className="login-form">
            <small className="p-error">{otpVerfiyForm.errors.email}</small>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-user"></i>
              </span>
              <InputText
                placeholder={t("otpVerify.Email") ?? "Your E-mail"}
                value={otpVerfiyForm.values.email}
                onChange={otpVerfiyForm.handleChange}
                name="email"
              />
            </div>
            <small className="p-error">{otpVerfiyForm.errors.code}</small>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-lock"></i>
              </span>
              <InputText
                type="code"
                placeholder={t("otpVerify.code") ?? "Your Code"}
                name="code"
                value={otpVerfiyForm.values.code}
                onChange={otpVerfiyForm.handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default VerifyDialog;
