import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useTranslation } from "react-i18next";

export default function BasicDemo(props: any) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const { t } = useTranslation();

    const { classNameProp } = props;

  useEffect(() => {
    const data = {
      labels: [
        t("charts.Q1") ?? "Q1",
        t("charts.Q2") ?? "Q2",
        t("charts.Q3") ?? "Q3",
        t("charts.Q4") ?? "Q4",
      ],
      datasets: [
        {
          label: t("charts.Sales") ?? "Sales",
          data: [540, 325, 702, 620],
          backgroundColor: [
            "rgba(255, 159, 64, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
          ],
          borderColor: [
            "rgb(255, 159, 64)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      innerHeight: "300px",
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <div className="card">
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        className={classNameProp}
      />
    </div>
  );
}
