import React, { useEffect, useState } from "react";
import { Tag } from "primereact/tag";

import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useParams, Link } from "react-router-dom";

import "../styles/product.scss";
import {
  addToImportList,
  getImportedList,
  getProduct,
  removeFromImportList,
} from "../services";
import { Item } from "../modules/Retailer.modules";
import { Dropdown } from "primereact/dropdown";
import {
  concatenateAndMoveDimensionsToEnd,
  processProductInfo,
} from "../helpers/helper";
import "./Product.css";
import { TabPanel, TabView } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { Carousel } from "primereact/carousel";
import { htmlToText } from "html-to-text";
import { Image } from 'primereact/image';

const Product = () => {
  let { id } = useParams();
  let [item, setItem] = useState<Item>();
  const [visible, setVisible] = useState(false);
  const [visibleTable, setVisibleTable] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Item[]>([]);

  const [columnHeaders, setColumnHeaders] = useState<any>();
  const [attributesVal, setAttributesVal] = useState<any>(item?.subProducts);
  const [selectedItem, setSelectedItem] = useState("" as any);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";

  // Fetch product data from server when the component mounts
  useEffect(() => {
    getProduct(id)
      .then((res) => {
        setItem(res);
        setColumnHeaders(Object.keys(res.subProducts[0]));
        setAttributesVal(res.subProducts);
      })
      .catch((error) => {});
    getImportedList().then((response) => {
      if (!("image" in response && "message" in response)) {
        setSelectedProduct(response);
      }
    });
  }, [id]);

  const handleImport = (data: any) => {
    let dataToSend = { product_id: data.id };
    addToImportList(dataToSend).then((res) => {
      setSelectedProduct((prevItems) => [...prevItems, data]);
    });
  };
  function handleRemove(data: any) {
    let productToDelete = { product_id: data.id };
    removeFromImportList(productToDelete).then((res) => {
      setSelectedProduct((prevItems: Item[]) =>
        prevItems.filter((e) => e.id !== data.id)
      );
    });
  }

  const imageTemplate = (data: any) => {
    return (
      <img
        src={`${data.image}  `}
        alt={data.title}
        className="w-6rem shadow-2 border-round"
      />
    );
  };

  const subProductsWithRatio = attributesVal?.map((subProduct: any) => {
    const { height, width, length, weight, ...rest } = subProduct;
    const dimensions = { height, width, length, weight };
    const updatedSubProductWithDimension = {
      ...rest,
      dimensions,
    };

    return updatedSubProductWithDimension;
  });

  const names = item?.names || [];
  const keys =
    subProductsWithRatio?.length > 0
      ? Object.keys(subProductsWithRatio[0])
      : [];
  const concatenatedArray = concatenateAndMoveDimensionsToEnd(keys, names);

  const allowExpansion = (rowData: any) => {
    return rowData.dimensions.length > 0;
  };
  const buttonTemplate = (rowData: any) => {
    return (
      <>
        <Button
          label={t("productPage.Info") ?? "Info"}
          type="button"
          onClick={() => {
            setVisibleTable(true);
            setSelectedItem(rowData);
          }}
          disabled={rowData.dimensions.length > 0 ? false : true}
        />
      </>
    );
  };
  const productTemplate = (image: any) => {
    return (
      <div className="relative overflow-hidden rounded-t h-[450px]">
        <Image className="w-full h-full object-fill" src={`${image}`} alt="" preview />
      </div>
    );
  };

  const [textToDisplay, setTextToDisplay] = useState(
    processProductInfo(item?.description ?? "")
  );
  
  return (
    <section className=" overflow-hidden bg-white ">
      <div className="max-w-6xl py-0 mt-2 mx-auto lg:py-0 md:px-6">
        <div className="flex flex-gap-2 flex-wrap -mx-0 justify-between">
          <div className="mt-0 pl-4 pr-4 w-full md:w-1/2 bg-white  dark:bg-gray-800">
            <div className="grid">
              <div className="col-12 md:col-12 lg:col-6 xl:col-6 ">
                <div className="col relative">
                  {item?.images && item?.images?.length > 1 ? (
                    <Carousel
                      value={item.images}
                      numScroll={1}
                      numVisible={1}
                      itemTemplate={productTemplate}
                    />
                  ) : (
                    <div className="relative overflow-hidden rounded-t">
                      {
                        <img
                          className="h-64 w-full object-cover object-center"
                          src={item?.images && item?.images[0]}
                          alt={item?.title}
                        />
                      }
                    </div>
                  )}
                  <span
                    className={`col absolute top-0 ${
                      isRTL ? "right-0" : "left-0"
                    }`}
                  >
                    {item &&
                    selectedProduct?.some((data) => data.id === item?.id) ? (
                      <Tag
                        severity="danger"
                        value={t("productsPage.Imported") ?? "Imported"}
                        icon="pi pi-check"
                        className="flex-end "
                      ></Tag>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className="col-12 md:col-12 lg:col-6 xl:col-6 justify-content-start mt-4">
                <span className="my-2">
                  {item?.quantity && item?.quantity > 0 ? (
                    <Tag
                      severity="success"
                      icon="pi pi-check"
                      value={t("productsPage.Available") ?? "Available"}
                    ></Tag>
                  ) : (
                    <Tag
                      severity="warning"
                      value={t("productsPage.OutOfStock") ?? "Out of stock"}
                    ></Tag>
                  )}
                </span>

                <h2 className="my-3 font-medium text-3xl dark:text-gray-300 ">
                  {item?.title}
                </h2>

                <h4 className="my-2 font-medium">
                  <Tag
                    severity="info"
                    value={`${item?.category.title}`}
                    rounded
                  ></Tag>
                </h4>

                <h3 className="font-medium my-2 text-base dark:text-gray-400">
                  <span className="leading-snug tracking-tight text-black dark:text-white ">
                    {textToDisplay}
                  </span>
                </h3>

                <h2 className="my-2 text-base  font-medium dark:text-gray-300">
                  {t("productPage.Price") ?? "Price:"}
                  <span className="inline-block underline px-2 py-1 text-base	text-blue-500 ">
                    $ {item?.listing_price}
                  </span>
                </h2>
                <hr className="my-3" />
                <div className="mb-2 flex justify-between mt-3  w-full">
                  <Button
                    className=" mr-2  text-sm mt-2 "
                    label={
                      t("productPage.OpenProductVariants") ??
                      "Open Product Variations"
                    }
                    severity="danger"
                    onClick={() => setVisible(true)}
                    rounded
                    outlined
                  />
                  <Button
                    className=" text-sm mt-2 "
                    label={`${
                      selectedProduct?.some((data) => data.id === item?.id)
                        ? t("productsPage.RemoveFromImportList") ??
                          "Remove from Product List"
                        : t("productsPage.AddToImportList") ??
                          "Add to import list"
                    }`}
                    severity="danger"
                    rounded
                    onClick={
                      selectedProduct.some(
                        (selectedProductId) => selectedProductId.id === item?.id
                      )
                        ? () => handleRemove(item)
                        : () => handleImport(item)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="my-4 w-full py-4  px-6 md:w-1/2 bg-white ">
            <div className="card">
              <TabView className="change-color">
                <TabPanel
                  className="change-color"
                  headerClassName="flex align-items-center"
                  header={t("productPage.ProcessTime") ?? "Process Time"}
                >
                  <h6 className="mb-4 text-sm">
                    {t("productPage.1BusinessDay") ?? "1 business day"}
                  </h6>
                </TabPanel>
                <TabPanel
                  className="change-color"
                  headerClassName="flex align-items-center"
                  header={t("productPage.ShippingTime") ?? "Shipping Time"}
                >
                  <h6 className="mb-1 text-sm">United Kingdom</h6>
                  <p className="mb-4 text-sm">
                    ({t("productPage.4BusinessDays") ?? "4 business days"})
                  </p>
                </TabPanel>
                <TabPanel
                  headerClassName="flex align-items-center"
                  header={t("productPage.ReturnPolicy") ?? "Return Policy"}
                >
                  <p className="m-0">
                    {t(
                      "productPage.ThisItemIsNonReturnableAndNonExchangeable"
                    ) ?? "This item is non-returnable and non-exchangeable."}
                  </p>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header={t("productPage.ProductVariants") ?? "Product Variants"}
        visible={visible}
        style={{ width: "55vw" }}
        onHide={() => setVisible(false)}
      >
        <div dir={isRTL ? "rtl" : "ltr"} className="flex flex-col gap-3 mt-3">
          {
            <>
              <DataTable
                value={subProductsWithRatio}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                dir={isRTL ? "ltr" : "ltr"}
                size="normal"
                dataKey="id"
                showGridlines
                stripedRows
              >
                {concatenatedArray?.length > 0 &&
                  concatenatedArray
                    .filter(
                      (col: any, index: number, self: any[]) =>
                        self.indexOf(col) === index
                    )
                    .filter(
                      (col: any) =>
                        col !== "subProductAttributes" &&
                        col !== "attribute_text_style" &&
                        col !== "active" &&
                        col !== "id" &&
                        col !== "price" &&
                        col != "default"
                    )
                    .map((col: any, index: number) => {
                      const isSubNameCol = item?.names.includes(col);
                      return col === "listing_price" ? (
                        <Column
                          key={index}
                          field={col}
                          header={
                            t("productPage.ListingPrice") ?? "Listing Price"
                          }
                        />
                      ) : col === "image" ? (
                        <Column
                          key={index}
                          field="image"
                          header={t("productPage.Image") ?? "Image"}
                          body={imageTemplate}
                        />
                      ) : col === "quantity" ? (
                        <Column
                          key={index}
                          field="quantity"
                          header={t("productPage.Quantity") ?? "Quantity"}
                        />
                      ) : col === "sku" ? (
                        <Column
                          key={index}
                          field="sku"
                          header={t("productPage.SKU") ?? "SKU"}
                        />
                      ) : col === "ratio" ? (
                        <Column
                          key={index}
                          field="ratio"
                          header={t("productPage.Ratio") ?? "Ratio"}
                        />
                      ) : col === "recommended_price" ? (
                        <Column
                          key={index}
                          field="recommended_price"
                          header={
                            t("productPage.RecommendedPrice") ?? "Sales Price"
                          }
                        />
                      ) : col && isSubNameCol ? (
                        <Column
                          key={index}
                          field={col}
                          header={col}
                          body={(rowData: any) => {
                            const attribute = rowData.subProductAttributes.find(
                              (attr: any) => attr.name === col
                            );
                            return attribute ? attribute.value : null;
                          }}
                        />
                      ) : col === "dimensions" ? (
                        <Column
                          expander={allowExpansion}
                          header={t("productPage.Details") ?? "Details"}
                          body={buttonTemplate}
                          key={index}
                          field={col}
                        />
                      ) : (
                        <Column key={col} field={col} header={col} />
                      );
                    })}
              </DataTable>
              <Dialog
                header={t("productPage.Dimensions") ?? "Dimensions"}
                visible={visibleTable}
                style={{ width: "25vw" }}
                onHide={() => setVisibleTable(false)}
              >
                {selectedItem && (
                  <div className="p-4">
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <td className="font-semibold">
                            {t("productPage.Height") ?? "Height:"}
                          </td>
                          <td>{selectedItem.dimensions?.height}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold">
                            {t("productPage.Weight") ?? "Weight:"}
                          </td>
                          <td>{selectedItem.dimensions?.weight}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold">
                            {" "}
                            {t("productPage.Length") ?? "Length:"}
                          </td>
                          <td>{selectedItem.dimensions?.length}</td>
                        </tr>
                        <tr>
                          <td className="font-semibold">
                            {t("productPage.Width") ?? "Width:"}
                          </td>
                          <td>{selectedItem.dimensions?.width}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </Dialog>
            </>
          }
        </div>
      </Dialog>
    </section>
  );
};

export default Product;
