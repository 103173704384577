import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { useContext, useEffect, useState } from "react";
import { BanksInfo, ChargeWallet, WalletInfo } from "../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { ChargeWalletDTO } from "../modules/Retailer.modules";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../components/Loading";
import MainLayout from "../containers/MainLayout";
import { FilterMatchMode } from "primereact/api";
import SharedDataContext from "../services/SharedData";

const Wallet = () => {
  const { updateAmount, balance } = useContext(SharedDataContext);

  const [walletInfo, setWalletInfo] = useState<any>({});
  const [showCharge, setShowCharge] = useState<boolean>(false);
  const [fullName, setFullName] = useState("");
  const [bankName, setBankName] = useState<any>();
  const { t, i18n } = useTranslation();
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    debit: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    credit: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    after_operation_balance: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    created_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    transaction_type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    related_model_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    related_model_type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    note: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [loading, setLoading] = useState(true);
  const isRTL = i18n.language === "ar";

  const Walletform = useFormik<ChargeWalletDTO>({
    initialValues: new ChargeWalletDTO(),
    validateOnChange: true,

    onSubmit: () => {
      ChargeWallet(Walletform.values).then((res) => {
        updateAmount(Walletform.values.amount);
        window.open(res.url, "_blank");
      });

      /*   const userString = localStorage.getItem("user");
      if (userString) {
        let userData = JSON.parse(userString);

        console.log("wallet", balance, Walletform.values.amount);
        userData.wallet = balance + Number(Walletform.values.amount);
        localStorage.setItem("user", JSON.stringify(userData));
      } */
      setShowCharge(false);
    },
  });

  const bankOptions = Array.isArray(bankName)
    ? bankName?.map((n: any) => ({
        label: n?.bank_name,
        value: n?.id,
      }))
    : [];

  const GetWalletInfo = () => {
    WalletInfo().then((res) => {
      setWalletInfo(res);
    });
  };

  const GetFullName = () => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      const userObject = JSON.parse(storedUser);
      const userFullName = userObject.fullName;
      setFullName(userFullName);
    }
  };

  const GetBanksList = () => {
    BanksInfo().then((res) => setBankName(res));
  };

  const Charge = () => {
    setShowCharge(true);
    GetBanksList();
  };
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <h1> {t("endCustomer.Transaction") ?? "Transaction Information"}</h1>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={t("walletPage.KeywordSearch") ?? "Keyword Search"}
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  useEffect(() => {
    setLoading(true);
    GetWalletInfo();
    GetFullName();
    setLoading(false);
  }, []);
  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div>
          <div className="p-6">
            <div className="grid">
              <div className="col">
                <Panel
                  header={
                    t("walletPage.WalletInformation") ?? "Wallet Information"
                  }
                >
                  <div className="grid">
                    <div className="md:col-6 lg:col-6">
                      <label className="mb-2" htmlFor="name">
                        {" "}
                        {t("walletPage.Name") ?? "Name"}
                      </label>
                      <InputText
                        placeholder={t("walletPage.Name") ?? "Name"}
                        name="name"
                        value={fullName}
                        readOnly
                      />
                    </div>
                    <div className="md:col-6 lg:col-6">
                      <label className="mb-2" htmlFor="balance">
                        {" "}
                        {t("walletPage.Balance") ?? "Balance"}
                      </label>
                      <InputText
                        placeholder={t("walletPage.Balance") ?? "Balance"}
                        name="Balance"
                        value={
                          balance !== undefined
                            ? balance.toString()
                            : walletInfo?.balance
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
            <DataTable
              value={walletInfo?.transactions}
              stripedRows
              showGridlines
              className="mt-4"
              resizableColumns
              rows={10}
              rowsPerPageOptions={[10, 15, 20, 50]}
              filterDisplay="menu"
              paginator
              rowHover
              sortMode="multiple"
              filters={filters}
              header={header}
            >
              {/* <Column field="id" header="TID" sortable></Column> */}
              <Column
                field="debit"
                header={t("walletPage.Debit") ?? "Debit"}
                sortable
                filter
                filterPlaceholder="Search by name"
              ></Column>
              <Column
                field="credit"
                header={t("walletPage.Credit") ?? "Credit"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="after_operation_balance"
                header={t("walletPage.Balance") ?? "Balance"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="transaction_type"
                header={t("walletPage.TransactionType") ?? "Transaction Type"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="related_model_id"
                header={t("walletPage.DocumentID") ?? "Document ID"}
                filter
                filterPlaceholder="Search by name"
                sortable
              ></Column>
              <Column
                field="related_model_type"
                header={t("walletPage.Reason") ?? "Reason"}
                filter
                filterPlaceholder="Search by name"
              ></Column>
              <Column
                field="note"
                header={t("walletPage.Note") ?? "Note"}
                filter
                filterPlaceholder="Search by name"
              ></Column>
            </DataTable>
            <div>
              <Button
                label={t("walletPage.ChargeWallet") ?? "Charge Wallet"}
                onClick={Charge}
                severity="warning"
                size="small"
                className="mt-4 change-color-btn"
              ></Button>
            </div>
          </div>
          <div className=" ">
            <Dialog
              header={t("walletPage.ChargeWallet") ?? "Charge Wallet"}
              visible={showCharge}
              className="	md:w-30rem lg:w-30rem"
              onHide={() => setShowCharge(false)}
              footer={
                <>
                  <div>
                    <Button
                      label={
                        t("walletPage.SaveTransaction") ?? "Save Transaction"
                      }
                      disabled={Walletform?.values?.amount > 0 ? false : true}
                      severity="danger"
                      size="small"
                      type="submit"
                      onClick={() => Walletform.handleSubmit()}
                      className="mt-4"
                    ></Button>
                    <Button
                      label={t("walletPage.Cancel") ?? "Cancel"}
                      severity="danger"
                      outlined
                      size="small"
                      onClick={() => setShowCharge(false)}
                      className="mt-4"
                    ></Button>
                  </div>
                </>
              }
            >
              <div className="grid overflow-hidden	">
                <div className="col ">
                  <label className="mb-2" htmlFor="amount">
                    {" "}
                    {t("walletPage.Amount") ?? "Amount"}{" "}
                  </label>
                  <InputNumber
                    placeholder={t("walletPage.Amount") ?? "Amount"}
                    name="amount"
                    value={Walletform?.values?.amount}
                    onChange={(e) =>
                      Walletform.setFieldValue("amount", e.value)
                    }
                    min={1}
                  />
                </div>
                <div className="col ">
                  <label className="mb-2" htmlFor="bank account">
                    {t("walletPage.BankAccount") ?? "Bank Account"}
                  </label>
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    value={Walletform?.values?.bank_account_id}
                    options={bankOptions}
                    onChange={(e) =>
                      Walletform.setFieldValue("bank_account_id", e.value)
                    }
                    placeholder={
                      t("walletPage.SelectBankAccount") ?? "Select Bank Account"
                    }
                  />
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default Wallet;
