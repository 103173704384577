import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useTranslation } from "react-i18next";

export default function StackedBarDemo(props: any) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const { t } = useTranslation();
 const { classNameProp } = props;

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );

    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        t("charts.January") ?? "January",
        t("charts.February") ?? "February",
        t("charts.March") ?? "March",
        t("charts.April") ?? "April",
        t("charts.May") ?? "May",
        t("charts.June") ?? "June",
        t("charts.July") ?? "July",
      ],
      datasets: [
        {
          type: "bar",
          label: t("charts.Orders") ?? "Orders",
          backgroundColor: documentStyle.getPropertyValue("--blue-400"),
          data: [50, 25, 12, 48, 90, 76, 42],
        },
        {
          type: "bar",
          label: t("charts.CanceledOrders") ?? "Canceled Orders",
          backgroundColor: documentStyle.getPropertyValue("--red-400"),
          data: [21, 84, 24, 75, 37, 65, 34],
        },
        {
          type: "bar",
          label: t("charts.CompletedOrders") ?? "Completed Orders",
          backgroundColor: documentStyle.getPropertyValue("--green-400"),
          data: [41, 52, 24, 74, 23, 21, 32],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <div className="card">
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        className={classNameProp}
      />
    </div>
  );
}
