import { useTranslation } from "react-i18next";
import { Image } from 'primereact/image';

type Props = {
  onChange: any;
  name: string;
  path?: string
  selectImage? : any[];
};

const FileUploader = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="file-upload-container">
        <div id="start">
          <i className="fa fa-download" aria-hidden="true"></i>
          <div>
            {t("fileUpload.SelectAFileOrDragHere") ??
              "Select a file or drag here"}
          </div>
        </div>
        <label className="file-upload-label">
          {t("fileUpload.ChooseAFile") ?? "Choose a File"}
        </label>
        <input
          type="file"
          multiple
          className="file-upload-input"
          name={props.name}
          onChange={props.onChange}
          required
        />
           {props.selectImage && props.selectImage.length >0 ?  
   props.selectImage?.map((image:any, index:any) => (
    <Image key={index} src={image} alt={`Selected ${index}`} width="250" height="90" preview  />
  ))
: 
<Image
src={props.path}
width="320" height="100" preview
/>
}
      </div>
   
    </>
  );
};

export default FileUploader;
