import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
import "../styles/global.css";
import "primereact/resources/themes/soho-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import { useAuth } from "../AuthContext/AuthContext";
import Products from "../pages/Products";
import ImportList from "../pages/ImportList";
import Profile from "../pages/Profile";
import Orders from "../pages/Orders";
import Product from "../components/Product";
import StoreContainer from "../containers/StoreContainer/StoreContainer";
import Wallet from "../pages/Wallet";
import Banks from "../pages/Banks";
import Plans from "../pages/Plans";
import Support from "../pages/Support";
import NotFound from "../pages/NotFound";
import EndCustomers from "../pages/EndCustomers";
import Integrations from "../pages/Integrations";
import { ToastContainer } from "react-toastify";
import { Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Employees from "../pages/Employees";
import AppNotifications from "../pages/AppNotifications";
import NSideBar from "../components/NSideBar";
import i18n from "../i18n";
import PaymentFailure from "../pages/payment/PaymentFailure";
import PaymentSuccess from "../pages/payment/PaymentSuccess";

const LayoutComponent = () => {
  const { user } = useAuth();
  const isRTL = i18n.language === "ar" || i18n.language === "ar-sy";
  const location = useLocation();
  const hideNavBarAndSideBar =
    location.pathname === "/payment-success" ||
    location.pathname === "/payment-failure";

  return (
    <div
      dir={isRTL ? "rtl" : "ltr"}
      style={{ display: "flex", height: "100%" , overflowX:'hidden'}}
    >
      {user && !hideNavBarAndSideBar && <NSideBar />}
      <div id="main" className="">
        <div className=" flex-1 ">
          {user && !hideNavBarAndSideBar && <NavBar />}
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
          />

          <Routes>
            {user ? (
              <>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/" element={<Home />}></Route>
                <Route path="/profile" element={<Profile />}></Route>
                <Route path="/products" element={<Products />}></Route>
                <Route path="/products/:id" element={<Product />}></Route>
                <Route path="/stores/:id" element={<StoreContainer />}></Route>
                <Route path="/import-list" element={<ImportList />}></Route>
                <Route path="/orders" element={<Orders />}></Route>
                <Route path="/wallet" element={<Wallet />}></Route>
                <Route path="/bank-accounts" element={<Banks />}></Route>
                <Route path="/plans" element={<Plans />}></Route>
                <Route path="/support" element={<Support />}></Route>
                <Route path="/end-customers" element={<EndCustomers />}></Route>
                <Route path="/integrations" element={<Integrations />}></Route>
                <Route path="/employees" element={<Employees />}></Route>
                <Route
                  path="/notifications"
                  element={<AppNotifications />}
                ></Route>
                <Route path="*" element={<NotFound />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="*" element={<Login />} />
              </>
            )}

            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-failure" element={<PaymentFailure />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;
