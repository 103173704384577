import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useState } from "react";
import {
  UpdateComplaint,
  createComplaint,
  getComplaint,
  getComplaintDefinitions,
} from "../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { AddComplaintDTO } from "../modules/Retailer.modules";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import FileUploader from "../components/FileUploader";
import { Dialog } from "primereact/dialog";
import TextResult from "../components/TextResult";
import { Dropdown } from "primereact/dropdown";
import LoadingComponent from "../components/Loading";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import {
  CREATE_COMPLAINTS,
  UPDATE_COMPLAINTS,
} from "../permissions/permissions";
import { Image } from 'primereact/image';

const Support = () => {
  const [complaints, setComplaints] = useState<any>([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [id, setId] = useState<any>();
  const [types, setTypes] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const [activeIndex, setActiveIndex] = useState(0);
  const [file, setFile] = useState<any>();
  const [selectedComplaintImages, setSelectedComplaintImages] = useState<any>([]);
  const complaintForm = useFormik<AddComplaintDTO>({
    initialValues: new AddComplaintDTO(),
    validateOnChange: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("attachments[]", file);
      formData.append("description", values.description);
      formData.append("type", values.type);
      createComplaint(formData).then((res) => {
        if (res) {
          setComplaints((prev: any) => [...complaints, res]);
          setActiveIndex(0);
          complaintForm.resetForm();
        }
      });
    },
  });

  const complaintFormEdit = useFormik<AddComplaintDTO>({
    initialValues: new AddComplaintDTO(),
    validateOnChange: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("attachments[]", file);
      formData.append("description", values.description);
      formData.append("type", values.type);
      UpdateComplaint(id, formData).then((res) => {
        if (res) {
          setComplaints((prev: any) =>
            prev.map((complaint: any) => {
              return complaint.id === res.id ? res : complaint;
            })
          );
        }
      });
      setShowEdit(false);
    },
  });

  const ShowModal = (rowData: any) => {
    setShowEdit(true);
    setId(rowData.id);
    complaintFormEdit.values.type = rowData.type;
    complaintFormEdit.values.description = rowData.description;
    complaintFormEdit.values.attachments = rowData.attachments;
  };

  const GetData = () => {
    getComplaint().then((res: any) => {
      if (res !== undefined) {
        setComplaints(res);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    GetData();
    getComplaintDefinitions().then((res) => {
      setTypes(res?.types);
      setStatus(res?.status);
      setLoading(false);
    });
  }, []);
  const BodyTemplate = (rowData: any) => {
    return (
      <div className="gap-3">
        {UPDATE_COMPLAINTS() ? (
          <i
            className="pi pi-bold pi-pencil"
            style={{
              fontSize: "1.2rem",
              color: "slateblue",
              padding: "7px",
              cursor: "pointer",
            }}
            onClick={() => ShowModal(rowData)}
          ></i>
        ) : (
          <></>
        )}
      </div>
    );
  };

  function handleChange(event: any) {
    setFile(event.target.files[0]);
    const files = Array.from(event.target.files);
    setSelectedComplaintImages(files.map((file:any) => URL.createObjectURL(file)));
  }
  const statusBodyTemplate = (rowData: any) => {
    return (
      <Tag
        value={rowData.status}
        style={
          rowData.status === "PENDING"
            ? { backgroundColor: "#ff8000" }
            : rowData.status === "IN_PROGRESS"
            ? { backgroundColor: "#0000ff" }
            : rowData.status === "UNRESOLVED"
            ? { backgroundColor: "#ff0000" }
            : { backgroundColor: "#294B29" }
        }
        rounded
      ></Tag>
    );
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="card p-5">
          <Dialog
            header={t("supportPage.EditComplaint") ?? "Edit Complaint"}
            visible={showEdit}
            className="w-72 md:w-96 lg:w-[450px] xl:w-[450px]"
            onHide={() => setShowEdit(false)}
            footer={
              <div className="mt-3">
                <Button
                  label={t("supportPage.Update") ?? "Update"}
                  severity="danger"
                  size="small"
                  type="submit"
                  onClick={() => complaintFormEdit.handleSubmit()}
                  className="mt-4"
                ></Button>
                <Button
                  label={t("supportPage.Cancel") ?? "Cancel"}
                  severity="danger"
                  outlined
                  size="small"
                  onClick={() => setShowEdit(false)}
                  className="mt-4"
                ></Button>
              </div>
            }
          >
            <>
              <div className="grid gap-0">
                <div className="md:col-6 lg:col-6">
                  <label className="mb-2" htmlFor="type">
                    {t("supportPage.Type") ?? "Type"}
                  </label>
                  <Dropdown
                    value={complaintFormEdit.values.type}
                    onChange={(e) =>
                      complaintFormEdit.setFieldValue("type", e.value)
                    }
                    options={types}
                    optionLabel="type"
                    optionValue="type"
                    placeholder={
                      t("supportPage.SelectaType") ?? "Select a type"
                    }
                    className="w-full"
                  />
                </div>
                <div className="md:col-6 lg:col-6">
                  <label className="mb-2" htmlFor="Lastname">
                    {t("supportPage.Description") ?? "Description"}
                  </label>
                  <InputText
                    placeholder={t("supportPage.Description") ?? "Description"}
                    value={complaintFormEdit.values.description}
                    onChange={complaintFormEdit.handleChange}
                    name="description"
                  />
                </div>
              </div>

              <div className="flex-none">
                <label className="" htmlFor="LastName">
                  {" "}
                  {t("supportPage.Attachments") ?? "Attachments"}{" "}
                </label>
                <FileUploader onChange={handleChange} name=""
                  path={complaintFormEdit.values.attachments && complaintFormEdit.values.attachments[0]}
                  selectImage={selectedComplaintImages}
                 />
              </div>
            </>
          </Dialog>

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header={t("supportPage.AllComplaint") ?? "All Complaint"}>
              <div className="new-data-table">
                <DataTable
                  value={complaints}
                  stripedRows
                  showGridlines
                  tableStyle={{ minWidth: "50rem" }}
                  responsiveLayout="scroll"
                  breakpoint="960px"
                  paginator
                  rows={10}
                >
                  <Column
                    field="type"
                    header={t("supportPage.ComplaintType") ?? "Complaint Type"}
                    style={{ width: "20%" }}
                    sortable
                  ></Column>
                  <Column
                    field="description"
                    header={t("supportPage.Description") ?? "Description"}
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field="attachments.[0]"
                    header={t("supportPage.Attachments") ?? "Attachments"}
                    body={(rowData: any) =>
                      rowData.attachments && rowData.attachments.length > 0 ? (
                        <Image
                          src={rowData.attachments[0]}
                          alt={t("supportPage.Attachments") ?? "Attachments"}
                          preview
                          width="150" height="200"
                        />
                      ) : (
                        <span>
                          {t("supportPage.NoAttachments") ?? "No attachments"}
                        </span>
                      )
                    }
                  />
                  <Column
                    field="status"
                    header={t("supportPage.Status") ?? "Status"}
                    body={statusBodyTemplate}
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field="created_at"
                    header={t("supportPage.Date") ?? "Date"}
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field=""
                    header={t("supportPage.Actions") ?? "Actions"}
                    body={BodyTemplate}
                  ></Column>
                </DataTable>
              </div>
            </TabPanel>
            <TabPanel header={t("supportPage.AddComplaint") ?? "Add Complaint"}>
              <div className=" mt-2">
                <div className="grid gap-4">
                  <div className="md:col-4 lg:col-4">
                    <label className="mb-2" htmlFor="type">
                      {t("supportPage.Type") ?? "Type"}
                    </label>
                    <Dropdown
                      value={complaintForm.values.type}
                      onChange={(e) =>
                        complaintForm.setFieldValue("type", e.value)
                      }
                      options={types}
                      optionLabel="type"
                      optionValue="type"
                      placeholder={
                        t("supportPage.SelectaType") ?? "Select a type"
                      }
                      className="w-full"
                    />
                  </div>
                  <div className="md:col-4 lg:col-4">
                    <label className="mb-2" htmlFor="description">
                      {t("supportPage.Description") ?? "Description"}
                    </label>
                    <InputText
                      placeholder={
                        t("supportPage.Description") ?? "Description"
                      }
                      value={complaintForm.values.description}
                      onChange={complaintForm.handleChange}
                      name="description"
                    />
                  </div>
                </div>
                <div className="grid mt-4">
                  <div className="md:col-6 lg:col-6 overflow-hidden">
                    <FileUploader onChange={handleChange} name=""
                     selectImage={selectedComplaintImages}
                    />
                  </div>
                </div>
                {CREATE_COMPLAINTS() ? (
                  <Button
                    className="mt-4"
                    label={t("supportPage.SendComplaint") ?? "Send Complaint"}
                    severity="danger"
                    outlined
                    type="submit"
                    size="small"
                    icon="pi pi-check"
                    iconPos={isRTL ? "right" : "left"}
                    onClick={() => complaintForm.handleSubmit()}
                  />
                ) : (
                  <></>
                )}
              </div>
            </TabPanel>
          </TabView>
        </div>
      )}
    </MainLayout>
  );
};

export default Support;
