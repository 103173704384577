import React, { useState } from "react";
import { IPagination } from "../../modules/Retailer.modules";
import { Paginator } from "primereact/paginator";
import { useTranslation } from "react-i18next";
interface Props {
  dataPagination: IPagination;
  onPageChange: (text: string) => void;
}
const PaginationTemplate: React.FC<Props> = ({
  dataPagination,
  onPageChange,
}: Props) => {
  const [first, setFirst] = useState(0);
  const { t } = useTranslation();

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
    FirstPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={dataPagination!.current_page > 1 ? "" : options.className}
          onClick={() => onPageChange("first")}
          disabled={dataPagination!.current_page > 1 ? false : true}
        >
          <span className="p-3"> {t("pagination.First") ?? "First"}</span>
        </button>
      );
    },
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={dataPagination!.current_page > 1 ? "" : options.className}
          onClick={() => onPageChange("prev")}
          disabled={dataPagination!.current_page > 1 ? false : true}
        >
          <span className="p-3">{t("pagination.Previous") ?? "Previous"}</span>
        </button>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          className="mx-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          ({dataPagination.current_page} / {dataPagination.total_pages})
        </span>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={
            dataPagination!.current_page < dataPagination!.total_pages
              ? options.className
              : ""
          }
          onClick={() => onPageChange("next")}
          disabled={
            dataPagination!.current_page < dataPagination!.total_pages
              ? false
              : true
          }
        >
          <span className="p-3">{t("pagination.Next") ?? "Next"}</span>
        </button>
      );
    },
    LastPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={
            dataPagination!.current_page < dataPagination!.total_pages
              ? options.className
              : ""
          }
          onClick={() => onPageChange("last")}
          disabled={
            dataPagination!.current_page < dataPagination!.total_pages
              ? false
              : true
          }
        >
          <span className="p-3">{t("pagination.Last") ?? "Last"}</span>
        </button>
      );
    },
  };
  return (
    <div className="card">
      <Paginator
        first={first}
        rows={dataPagination.per_page}
        totalRecords={dataPagination.total}
        template={paginatorTemplate}
      />
    </div>
  );
};

export default PaginationTemplate;
