import React, { ChangeEvent } from "react";
import Search from "../../components/SearchVeFilters/Search";
import "./Categories.css";
import { Category, FilterDTO } from "../../modules/Retailer.modules";
type Props = {
  handleSelectCategory: (category: any) => void;
  onHideFilter: () => void;
  handleSearch: (data: any) => void;
  handleFilter: (data: any) => void;
  handleChange: (
    name: string,
    val: string | number | boolean | Category[]
  ) => void;
  setVisibleFilter: React.Dispatch<React.SetStateAction<boolean>>;
  categories: Category[];
  initialMinPrice?: number;
  initialMaxPrice?: number;
  initialAvailableProduct?: number;
  initialPublishedOnStore?: number;
  initialCategories?: Category[];
  visibleFilter: boolean;
};

const CategoriesContainer: React.FC<Props> = ({
  handleSelectCategory,
  handleSearch,
  handleFilter,
  handleChange,
  onHideFilter,
  setVisibleFilter,
  categories,
  initialMinPrice,
  initialMaxPrice,
  initialAvailableProduct,
  initialPublishedOnStore,
  initialCategories,
  visibleFilter,
}) => {
  return (
    <>
      <Search
        handleSearch={handleSearch}
        categories={categories}
        handleFilter={handleFilter}
        handleChange={handleChange}
        initialMinPrice={initialMinPrice}
        initialMaxPrice={initialMaxPrice}
        initialAvailableProduct={initialAvailableProduct}
        initialPublishedOnStore={initialPublishedOnStore}
        initialCategories={initialCategories}
        visibleFilter={visibleFilter}
        onHideFilter={onHideFilter}
        setVisibleFilter={setVisibleFilter}
      />
      <div className="py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl  lg:py-4">
        <div className="grid gap-4 lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-4">
          {categories?.map((item, index) => (
            <div
              className="p-1 flex flex-col items-center cursor-pointer hover:rounded-2xl hover:!text-white hover:border-[#f50c44] hover:!bg-[#f50c44] hover:border"
              onClick={() => handleSelectCategory(item)}
              key={index}
            >
              <img
                className="object-cover w-20 h-20 mb-2 rounded-full shadow"
                src={item.logo}
                alt={item.title}
              />
              <div className="flex flex-col items-center">
                <p className="text-sm font-bold ">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CategoriesContainer;
