import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Category, selectStoreOption } from "../../modules/Retailer.modules";
import SearchBar from "../SearchBar";
import Filter from "./Filter";
import { Panel } from "primereact/panel";
type Props = {
  removeAll: () => void;
  storesOptions: selectStoreOption[];
  handleSearch: (searchInput: any) => void;
  handleFilter: (searchInput: any) => void;
  setVisibleFilter: React.Dispatch<React.SetStateAction<boolean>>;
  onHideFilter: () => void;
  pushAllExistedProductsToStore: (data: any) => void;
  handleChange: (
    name: string,
    val: string | number | boolean | Category[]
  ) => void;
  initialMinPrice?: number;
  initialMaxPrice?: number;
  initialAvailableProduct?: number;
  initialPublishedOnStore?: number;
  initialCategories?: Category[];
  visibleFilter: boolean;
};
function SearchImport({
  removeAll,
  storesOptions,
  handleSearch,
  handleFilter,
  handleChange,
  onHideFilter,
  initialMinPrice,
  initialMaxPrice,
  initialAvailableProduct,
  initialPublishedOnStore,
  initialCategories,
  pushAllExistedProductsToStore,
  visibleFilter,
  setVisibleFilter,
}: Props) {
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const myClass = `flex ${isRTL ? "mr-3" : "ml-3"} mb-3`;
  const [filterNumber, setFilterNumber] = useState<string>(
    t("main.Filter") ?? "Filter"
  );
  const ref = useRef<Panel>(null);
  const footerContent = (item: any) => (
    <div className="mt-3">
      <Button
        label={t("importListCard.Cancel") ?? "Cancel"}
        icon="pi pi-times"
        onClick={() => {
          setVisible(false);
        }}
        severity="danger"
        outlined
      />
      <Button
        label={t("importListCard.Push") ?? "Push"}
        icon="pi pi-check"
        severity="danger"
        onClick={() => {
          pushToStore(item);
        }}
        disabled={storesOptions?.length <= 0 ? true : false}
        autoFocus
      />
    </div>
  );
  const pushToStore = (item: any) => {
    setVisible(false);
    const datToPush = {
      connect_store_id: selectedStore,
    };
    pushAllExistedProductsToStore(datToPush);
  };
  const toggleVisibility = (e: any) => {
    setVisibleFilter((prev: any) => !prev);
    ref.current?.expand(e);
  };
  return (
    <>
      <div className="mx-4 flex justify-between gap-2  xl:flex-row lg:flex-row md:flex-row flex-col xs:flex-col">
        <div className="flex justify-start items-center flex-col md:flex-row lg:flex-row xl:flex-row form-search">
          <SearchBar handleSearch={handleSearch} />
          <div className={myClass}>
            <Button
              label={filterNumber}
              severity="danger"
              icon="pi pi-filter"
              iconPos={isRTL ? "right" : "left"}
              raised
              className="text-white w-full"
              onClick={toggleVisibility}
            />
          </div>
        </div>
        <div className="flex justify-between items-center mb-3 flex-row sm:flex-row md:flex-row lg:flex-row xl:flex-row ">
          <div className={`flex items-start ${isRTL ? "ml-3" : "mr-3"} `}>
            <Button
              label={t("importListCard.RemoveAll") ?? "Remove All"}
              severity="danger"
              outlined
              onClick={removeAll}
            />
          </div>
          <div className=" flex items-end">
            <Button
              label={t("importListPage.PushAll") ?? "Push All"}
              severity="danger"
              onClick={() => {
                setVisible(true);
              }}
            />
          </div>
        </div>
      </div>
      <Dialog
        header={
          t("importListCard.PleaseSelecttheStore") ?? "Please Select the Store"
        }
        visible={visible}
        className="w-72 md:w-96 lg:w-96 xl:w-96"
        onHide={() => setVisible(false)}
        footer={footerContent}
        key={selectedItemId}
      >
        {storesOptions && storesOptions.length > 0 ? (
          <div
            className="col-12"
            style={{ textAlign: isRTL ? "right" : "left" }}
          >
            <Dropdown
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.value)}
              options={storesOptions}
              optionLabel="name"
              placeholder={
                t("importListCard.AvailableStores") ?? "Available Stores"
              }
              className="w-full md:w-14rem"
            />
          </div>
        ) : (
          <p className="my-2">
            {t("importListCard.PleaseMakeStoreFirstFromIntegrationPage") ??
              " Please Make Store first from Integration Page"}{" "}
          </p>
        )}
      </Dialog>
      {visibleFilter && (
        <Panel
          ref={ref}
          header={t("main.SelectYourFilter") ?? "Select Your Filter"}
          className="hide-close"
          toggleable={true}
        >
          <Filter
            visible={visibleFilter}
            onHide={() => setVisibleFilter(false)}
            handleFilter={handleFilter}
            handleChange={handleChange}
            setFilterNum={setFilterNumber}
            initialMinPrice={initialMinPrice}
            initialMaxPrice={initialMaxPrice}
            initialAvailableProduct={initialAvailableProduct}
            initialPublishedOnStore={initialPublishedOnStore}
            initialCategories={initialCategories}
            visibleFilter={visibleFilter}
            onHideFilter={onHideFilter}
          />
        </Panel>
      )}
    </>
  );
}

export default SearchImport;
