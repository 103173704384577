import { Editor } from "primereact/editor";
import React, { useState } from "react";
import { ProductEditDTO } from "../modules/Retailer.modules";
type Props = {
  content: string;
  initialValues: ProductEditDTO;
  handleChange: (name: string, val: string) => void;
  itemtemp?: string;
};
function CardEditor({ content, initialValues, handleChange, itemtemp }: Props) {
  const [text, setText] = useState<string>(
    initialValues.description ?? itemtemp ?? content
  );

  return (
    <Editor
      className="m-2"
      value={text}
      onTextChange={(e: any) => {
        setText(e.textValue);
        handleChange("description", e.textValue);
        initialValues.description = e.textValue;
      }}
      style={{ height: "320px" }}
      id="description"
      name="description"
    />
  );
}

export default CardEditor;
