import React, { useLayoutEffect, useState } from "react";
import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import "../../styles/DataViewDemo.scss";
import { Tag } from "primereact/tag";
import { Carousel } from "primereact/carousel";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { Item } from "../../modules/Retailer.modules";

import TurkeyFlag from "../../icons/TurkeyFlag";
import { Link } from "react-router-dom";
import { IPagination } from "../../modules/Retailer.modules";

import { useTranslation } from "react-i18next";
import MainLayout from "../MainLayout";
import { trimText } from "../../helpers/helper";
import { InputNumber } from "primereact/inputnumber";

interface Props {
  products: Item[];
  selectedProducts: Item[];
  handleImport: (item: any) => void;
  handleRemove: (item: any) => void;
  dataPagination: IPagination;
  first: number;
  rows: number;
  onPageChange: (text: string) => void;
  setFirst: (data: number) => void;
}
const ProductsContainer: React.FC<Props> = ({
  products,
  selectedProducts,
  handleImport,
  handleRemove,
  dataPagination,
  first,
  rows,
  onPageChange,
  setFirst,
}) => {
  const { t, i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(i18n.language === "ar");

  const [pageNo, setPageNo] = useState<number>(dataPagination?.current_page);
  const renderListItem = (data: any) => {
    return (
      <div className="col-12">
        <div className="product-list-item">
          <img src={`${data?.image[0]}`} alt={data.title} />
          <div className="product-list-detail">
            <div className="product-name">{data.title}</div>
            <div className="product-description">{data.description}</div>
            <Rating value={data.rating} readOnly cancel={false}></Rating>
            <i className="pi pi-tag product-category-icon"></i>
            <span className="product-category">{data.category}</span>
          </div>
          <div className="product-list-action">
            <span className="product-price">${data.price}</span>
            <Button
              icon="pi pi-shopping-cart"
              label="Add to Cart"
              disabled={data.inventoryStatus === "OUTOFSTOCK"}
            ></Button>
            <span
              className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}
            >
              {data.inventoryStatus}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const productTemplate = (image: any) => {
    return (
      <div className="relative overflow-hidden rounded-t ">
        <img className="h-64 w-full" src={`${image}`} alt="" />
      </div>
    );
  };

  const renderGridItem = (data: any) => {
    const { trimmedText, isTrimmed } = trimText(data.title);
    return (
      <div
        className={`col bg-white rounded border card h-full relative ${
          selectedProducts?.some(
            (selectedProductId) => selectedProductId.id === data.id
          )
            ? "border-red-400"
            : "border-gray-400"
        } m-2 `}
      >
        <div className="h-full">
          <div className="relative w-full mb-2">
            <Link
              to={{ pathname: `/products/${data.id}` }}
              className="cursor-pointer z-10"
            >
              <div className="product-grid-item-content">
                {data.images && data.images?.length > 1 ? (
                  <Carousel
                    value={data.images}
                    numScroll={1}
                    numVisible={1}
                    itemTemplate={productTemplate}
                  />
                ) : (
                  <div className="relative overflow-hidden rounded-t">
                    {
                      <img
                        className="h-64 w-full object-cover object-center"
                        src={data.images && data.images[0]}
                        alt={data.title}
                      />
                    }
                  </div>
                )}
              </div>
              <span className="absolute top-0 left-3 px-2 py-1 mr-2 rounded shadow-sm text-xs bg-white border-0 border-gray-600">
                {data.published_in_stores && (
                  <AvatarGroup>
                    {data.published_in_stores?.map(
                      (store: any, index: number) => (
                        <Avatar key={index} image={store.logo} shape="circle" />
                      )
                    )}
                  </AvatarGroup>
                )}
              </span>
              <span
                className="absolute top-0 right-0 px-2 py-1 mt-2 mr-2 rounded  text-xs  border-0 border-gray-600"
                style={{
                  display: selectedProducts?.some(
                    (selectedProductId) => selectedProductId.id === data.id
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Tag
                  className="mr-2"
                  value={t("productsPage.Imported") ?? "Imported"}
                  icon="pi pi-check"
                  severity="danger"
                  rounded
                ></Tag>
              </span>
            </Link>
          </div>
          <div className="flex flex-col col-span-5 mb-6">
            <div className="flex items-center justify-between mb-4 mx-4">
              <div className="group relative  flex justify-center">
                <h2 className="text-lg font-semibold dark:text-gray-300">
                  {trimmedText}
                </h2>
                {isTrimmed ? (
                  <span className="absolute bottom-5 scale-0 transition-all rounded  border border-gray bg-white p-2 text-xs text-black group-hover:scale-100">
                    ✨{data.title}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="flex items-center justify-between mb-2 mx-4">
              <p className="text-md "> {data.category.title}</p>
              <div className="flex flex-end">
                <span className="text-lg font-medium text-rose-500 dark:text-rose-200">
                  {data?.quantity && data?.quantity > 0 ? (
                    <Tag
                      severity="success"
                      icon="pi pi-check"
                      value={t("productsPage.Available") ?? "Available"}
                    ></Tag>
                  ) : (
                    <Tag
                      severity="warning"
                      value={t("productsPage.OutOfStock") ?? "Out of stock"}
                    ></Tag>
                  )}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between my-2 mx-4">
              <div>
                <h2 className="text-xs font-medium dark:text-gray-300">
                  {t("productsPage.ListingCost") ?? "Listing cost"}
                </h2>
                <span className="inline-block px-2 py-1 font-extrabold text-sm text-[#f50c44] dark:text-gray-400">
                  {data?.listing_price}
                </span>
              </div>
              <div></div>
            </div>
            <div className="flex flex-row items-center justify-between my-1 mx-4">
              <h2 className="text-xs font-medium dark:text-gray-300 mb-2">
                {t("productsPage.Shipping") ?? "Shipping"}
              </h2>
              <div className="flex flex-row">
                <h4 className={`${isRTL ? "ml-1" : "mr-1"} text-xs `}>5 $</h4>
                <TurkeyFlag />
                <span className={`${isRTL ? "ml-r" : "ml-1"} text-xs `}>
                  {" "}
                  {t("productsPage.2To3Days") ?? "2-3 days"}{" "}
                </span>
              </div>
            </div>

            <div className="flex items-start justify-between mb-2 mx-4"></div>
          </div>
          <div className="text-white my-2 mx-2 absolute bottom-0 left-[5%] right-[5%]">
            <Button
              className={`h-15 w-full text-sm hover:text-white ${
                selectedProducts?.some(
                  (selectedProductId) => selectedProductId.id === data.id
                )
                  ? " hover:bg-[#f50c44]"
                  : "hover:bg-[#0E1530] change-color-btn-outlined"
              }`}
              severity={`${
                selectedProducts?.some(
                  (selectedProductId) => selectedProductId.id === data.id
                )
                  ? "danger"
                  : "info"
              }`}
              outlined
              icon={`${
                selectedProducts?.some(
                  (selectedProductId) => selectedProductId.id === data.id
                )
                  ? "pi pi-times"
                  : "pi pi-plus"
              }`}
              label={`${
                selectedProducts?.some(
                  (selectedProductId) => selectedProductId.id === data.id
                )
                  ? t("productsPage.RemoveFromImportList") ??
                    "Remove from Product List"
                  : t("productsPage.AddToImportList") ?? "Add to import list"
              }`}
              onClick={
                selectedProducts?.some(
                  (selectedProductId) => selectedProductId.id === data.id
                )
                  ? () => handleRemove(data)
                  : () => handleImport(data)
              }
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  const onPageInputChange = (event: any) => {
    const current = event.value;
    setPageNo(current);
  };
  const paginatorTemplate = {
    layout:
      " FirstPageLink PrevPageLink CurrentPageReport  NextPageLink LastPageLink JumpToPageInput",
    FirstPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={dataPagination!.current_page > 1 ? "" : options.className}
          onClick={() => onPageChange("first")}
          disabled={dataPagination!.current_page > 1 ? false : true}
        >
          <span className="p-3">{t("pagination.First") ?? "First"}</span>
        </button>
      );
    },
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={dataPagination!.current_page > 1 ? "" : options.className}
          onClick={() => onPageChange("prev")}
          disabled={dataPagination!.current_page > 1 ? false : true}
        >
          <span className="p-3"> {t("pagination.Previous") ?? "Previous"}</span>
        </button>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          className="mx-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          ({dataPagination.current_page} / {dataPagination.total_pages})
        </span>
      );
    },
    JumpToPageInput: (options: any) => {
      return (
        <div>
          <span className="mx-3" style={{ color: "black", userSelect: "none" }}>
            {t("productsPage.GoToPage") ?? "Go to page :"}{" "}
            <InputNumber
              className="ml-1"
              min={1}
              max={dataPagination?.total_pages}
              value={dataPagination?.current_page}
              onValueChange={onPageInputChange}
            />
          </span>
          <Button
            id="search-button"
            severity="danger"
            onClick={() => onPageChange(pageNo.toString())}
            label={t("productsPage.Go") ?? "Go"}
          />
        </div>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={
            dataPagination!.current_page < dataPagination!.total_pages
              ? options.className
              : ""
          }
          onClick={() => onPageChange("next")}
          disabled={
            dataPagination!.current_page < dataPagination!.total_pages
              ? false
              : true
          }
        >
          <span className="p-3">{t("pagination.Next") ?? "Next"}</span>
        </button>
      );
    },
    LastPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={
            dataPagination!.current_page < dataPagination!.total_pages
              ? options.className
              : ""
          }
          onClick={() => onPageChange("last")}
          disabled={
            dataPagination!.current_page < dataPagination!.total_pages
              ? false
              : true
          }
        >
          <span className="p-3">{t("pagination.Last") ?? "Last"}</span>
        </button>
      );
    },
  };

  return (
    <MainLayout>
      {products?.length > 0 && (
        <div className="">
          <div className="card mt-6">
            <DataView
              value={products}
              layout={"grid"}
              paginator
              itemTemplate={(product, layout) => {
                if (!product) {
                  return null;
                }
                if (layout === "list") return renderListItem(product);
                else if (layout === "grid") return renderGridItem(product);
              }}
              rows={dataPagination.per_page}
              totalRecords={dataPagination.total}
              first={first}
              paginatorTemplate={paginatorTemplate}
            />
          </div>
        </div>
      )}
    </MainLayout>
  );
};
export default ProductsContainer;
