import React from "react";

const ProductTemplate = (image: any) => {
  return (
    <div className="relative overflow-hidden">
      <img
        className="h-64 w-full object-cover object-center rounded-t"
        src={`${image}`}
        alt=""
      />
    </div>
  );
};

export default ProductTemplate;
