import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SharedDataContext from "../../services/SharedData";

import "../../styles/App.scss";

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const { updateChargeState } = useContext(SharedDataContext);

  useEffect(() => {
    const closeWindow = () => {
      updateChargeState(true);
      window.close();
    };

    const timer = setTimeout(closeWindow, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={"payment"}>
      <i
        className="pi pi-check-circle"
        style={{ fontSize: "120px", color: "green" }}
      />
      <h1 style={{ marginTop: "20px", fontSize: "30px" }}>
        {t("payment.paymentSuccess")}
      </h1>
    </div>
  );
};

export default PaymentSuccess;
