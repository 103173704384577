import React from "react";
import { useLocation } from "react-router";
import { PropertyEmptyData } from "../modules/Retailer.modules";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import itemImage from "../styles/images/noimorteditem.jpeg";

//noimorteditem
type Props = {
  body?: PropertyEmptyData;
};
function TextResult({ body }: Props) {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentPage = location.pathname.substring(1);

  const getPageEmptyMessage = (page: string, body: any) => {
    if (page.includes("stores/")) {
      return (
        t(`emptyMessage.stores/id`) || "No empty message found for this page."
      );
    }

    return t(`emptyMessage.${page}`) || "No empty message found for this page.";
  };
  const errorMsg = getPageEmptyMessage(currentPage, body);

  return (
    <MainLayout>
      <div className="h-full ">
        <div className="py-10 text-center flex flex-column gap-5 flex-shrink-0 justify-content-center align-items-center mx-auto">
          <img
            src={body?.image ?? itemImage}
            alt="result"
            width={300}
            height={300}
          />

          <h3 className="mb-2 text-sm font-normal text-center">{errorMsg}</h3>
        </div>
      </div>
    </MainLayout>
  );
}

export default TextResult;
