import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CartItem } from "../../modules/Retailer.modules";
type Props = {
  item: CartItem;
  increaseCartStore: (data: any) => void;
  decreaseCartStore: (data: any) => void;
  deleteFromStoreCart: (data: any) => void;
};
const CartCard = ({
  item,
  increaseCartStore,
  decreaseCartStore,
  deleteFromStoreCart,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [quantity, setQuantity] = useState(item.quantity);

  const updateQuantity = (item: CartItem) => {
    let data = {
      sub_product_id: item.sub_product_id,
    };
    let dataToSend = {
      ...data,
      quantity: quantity,
    };
    if (quantity === 0) {
      deleteFromStoreCart(data);
    } else if (item.quantity > quantity) {
      decreaseCartStore(dataToSend);
    } else {
      increaseCartStore(dataToSend);
    }
  };
  return (
    <Card className="p-0">
      <div className="grid">
        <div className="col-3">
          <img src={item?.image} />
        </div>
        <div className="col-9">
          <div className="grid">
            <div className="col-5">
              <p>{item?.product_title}</p>
              <span>{item?.price + "$"}</span>
            </div>
            <div className="col-4 quantity-cart">
              <InputNumber
                placeholder={t("cartPage.Quantity") ?? "Quantity"}
                id={item.id}
                showButtons
                mode="decimal"
                min={0}
                buttonLayout="horizontal"
                value={quantity}
                decrementButtonClassName="p-button-danger"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                onChange={(e: any) => {                  
                  setQuantity(e.value);                 
                }}
                name="quantity"
              />
              <Button
                icon="pi pi-check"
                label={t("cartPage.Send") ?? "Send"}
                onClick={() => updateQuantity(item)}
                rounded
                outlined
                className="ml-0 mt-3"
                severity="danger"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CartCard;
