import { Bounce, toast } from "react-toastify";
import { authLogout } from ".";
import { emptyData, mainRequestNeededEmptyData } from "./reaquestdata";

export const handleResponse = (
  response: any,
  page?: string,
  requestType?: string
) => {
  if (response === undefined) {
    handleError(response.response.data.errors);
  } else if (response.status === 200) {
    const result = response.data.result.data;

    if (response.data.result.pagination) {
      result["pagination"] = response.data.result.pagination;
    }

    if (requestType === "Post" || requestType === "Delete") {
      handleSuccess(response?.data?.message);
    }

    if (Array.isArray(result) && result.length > 0) {
      return result;
    } else if (typeof result === "object" && !Array.isArray(result) && result) {
      return result;
    } else {
      if (page && mainRequestNeededEmptyData.includes(page)) {
        return emptyData[page!.toLowerCase()];
      } else if (typeof result === "string") {
        return { data: result };
      } else {
        return [];
      }
    }
  } else {
    handleError(response.response.data.errors);
  }
};

export const handleError = async (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.clear();
    await authLogout();
    window.location.href = "/login";
  } else {
    const messageError = error?.response?.data?.errors[0]
      ? error?.response?.data?.errors[0]
      : "an error has happened";

    toast(messageError, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "dark",
      transition: Bounce,
      style: { backgroundColor: "#0E1530", color: "#fff" },
    });
  }
};

export const handleSuccess = (response?: any) => {
  const message = response
    ? response
    : "The process was completed successfully";

  toast(message, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",

    transition: Bounce,
    style: { backgroundColor: "#caf1d8", color: "#1da750" },
  });
};
