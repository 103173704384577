import { useState, useEffect, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { createStore, listSupportedIntegrations, Subscription } from "../services";
import MainLayout from "../containers/MainLayout";
import LoadingComponent from "../components/Loading";
import SharedDataContext from "../services/SharedData";
import { CREATE_INTEGRATION_SUPPORTS } from "../permissions/permissions";

interface WixIntegrationKeys {
  wix_app_id?: string;
  wix_app_secret?: string;
}

const IntegrationCard = ({ selectedIntegration, handleSubmit, handleInputChange, t, activeIndex, handleAccordionChange }: any) => {
  return (
    <div className="card flex flex-col justify-content-center">
      {selectedIntegration.integration_steps?.length > 0 ? (
        <form onSubmit={handleSubmit} className="flex flex-column gap-2">
          <Accordion activeIndex={activeIndex} onTabChange={handleAccordionChange}>
            {selectedIntegration.integration_steps.map((step: any, index: number) => (
              <AccordionTab key={index} header={step.title}>
                <div dangerouslySetInnerHTML={{ __html: step.description }} />
                {step.copy_element && (
                  <Button
                    icon="pi pi-copy"
                    label={step.copy_element}
                    onClick={() => navigator.clipboard.writeText(step.copy_element)}
                    rounded
                    outlined
                    className="ml-0 mt-3"
                    severity="danger"
                    size="small"
                  />
                )}
                {step.requirements?.length > 0 && step.requirements.map((requirement: any, reqIndex: number) => (
                  <div key={reqIndex} className="flex flex-column gap-2">
                    <label htmlFor={requirement.translate}>{requirement.translate}</label>
                    <InputText
                      id={requirement.input}
                      name={requirement.input}
                      aria-describedby="url-help"
                      onChange={(event) => handleInputChange(requirement.input, event)}
                    />
                    <small id="url-help">{requirement.help}</small>
                  </div>
                ))}
              </AccordionTab>
            ))}
          </Accordion>
        </form>
      ) : (
        <p className="m-2">{t("integrationsPage.ThereIsNoRequirements") ?? "There are no requirements"}</p>
      )}
    </div>
  );
};

const FooterContent = ({ item, userPlan, setVisible, connectToStore, t, currentUser }: any) => (
  <div className="mt-3 add-margin-right">
    {userPlan ? (
      <div className="mt-2">
        <Button
          label={t("main.Cancel") ?? "Cancel"}
          icon="pi pi-times"
          onClick={() => setVisible(false)}
          severity="danger"
          outlined
        />
        <Button
          label={t("integrationsPage.ConnectToStore") ?? "Connect Store"}
          icon="pi pi-check"
          severity="danger"
          type="submit"
          onClick={() => connectToStore(item.type)}
          autoFocus
        />
      </div>
    ) : (
      <div className="mt-3 add-margin-right">
        <Button
          label={t("main.Cancel") ?? "Cancel"}
          icon="pi pi-times"
          onClick={() => setVisible(false)}
          severity="danger"
          outlined
        />
        <Button
          label={
            currentUser.wallet > 0
              ? t("integrationsPage.ChooseAplan") ?? "choose a plan"
              : t("integrationsPage.ChargeWallet") ?? "charge wallet"
          }
          icon="pi pi-check"
          severity="danger"
          onClick={
            currentUser.wallet > 0
              ? () => (window.location.href = "/plans")
              : () => (window.location.href = "/wallet")
          }
          autoFocus
        />
      </div>
    )}
  </div>
);

const Integrations = () => {
  const [integrations, setIntegrations] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<any>();
  const [userPlan, setUserPlan] = useState(null);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const [loading, setLoading] = useState(false);
  const { addStore } = useContext(SharedDataContext);
  const [activeIndex, setActiveIndex] = useState(null);
  const formDataRef = useRef({});

  const [currentUser,] = useState(() => {
    const userString = localStorage.getItem("user");
    return userString ? JSON.parse(userString) : "";
  });

  useEffect(() => {
    setLoading(true);

    listSupportedIntegrations()
      .then((res) => {
        setIntegrations(res);
        setLoading(false);
      })
      .catch((err) => console.error(err));

    Subscription().then((res) => {
      if (res) {
        setUserPlan(res.plan);
      }
    });
  }, []);

  const handleInputChange = (input: any, e: any) => {
    formDataRef.current = { ...formDataRef.current, [input]: e.target.value };
  };

  const handleAccordionChange = (e: any) => {
    const index = e.index;
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
  };

  const connectToStore = (data: any) => {
    const dataToPush = {
      integration_supports_type: data,
      ...formDataRef.current,
    };

    createStore(dataToPush).then((response) => {
      if (response) {
        addStore(response);
        const { wix_app_id, wix_app_secret }: WixIntegrationKeys  = formDataRef.current;
        if (wix_app_id?.trim() && wix_app_secret?.trim()) {
          installApp(wix_app_id);
        }
      }
      setVisible(false);
    });
  };

  const openModal = () => {
    setVisible(true);
  };

  const installApp = (appId: string) => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth - 600) / 2;
    const top = (screenHeight - 400) / 2;

    const installerWindow = window.open(
      `https://www.wix.com/installer/install?appId=${appId}&redirectUrl=${process.env.REACT_APP_API_BASE_URL}webhooks/wix/install`,
      "Wix Installer",
      `width=600,height=600,left=${left},top=${top}`
    );

    if (installerWindow) {
      window.addEventListener("message", (event) => {

        if (
          event.data.installStatus === "waitForTokenReceived" &&
          event.data.appId === appId
        ) {
          setTimeout(() => {
            toast("App installation was completed successfully!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              transition: Bounce,
              style: { backgroundColor: "#caf1d8", color: "#1da750" },
            });
            installerWindow.close();
          }, 1000);
        }
      });
    }
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="py-16">
          <div className="text-center mb-4">
            <h2 className="text-md md:text-3xl lg:text-3xl text-gray-950 dark:text-white font-semibold mb-2">
              {t("integrationsPage.OurAvailableIntegrationstoMakeaStore") ??
                "Our available integrations allow you to connect your existing store to our platform"}
            </h2>
          </div>
          <div className="mx-auto px-3 max-w-6xl text-gray-500 mt-2">
            <div className="mt-12 col-12 flex flex-wrap ">
              {integrations?.map((integration: any, index: number) => (
                <div key={index} className="col-12 lg:col-4 md:col-6 p-1 md:p-4 lg:p-4">
                  <div 
                   style={{maxHeight:'400px' , minHeight:'400px'}}
                  className="relative group overflow-hidden p-6 rounded-xl bg-white border border-gray-200 dark:border-gray-800 dark:bg-gray-900">
                    {index % 3 === 2 && index !== 0 ? (
                      <div
                        aria-hidden="true"
                        className="inset-0 absolute aspect-video border rounded-full -translate-y-1/2 group-hover:-translate-y-1/4 duration-300 bg-gradient-to-b from-gray-500 to-white dark:from-white dark:to-white blur-2xl opacity-25 dark:opacity-5 dark:group-hover:opacity-10"
                      ></div>
                    ) : index % 3 === 0 ? (
                      <div
                        aria-hidden="true"
                        className="inset-0 absolute aspect-video border rounded-full -translate-y-1/2 group-hover:-translate-y-1/4 duration-300 bg-gradient-to-b from-green-500 to-white dark:from-white dark:to-white blur-2xl opacity-25 dark:opacity-5 dark:group-hover:opacity-10"
                      ></div>
                    ) : (
                      <div
                        aria-hidden="true"
                        className="inset-0 absolute aspect-video border rounded-full -translate-y-1/2 group-hover:-translate-y-1/4 duration-300 bg-gradient-to-b from-red-500 to-white dark:from-white dark:to-white blur-2xl opacity-25 dark:opacity-5 dark:group-hover:opacity-10"
                      ></div>
                    )}

                    <div className="relative">
                      <div className="">
                        
                          <img src={integration?.logo} width="100%" height="100%"
                           style={{maxHeight:'100px' , minHeight:'150px'}}

                          />
                      </div>

                      <div className="mt-6 pb-3 rounded-b-[--card-border-radius]">
                        <p className="text-gray-700 dark:text-gray-300">
                          {integration?.description}
                        </p>
                      </div>
                      <div className="flex gap-3 -mb-4 py-2 border-t border-gray-200 dark:border-gray-800">
                        {CREATE_INTEGRATION_SUPPORTS() ? (
                          <Button
                            severity="danger"
                            outlined
                            onClick={() => {
                              setSelectedIntegration(integration);
                              openModal();
                            }}
                            className="button group rounded-xl text-gray-700 flex gap-1.5 items-center text-sm h-12 px-3 justify-center"
                          >
                            <span>{t("integrationsPage.Connect") ?? "Connect"}</span>
                          </Button>
                        ) : null}
                        <Link to={integration?.base_url} className="cursor-pointer z-10 mt-2">
                          <span className="mr-1">{t("integrationsPage.Visit") ?? "Visit"}</span>
                          <i className={`pi ${isRTL ? "pi-arrow-left mr-2" : "pi-arrow-right ml-2"}`}></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <Dialog
                header={t("integrationsPage.PleaseFillTheNeededFieldsToConnectYourStore") ?? "Please fill the needed fields to connect your store"}
                visible={visible}
                style={{ width: "50vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                onHide={() => setVisible(false)}
                footer={<FooterContent item={selectedIntegration} userPlan={userPlan} setVisible={setVisible} connectToStore={connectToStore} t={t} currentUser={currentUser} />}
                key={selectedIntegration?.id}
              >
                {selectedIntegration && (
                  <IntegrationCard
                    selectedIntegration={selectedIntegration}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                    t={t}
                    activeIndex={activeIndex}
                    handleAccordionChange={handleAccordionChange}
                  />
                )}
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default Integrations;
