import { useContext, useEffect, useState } from "react";
import {
  PlansList,
  Subscription,
  newSubscription,
  upgradeSubscription,
  WalletInfo,
} from "../services";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import TextResult from "../components/TextResult";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import LoadingComponent from "../components/Loading";
import { Dialog } from "primereact/dialog";
import i18n from "../i18n";
import SharedDataContext from "../services/SharedData";

const Plans = () => {
  const [plans, setPlans] = useState<any>();
  const [showChoose, setShowChoose] = useState<any>(null);
  const [yourPlan, setYourPlan] = useState<any>();
  const [propEmpty, setPropEmpty] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const isRTL = i18n.language === "ar";
  let userInfo = localStorage.getItem("user");
  let userWallet = JSON.parse(userInfo!);
  const { updateBalance } = useContext(SharedDataContext);
  const getSubscription = () => {
    PlansList().then((res) => {
      if (res !== undefined) {
        if (res && "image" in res && "message" in res) {
          setPropEmpty(res);
        } else {
          setPlans(res);
        }
        setLoading(false);
      }
    });

    Subscription().then((res) => {
      setYourPlan(res);
    });
  };

  useEffect(() => {
    setLoading(true);
    getSubscription();
  }, []);

  const newSub = (plan: any, useWallet = false) => {
    setLoading(true);
    const data = {
      plan: plan.id,
      use_wallet: useWallet,
      redirect_success: `${window.location.origin}/${process.env.REACT_APP_PAYMENT_REDIRECT_SUCCESS}`,
      redirect_failure: `${window.location.origin}/${process.env.REACT_APP_PAYMENT_REDIRECT_FAILURE}`,
    };

    handleSubscription(data, useWallet, true);
  };

  const upgradeSub = (plan: any, useWallet = false) => {
    setLoading(true);
    const data = {
      plan: plan?.id,
      use_wallet: useWallet,
      redirect_success: `${window.location.origin}/${process.env.REACT_APP_PAYMENT_REDIRECT_SUCCESS}`,
      redirect_failure: `${window.location.origin}/${process.env.REACT_APP_PAYMENT_REDIRECT_FAILURE}`,
    };

    handleSubscription(data, useWallet, false);
  };

  const handleSubscription = async (
    data: any,
    useWallet: boolean,
    useNew: boolean
  ) => {
    try {
      const res = useNew
        ? await newSubscription(data)
        : await upgradeSubscription(data);

      if (!useWallet && res.url) {
        window.open(res.url, "_blank");
      } else if (res) {
        Subscription().then((res) => {
          setYourPlan(res);
          WalletInfo().then((res) => {
            if (res !== undefined) {
              userWallet.wallet = res?.balance;
              updateBalance(res?.balance);
              localStorage.setItem("user", JSON.stringify(userWallet));
            }
          });
        });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setShowChoose(null);
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmpty && <TextResult body={propEmpty} />}
          {!propEmpty && (
            <>
              <div className="justify-content-center ">
                <h2 className="pt-4 text-xl	font-bold	text-center text-[#f50c44]">
                  {" "}
                  - {t("plansPage.DOSHTUPLANS") ?? "DOSHTU PLANS"} -
                </h2>
              </div>

              <div className=" justify-content-center mt-3">
                <h2 className="text-4xl	text-center	">
                  {t("plansPage.Flexibleplansforeveryone") ??
                    "Flexible plans for everyone"}
                </h2>
              </div>

              <div className="mb-5">
                <div className="grid mt-3 ">
                  {plans?.map((plan: any, index: number) => (
                    <div
                      className="col-12 md:col-6 lg:col-4 xl:col-3 p-3 mt-3"
                      key={plan.id}
                    >
                      <div className="h-full flex flex-col bg-white rounded-lg transition-all duration-500 transform  lg:hover:-translate-y-6
                      md:hover:-translate-y-3 sm:hover:-translate-y-2
                      hover:scale-105 shadow-xl hover:shadow-2xl cursor-pointer">
                        <div
                          style={
                            yourPlan?.plan?.id === plan?.id
                              ? {
                                  padding: "3px",
                                  borderRadius: "14px 14px 4px 4px",
                                }
                              : {}
                          }
                          className={`flex flex-col h-full ${
                            yourPlan?.plan?.id === plan?.id
                              ? "subscripted-sty"
                              : ""
                          }`}
                        >
                          {yourPlan?.plan?.id === plan?.id ? (
                            <span
                              className="flex gap-2"
                              style={{ justifyContent: "center" }}
                            >
                              <h4 className="text-white	font-bold">
                                {" "}
                                {t("plansPage.YourSubscription") ??
                                  "Your Subscription"}
                              </h4>
                              <i
                                className="pi pi-check mt-1"
                                style={{ fontSize: "1rem", color: "white" }}
                              ></i>
                            </span>
                          ) : (
                            <span></span>
                          )}
                        <Card
  key={plan.id}
  title={
    <div
      style={{
        fontSize: "18px",
        textAlign: "center",
      }}
    >
      {plan?.title}
    </div>
  }
  className=" h-full custom-box-shadow  break-words"
  style={{ minWidth: '200px', minHeight: '100px' }}
>
  <h4 className="mt-2 text-center text-xl font-semibold text-gray-800 dark:text-gray-100">
    {plan?.price + "$"}
    <span className="text-base font-normal text-gray-600 dark:text-gray-400">
      / {plan?.interval}
    </span>
  </h4>
  <div
    style={{ minWidth: '200px', minHeight: '100px' }}
  >
    <p className="mt-4 text-gray-500 dark:text-gray-300 break-words">
      {plan?.description}
    </p>
  </div>
  <p
    className="my-3 text-center break-words"
    style={{
      color: "rgb(8, 31, 64)",
      opacity: "0.6",
    }}
  >
    {t("plansPage.Free14-daytrial") ?? "Free {{d.trial_days}}-day trial"}
  </p>

  <Button
    label={
      yourPlan?.length <= 0
        ? t("plansPage.SubscribeNow") ?? "Subscribe Now"
        : yourPlan?.plan?.id === plan?.id
          ? t("plansPage.subscribed") ?? "subscribed"
          : plan?.price > yourPlan?.plan?.price
            ? t("plansPage.UpgradeNow") ?? "Upgrade Now"
            : t("plansPage.DowngradeNow") ?? "Downgrade Now"
    }
    disabled={
      yourPlan?.plan?.id !== plan?.id ? false : true
    }
    outlined
    size="small"
    onClick={() => setShowChoose(plan)}
    severity="danger"
    className="mb-3 mx-0 mt-auto w-full" 
  ></Button>
  <p className="text-start break-words">
    {t("plansPage.Whatyouget") ?? "What you get:"}
  </p>
  {Object.entries(plan?.type).map(
    ([typeKey, typeValue]) => (
      <div key={typeKey}>
        {Array.isArray(typeValue) &&
          typeValue.map((value, index) => (
            <p
              key={index}
              className="text-xs text-gray-700 dark:text-gray-300 break-words"
            >
              <span>
                <i
                  className="pi pi-check p-1"
                  style={{
                    fontSize: "1.2rem",
                    color: "#f50c44",
                  }}
                ></i>
              </span>
              {value}
            </p>
          ))}
      </div>
    )
  )}
</Card>



                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className=" ">
                <Dialog
                  header={
                    t("plansPage.PaymentMethodToCompleteTheSubscription") ??
                    "Payment method to complete the subscription"
                  }
                  visible={showChoose != null}
                  className="	md:w-30rem lg:w-30rem"
                  onHide={() => setShowChoose(null)}
                  footer={
                    <div className="flex justify-center add-margin-right">
                      <Button
                        label={t("plansPage.Wallet") ?? "Wallet"}
                        icon="pi pi-wallet"
                        severity="info"
                        size="small"
                        onClick={() =>
                          yourPlan?.length <= 0
                            ? newSub(showChoose, true)
                            : upgradeSub(showChoose, true)
                        }
                        className="mt-4 mr-4 change-color-btn"
                      />
                      <Button
                        label={t("plansPage.BankTransfer") ?? "Bank Transfer"}
                        icon="pi pi-credit-card"
                        severity="danger"
                        outlined
                        size="small"
                        onClick={() =>
                          yourPlan?.length <= 0
                            ? newSub(showChoose)
                            : upgradeSub(showChoose)
                        }
                        className="mt-4 "
                      />
                    </div>
                  }
                ></Dialog>
              </div>
            </>
          )}
        </>
      )}
    </MainLayout>
  );
};

export default Plans;
