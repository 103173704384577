import { useFormik } from "formik";
import * as Yup from "yup";
import { RegisterDTO } from "../modules/Retailer.modules";
import { authRegister } from "../services";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import SliderAuth from "../components/templates/SliderAuth";
import { Link } from "react-router-dom";
import "../styles/login.scss";
import { Password } from "primereact/password";
import { useState } from "react";
import VerifyDialog from "../components/VerifyDialog";

const Register = () => {
  const { t, i18n } = useTranslation();
  const [inputEmail, setInputEmail] = useState<string>("");
  const [visibleVerify, setVisibleVerify] = useState<boolean>(false);

  const validationSchema = Yup.object({
    first_name: Yup.string().required(
      t("register.FirstNameIsRequired") ?? "First Name is required"
    ),
    last_name: Yup.string().required(
      t("register.LastNameIsRequired") ?? "Last Name is required"
    ),
    email: Yup.string()
      .email(t("register.InvalidEmailAddress") ?? "Invalid email address")
      .required(t("register.EmailIsRequired") ?? "Email is required"),
    password: Yup.string()
      .required(t("register.PasswordIsRequired") ?? "Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,
        t("register.PasswordMustHaveAtLeast8CharactersIncluding") ??
          "Password must have at least 8 characters, including one uppercase letter and at least one symbol"
      ),
    password_confirmation: Yup.string()
      .min(
        6,
        t("register.PasswordMustBeAtLeast8Characters") ??
          "Password must be at least 8 characters"
      )
      .required(
        t("register.ConfirmationPasswordIsRequired") ??
          "Confirmation Password is required"
      )
      .oneOf(
        [Yup.ref("password")],
        t("register.PasswordsMustMatch") ?? "Passwords must match"
      ),
  });

  const register = useFormik<RegisterDTO>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },

    validateOnBlur: true,
    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      try {
        const result = await authRegister(register.values);
        if (result) {
          setInputEmail(values.email);
          setVisibleVerify(true);
        }
      } catch (err) {
      }
    },
  });

  return (
    <div className="login-form-container flex flex-wrap sm:flex-wrap xs:flex-wrap lg:flex-nowrap xl:flex-nowrap md:flex-wrap">
      <div className="login-container">
        <h1 className="login-tx-1">{t("register.Register") ?? "Register"}</h1>
        <div>
          <form className="" onSubmit={register.handleSubmit}>
            <div className="login-form">
              <div>
                {register.errors.first_name && register.touched.first_name && (
                  <small className="p-error">
                    {register.errors.first_name}
                  </small>
                )}
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                  </span>
                  <InputText
                    placeholder={t("register.Name") ?? "First Name"}
                    value={register.values.first_name}
                    onChange={register.handleChange}
                    name="first_name"
                    className={classNames({
                      "p-error":
                        register.touched.first_name &&
                        register.errors.first_name,
                    })}
                    onBlur={register.handleBlur}
                    onFocus={register.handleBlur}
                    validateOnly={register.validateOnChange}
                  />
                </div>
              </div>
              <div>
                {register.errors.last_name && register.touched.last_name && (
                  <small className="p-error">{register.errors.last_name}</small>
                )}
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                  </span>
                  <InputText
                    placeholder={t("register.LastName") ?? "Last Name"}
                    value={register.values.last_name}
                    onChange={register.handleChange}
                    name="last_name"
                    onBlur={register.handleBlur}
                    onFocus={register.handleBlur}
                    validateOnly={register.validateOnChange}
                  />
                </div>
              </div>
              <div>
                {register.errors.email && register.touched.email && (
                  <small className="p-error">{register.errors.email}</small>
                )}
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-at"></i>
                  </span>
                  <InputText
                    placeholder={t("login.Email") ?? "Your E-mail"}
                    value={register.values.email}
                    onChange={register.handleChange}
                    name="email"
                    onBlur={register.handleBlur}
                    onFocus={register.handleBlur}
                    validateOnly={register.validateOnChange}
                  />
                </div>
              </div>
              <div>
                {register.errors.password && register.touched.password && (
                  <small className="p-error">{register.errors.password}</small>
                )}
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </span>
                  <Password
                    placeholder={t("login.Password") ?? "Your Password"}
                    type="password"
                    value={register.values.password}
                    onChange={register.handleChange}
                    name="password"
                    onBlur={register.handleBlur}
                    onFocus={register.handleBlur}
                    toggleMask
                    promptLabel={
                      t("register.ConfirmPassword") ?? "Confirm Password"
                    }
                  />
                </div>
              </div>
              <div>
                {register.errors.password_confirmation &&
                  register.touched.password_confirmation && (
                    <small className="p-error">
                      {register.errors.password_confirmation}
                    </small>
                  )}

                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </span>
                  <Password
                    placeholder={
                      t("register.ConfirmPassword") ?? "Confirm Password"
                    }
                    type="password"
                    value={register.values.password_confirmation}
                    onChange={register.handleChange}
                    name="password_confirmation"
                    onBlur={register.handleBlur}
                    onFocus={register.handleBlur}
                    toggleMask
                    promptLabel={
                      t("register.ConfirmPassword") ?? "Confirm Password"
                    }
                    weakLabel={t("register.Weak") ?? "Weak"}
                    mediumLabel={t("register.Medium") ?? "Medium"}
                    strongLabel={t("register.Strong") ?? "Strong"}
                  />
                </div>
              </div>
              <div className="add-margin-right">
                <Button
                  label={t("register.Register") ?? "Register"}
                  severity="danger"
                  type="submit"
                  icon="pi pi-sign-in"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="flex mt-3">
          <p className="font-bold mx-2 text-[#0E1530]">
            {" "}
            {t("register.HaveAnAccountAlready?") ?? "Already have an account?"}
          </p>
          <Link
            to="/login"
            className="font-bold hover:border-b hover:border-[#f50c44]  text-[#0E1530] hover:cursor-pointer"
          >
            {" "}
            {t("register.LoginHere") ?? "Login in here"}
          </Link>
        </div>
      </div>
      {visibleVerify && (
        <VerifyDialog
          passEmail={inputEmail}
          isOpen={visibleVerify}
          onOpenChange={() => setVisibleVerify(false)}
        />
      )}
      <SliderAuth />
    </div>
  );
};

export default Register;
