import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import api, { authLogin, authLogout } from "../services";
import axios, { AxiosError } from "axios";

type User = {
  email: string;
  password: string;
};

type AuthContextType = {
  user: User | null;
  login: (userData: User) => Promise<boolean>;
  logout: () => void;
};
interface AuthProviderProps {
  children: ReactNode;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: any = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const login = (userData: User): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      authLogin(userData)
        .then((response) => {
          const accessToken = response.access_token;
          localStorage.setItem("token", accessToken);
          localStorage.setItem("user", JSON.stringify(response));

          resolve(true); // Resolve the promise with true
        })
        .catch((error) => {
          console.error(error);
          reject(false); // Reject the promise with false
        });
    });
  };

  const logout = async () => {
    const response = await authLogout();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/login";
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
