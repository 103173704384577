import { useState, useEffect, useLayoutEffect } from "react";
import CategoriesContainer from "../containers/CategoriesContainer/CategoriesContainer";
import ProductsContainer from "../containers/ProductsContainer/ProductsContainer";
import {
  Category,
  FilterDTO,
  Item,
  subCategory,
} from "../modules/Retailer.modules";
import {
  addToImportList,
  getAllProducts,
  getImportedList,
  removeFromImportList,
  getCategories,
} from "../services/index";
import SubCategories from "../components/Categories/SubCategories";
import TextResult from "../components/TextResult";
import { IPagination } from "../modules/Retailer.modules";
import MainLayout from "../containers/MainLayout";
import LoadingComponent from "../components/Loading";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [propEmpty, setPropEmpty] = useState<any>();
  const [propEmptySearch, setPropEmptySearch] = useState<any>();
  const [propEmptyFilter, setPropEmptyFilter] = useState<any>();
  const [pagination, setPagination] = useState<IPagination>();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [visibleFilter, setVisibleFilter] = useState(false);

  let basePage = 1;

  useEffect(() => {
    setLoading(true);
    getAllProducts(basePage).then((res) => {
      if ("image" in res && "message" in res) {
        setPropEmpty(res);
      } else {
        if (res.pagination) {
          setPagination(res.pagination);
        }

        setProducts(res);
        setFilteredProducts(res);
      }
      setLoading(false);
    });
    getImportedList(basePage).then((res: any) => {
      if (!("image" in res && "message" in res)) {
        setItems(res);
      }
    });
    getCategories().then((res) => {
      setCategories(res);
    });
  }, []);
  const onPageChange = (text: string) => {
    let currentPage = 1;
    switch (text) {
      case "next":
        currentPage = pagination!.current_page + 1;
        break;
      case "prev":
        currentPage = pagination!.current_page - 1;
        break;
      case "first":
        currentPage = 1;
        break;
      case "last":
        currentPage = pagination!.total_pages;

        break;

      default:
        currentPage = parseInt(text, 10);
        break;
      // Default case
    }
    getAllProducts(currentPage).then((newProducts) => {
      setProducts(newProducts);
      setPagination(newProducts!.pagination);
      setFirst(currentPage);
    });
    getImportedList(currentPage).then((res: any) => {
      if (!("image" in res && "message" in res)) {
        setItems(res);
      }
    });
  };

  useLayoutEffect(() => {}, [products]);

  const [showSubCategories, setShowSubCategories] = useState<boolean>(false);
  const [activeMainCategory, setActiveMainCategory] = useState("");
  const [subCategories, setSubCategories] = useState<subCategory[]>();
  const [selectedCategoriesEnd, setSelectedCategoriesEnd] = useState<
    Category[]
  >([]);
  const [selectedMinPrice, setSelectedMinPrice] = useState<number>(0);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState<number>(0);
  const [selectedAvailableProduct, setSelectedAvailableProduct] =
    useState<number>(0);
  const [selectedPublishedOnStore, setSelectedPublishedOnStore] =
    useState<number>(0);

  const initialValues: FilterDTO = {
    categories: [],
    minPrice: 0,
    maxPrice: 0,
    available: 0,
    publishedOnStore: 0,
  };
  const filterFormik = useFormik<FilterDTO>({
    initialValues: initialValues,
    validateOnChange: true,
    onSubmit: (values) => {
      let selectedCategories = filterFormik.values.categories;
      if (filterFormik.values.categories!.length > 0) {
        selectedCategories = filterFormik.values.categories!.map(
          (cat: any) => cat.id
        );
      } else {
        selectedCategories = [];
      }

      let dataToFilter =
        selectedCategories.length > 0
          ? {
              categories: selectedCategories,
              minPrice: values.minPrice,
              maxPrice: values.maxPrice,
              available: values.available,
              publishedOnStore: values.publishedOnStore,
            }
          : {
              minPrice: values.minPrice,
              maxPrice: values.maxPrice,
              available: values.available,
              publishedOnStore: values.publishedOnStore,
            };
      setSelectedMinPrice(filterFormik.values.minPrice ?? 0);
      setSelectedMaxPrice(filterFormik.values.maxPrice ?? 0);
      setSelectedAvailableProduct(filterFormik.values.available ?? 0);
      setSelectedPublishedOnStore(filterFormik.values.publishedOnStore ?? 0);
      setSelectedCategoriesEnd(filterFormik.values.categories ?? []);
      getAllProducts(basePage, undefined, dataToFilter).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }

            setPropEmpty("");
            setPropEmptySearch("");
            setProducts(res);
            setFilteredProducts(res);
          }
        }
      });
    },
  });
  const onHideFilter = () => {
    setPropEmpty("");
    setPropEmptySearch("");
    setVisibleFilter(false);

    getAllProducts(basePage).then((res) => {
      if ("image" in res && "message" in res) {
        setPropEmpty(res);
      } else {
        if (res.pagination) {
          setPagination(res.pagination);
        }

        setProducts(res);
        setFilteredProducts(res);
      }
    });
  };
  const handleImport = (data: Item) => {
    let dataToSend = { product_id: data.id };
    addToImportList(dataToSend).then((res: any) => {
      setItems((prevItems) => [...prevItems, data]);
    });
  };
  function handleRemove(data: any) {
    let productToDelete = { product_id: data.id };
    removeFromImportList(productToDelete).then((res) => {
      setItems((prevItems: Item[]) =>
        prevItems.filter((e) => e.id !== data.id)
      );
    });
  }
  const handleSearch = (search: any) => {
    if (search.trim() !== "") {
      getAllProducts(basePage, search, undefined).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }

            setProducts(res);
            setFilteredProducts(res);
          }
        }
      });
    } else {
      getAllProducts(basePage).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }

            setProducts(res);
            setFilteredProducts(res);
          }
        }
      });
    }
  };
  // Handles the filtering of products by category when a new category is selected.
  const handleSelectCategory = (category: any) => {
    setSelectedCategory(category.title);
    setActiveMainCategory(category);

    let dataToFilter = {
      categories: [category.id],
    };

    if (category.children && category.children.length > 0) {
      setShowSubCategories(true);
      setSubCategories(category.children);
    } else {
      setShowSubCategories(false);
    }
    getAllProducts(basePage, undefined, dataToFilter).then((res) => {
      if (res !== undefined) {
        if ("image" in res && "message" in res) {
          setPropEmptySearch(res);
          setActiveMainCategory("");
        } else {
          if (res.pagination) {
            setPagination(res.pagination);
          }

          setProducts(res);
          setFilteredProducts(res);
          setPropEmptySearch("");
        }
      }
    });
  };
  const handleSubcategorySelect = (subcategory: any) => {
    let dataToFilter = {
      subCategory: [subcategory.id],
    };
    getAllProducts(basePage, undefined, dataToFilter).then((res) => {
      if (res !== undefined) {
        if ("image" in res && "message" in res) {
          setPropEmptySearch(res);
          setActiveMainCategory("all");
        } else {
          if (res.pagination) {
            setPagination(res.pagination);
          }

          setProducts(res);
          setPropEmptySearch("");
          setFilteredProducts(res);
        }
      }
    });
  };

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmptySearch && (
            <div className="p-4 space-y-4">
              <CategoriesContainer
                handleSelectCategory={handleSelectCategory}
                categories={categories}
                handleSearch={handleSearch}
                handleFilter={filterFormik.handleSubmit}
                handleChange={filterFormik.setFieldValue}
                initialMinPrice={selectedMinPrice}
                initialMaxPrice={selectedMaxPrice}
                initialAvailableProduct={selectedAvailableProduct}
                initialPublishedOnStore={selectedPublishedOnStore}
                initialCategories={selectedCategoriesEnd}
                visibleFilter={visibleFilter}
                onHideFilter={onHideFilter}
                setVisibleFilter={setVisibleFilter}
              />
              {showSubCategories && (
                <SubCategories
                  subCategories={subCategories}
                  handleSubcategorySelect={handleSubcategorySelect}
                />
              )}
              <p className="mt-4 text-sm font-normal text-center">
                {propEmptySearch.message === "No search found."
                  ? t("main.NoResultsFound") ?? "No search found."
                  : t("main.FilterResultNotFound") ??
                    "Filter result not found."}
              </p>
            </div>
          )}

          {propEmpty && <TextResult body={propEmpty} />}
          {!propEmpty && !propEmptySearch && products.length > 0 && (
            <div className="p-4 space-y-4">
              <CategoriesContainer
                handleSelectCategory={handleSelectCategory}
                categories={categories}
                handleSearch={handleSearch}
                handleFilter={filterFormik.handleSubmit}
                handleChange={filterFormik.setFieldValue}
                initialMinPrice={selectedMinPrice}
                initialMaxPrice={selectedMaxPrice}
                initialAvailableProduct={selectedAvailableProduct}
                initialPublishedOnStore={selectedPublishedOnStore}
                initialCategories={selectedCategoriesEnd}
                visibleFilter={visibleFilter}
                onHideFilter={onHideFilter}
                setVisibleFilter={setVisibleFilter}
              />
              {showSubCategories && (
                <SubCategories
                  subCategories={subCategories}
                  handleSubcategorySelect={handleSubcategorySelect}
                />
              )}
              <ProductsContainer
                products={products}
                selectedProducts={items}
                handleImport={handleImport}
                handleRemove={handleRemove}
                dataPagination={pagination!}
                first={first}
                rows={rows}
                setFirst={setFirst}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </>
      )}
    </MainLayout>
  );
};

export default Products;
