import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  BanksInfo,
  CreateBankAccount,
  CurrencySystem,
  DeleteBankAccount,
  DetailsBankAccount,
  UpdateBankAccount,
} from "../services";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { BankDTO } from "../modules/Retailer.modules";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import TextResult from "../components/TextResult";
import { useTranslation } from "react-i18next";
import MainLayout from "../containers/MainLayout";
import LoadingComponent from "../components/Loading";
import * as Yup from "yup";
import { classNames } from "primereact/utils";

import {
  CREATE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNTS,
  UPDATE_BANK_ACCOUNTS,
} from "../permissions/permissions";
import { FilterMatchMode } from "primereact/api";
const Banks = () => {
  const [BankList, setBankList] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [rowIdForEdit, setRowIdForEdit] = useState<number>();
  const [CurrencyList, setCurrencyList] = useState<any>();
  const [propEmpty, setPropEmpty] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    account_holder_name: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    account_number: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    account_type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    balance: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    bank_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    branch_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
    currency: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    swift_code: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const getBanks = () => {
    BanksInfo().then((res) => {
      if (res !== undefined) {
        if ("image" in res && "message" in res) {
          setBankList([]);
        } else {
          setBankList(res);
        }
        setLoading(false);
      }
    });
    CurrencySystem().then((res) => setCurrencyList(res));
  };
  const options = CurrencyList?.map((currency: any) => ({
    label: currency,
    value: currency,
  }));
  const validationSchema = Yup.object({
    account_number: Yup.string().required(
      t("bankPage.AccountNumberIsRequired") ?? "Account Number Is Required"
    ),
    bank_name: Yup.string().required(
      t("bankPage.AccountNameIsRequired") ?? "Account Name Is Required"
    ),
    account_holder_name: Yup.string().required(
      t("bankPage.AccountHolderNameIsRequired") ??
        "Account Holder Name Is Required"
    ),
    account_type: Yup.string().required(
      t("bankPage.AccountTypeIsRequired") ?? "Account Type Is Required"
    ),
    branch_name: Yup.string().required(
      t("bankPage.BranchNameIsRequired") ?? "Branch Name Is Required"
    ),
    iban: Yup.string().required(
      t("bankPage.IBANIsRequired") ?? "IBAN Is Required"
    ),
    ifsc_code: Yup.string().required(
      t("bankPage.IFSCIsRequired") ?? "IFSC Is Required"
    ),
    swift_code: Yup.string().required(
      t("bankPage.SwiftCodeIsRequired") ?? "Swift Code Is Required"
    ),
    currency: Yup.string().required(
      t("bankPage.CurrencyIsRequired") ?? "Currency Is Required"
    ),
    balance: Yup.string().required(
      t("bankPage.BalanceIsRequired") ?? "Balance Is Required"
    ),
  });
  const Bankform = useFormik<BankDTO>({
    initialValues: new BankDTO(),
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: () => {
      CreateBankAccount(Bankform.values).then((res: any) => {
        if (res) {
          setBankList((prevItems: any) => [...prevItems, res]);
          setShow(false);
          Bankform.resetForm();
        }
      });
    },
  });

  const BankformEdit = useFormik<BankDTO>({
    initialValues: new BankDTO(),
    validateOnChange: true,
    onSubmit: () => {
      UpdateBankAccount(BankformEdit.values, rowIdForEdit);
      setShowEdit(false);
    },
  });
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        {CREATE_BANK_ACCOUNT() ? (
          <Button
            label={t("bankPage.AddNewBankAccount") ?? "Add New Bank Account"}
            onClick={() => setShow(true)}
            size="small"
            severity="danger"
          ></Button>
        ) : (
          <></>
        )}
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={t("bankPage.KeywordSearch") ?? "Keyword Search"}
          />
        </span>
      </div>
    );
  };
  const ShowBank = async (id: number) => {
    setShowEdit(true);
    const data = await DetailsBankAccount(id);
    BankformEdit.setValues(data);
    setRowIdForEdit(id);
  };
  useEffect(() => {
    setLoading(true);
    getBanks();
  }, []);
  const DeleteAccount = async (id: number) => {
    DeleteBankAccount(id).then((res: any) => {
      setBankList((prevItems: any) =>
        prevItems.filter((e: any) => e.id !== id)
      );
    });
  };
  const confirm = (id: number) => {
    confirmDialog({
      message:
        t("bankPage.DoYouWantToDeleteThisBankAccount") ??
        "Do you want to delete this bank account?",
      header: t("bankPage.DeleteConfirmation") ?? "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: t("bankPage.reject") ?? "reject",
      acceptClassName: "change-color-btn-outlined p-button-outlined py-2",
      rejectClassName: "p-button-danger  py-2",
      acceptLabel: t("main.Delete") ?? "Delete",
      rejectLabel: t("main.Cancel") ?? "Cancel",
      accept: () => DeleteAccount(id),
    });
  };

  const BodyTemplate = (rowData: any) => {
    return (
      <div className="gap-3">
        {UPDATE_BANK_ACCOUNTS() ? (
          <i
            className="pi pi-bold pi-pencil"
            onClick={() => ShowBank(rowData?.id)}
            style={{
              fontSize: "1.2rem",
              color: "#0E1530",
              padding: "7px",
              cursor: "pointer",
            }}
          ></i>
        ) : (
          <></>
        )}
        {DELETE_BANK_ACCOUNTS() ? (
          <i
            className="pi pi-bold pi-trash"
            onClick={() => confirm(rowData?.id)}
            style={{ fontSize: "1rem", color: "red", cursor: "pointer" }}
          ></i>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const header = renderHeader();

  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmpty && <TextResult body={propEmpty} />}
          <ConfirmDialog />
          <div className="new-data-table">
            <DataTable
              value={BankList}
              stripedRows
              showGridlines
              className=" m-4"
              tableStyle={{ minWidth: "40rem" }}
              filters={filters}
              paginator
              rows={10}
              header={header}
              scrollable
            >
              <Column
                field="account_holder_name"
                header={t("bankPage.AccountName") ?? "Account Name"}
                filter
                filterPlaceholder={t("main.SearchByName") ?? "Search by name"}
              ></Column>
              <Column
                field="account_number"
                header={t("bankPage.AccountNumber") ?? "Account Number"}
                filter
                filterPlaceholder={t("main.SearchByName") ?? "Search by name"}
              ></Column>
              <Column
                field="account_type"
                header={t("bankPage.AccountType") ?? "Account Type"}
                filter
                filterPlaceholder={t("main.SearchByName") ?? "Search by name"}
              ></Column>
              <Column
                field="balance"
                header={t("bankPage.Balance") ?? "Balance"}
                filter
                filterPlaceholder={t("main.SearchByName") ?? "Search by name"}
              ></Column>
              <Column
                field="bank_name"
                header={t("bankPage.BankName") ?? "Bank Name"}
                filter
                filterPlaceholder={t("main.SearchByName") ?? "Search by name"}
              ></Column>
              <Column
                field="branch_name"
                header={t("bankPage.BranchName") ?? "Branch Name"}
                filter
                filterPlaceholder={t("main.SearchByName") ?? "Search by name"}
              ></Column>
              <Column
                field="created_at"
                header={t("bankPage.Date") ?? "Date"}
                filter
                filterPlaceholder={t("main.SearchByDate") ?? "Search by date"}
              ></Column>
              <Column
                field="currency"
                header={t("bankPage.Currency") ?? "Currency"}
                filter
                filterPlaceholder={
                  t("main.SearchByCurrency") ?? "Search by Currency"
                }
              ></Column>
              <Column field="iban" header="IBAN"></Column>
              <Column
                field="swift_code"
                header={t("bankPage.SwiftCode") ?? "Swift Code"}
                filter
                filterPlaceholder={
                  t("main.SearchBySwiftCode") ?? "Search by Swift Code"
                }
              ></Column>
              <Column
                field=""
                header={t("bankPage.Actions") ?? "Actions"}
                body={BodyTemplate}
              ></Column>
            </DataTable>
          </div>
          <></>
          <Dialog
            header={t("bankPage.AddNewBankAccount") ?? "Add New Bank Account"}
            visible={show}
            className="md:w-40rem lg:w-40rem"
            onHide={() => setShow(false)}
            footer={
              <div className="mt-2">
                <Button
                  label={t("main.Save") ?? "Save"}
                  severity="danger"
                  size="small"
                  type="submit"
                  onClick={() => Bankform.handleSubmit()}
                  className="mt-4"
                ></Button>
                <Button
                  label={t("main.Cancel") ?? "Cancel"}
                  severity="danger"
                  outlined
                  size="small"
                  onClick={() => setShow(false)}
                  className="mt-4"
                ></Button>
              </div>
            }
          >
            <div className="grid mt-2">
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="AccountNumber">
                  {" "}
                  {t("bankPage.AccountNumber") ?? "Account Number"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.AccountNumber") ?? "Account Number"}
                  name="account_number"
                  value={Bankform?.values?.account_number}
                  onChange={(e) =>
                    Bankform.setFieldValue("account_number", e.target.value)
                  }
                  className={classNames({
                    "p-error": Bankform.touched.account_number,
                  })}
                />
                {Bankform.touched.account_number && (
                  <small className="p-error">
                    {Bankform.errors.account_number}
                  </small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Account Name">
                  {" "}
                  {t("bankPage.AccountName") ?? "Account Name"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.AccountName") ?? "Account Name"}
                  name="bank_name"
                  value={Bankform?.values?.bank_name}
                  onChange={(e) =>
                    Bankform.setFieldValue("bank_name", e.target.value)
                  }
                  className={classNames({
                    "p-error": Bankform.touched.bank_name,
                  })}
                />
                {Bankform.touched.bank_name && (
                  <small className="p-error">{Bankform.errors.bank_name}</small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Account Holder Name">
                  {" "}
                  {t("bankPage.AccountHolderName") ??
                    "Account Holder Name"}{" "}
                </label>
                <InputText
                  placeholder={
                    t("bankPage.AccountHolderName") ?? "Account Holder Name"
                  }
                  name="account_holder_name"
                  value={Bankform?.values?.account_holder_name}
                  onChange={(e) =>
                    Bankform.setFieldValue(
                      "account_holder_name",
                      e.target.value
                    )
                  }
                  className={classNames({
                    "p-error": Bankform.touched.account_holder_name,
                  })}
                />
                {Bankform.touched.account_holder_name && (
                  <small className="p-error">
                    {Bankform.errors.account_holder_name}
                  </small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Account Type">
                  {" "}
                  {t("bankPage.AccountType") ?? "Account Type"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.AccountType") ?? "Account Type"}
                  name="account_type"
                  value={Bankform?.values?.account_type}
                  onChange={(e) =>
                    Bankform.setFieldValue("account_type", e.target.value)
                  }
                  className={classNames({
                    "p-error": Bankform.touched.account_type,
                  })}
                />
                {Bankform.touched.account_type && (
                  <small className="p-error">
                    {Bankform.errors.account_type}
                  </small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Branch Name">
                  {" "}
                  {t("bankPage.BranchName") ?? "Branch Name"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.BranchName") ?? "Branch Name"}
                  name="branch_name"
                  value={Bankform?.values?.branch_name}
                  onChange={(e) =>
                    Bankform.setFieldValue("branch_name", e.target.value)
                  }
                  className={classNames({
                    "p-error": Bankform.touched.branch_name,
                  })}
                />
                {Bankform.touched.branch_name && (
                  <small className="p-error">
                    {Bankform.errors.branch_name}
                  </small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="IBAN">
                  {" "}
                  IBAN{" "}
                </label>
                <InputText
                  placeholder="IBAN"
                  name="iban"
                  value={Bankform?.values?.iban}
                  onChange={(e) =>
                    Bankform.setFieldValue("iban", e.target.value)
                  }
                  className={classNames({
                    "p-error": Bankform.touched.iban,
                  })}
                />
                {Bankform.touched.iban && (
                  <small className="p-error">{Bankform.errors.iban}</small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="IFSC">
                  {" "}
                  IFSC{" "}
                </label>
                <InputText
                  placeholder="IFSC"
                  name="ifsc_code"
                  value={Bankform?.values?.ifsc_code}
                  onChange={(e) =>
                    Bankform.setFieldValue("ifsc_code", e.target.value)
                  }
                  className={classNames({
                    "p-error": Bankform.touched.ifsc_code,
                  })}
                />
                {Bankform.touched.ifsc_code && (
                  <small className="p-error">{Bankform.errors.ifsc_code}</small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Swift Code">
                  {" "}
                  {t("bankPage.SwiftCode") ?? "Swift Code"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.SwiftCode") ?? "Swift Code"}
                  name="swift_code"
                  value={Bankform?.values?.swift_code}
                  onChange={(e) =>
                    Bankform.setFieldValue("swift_code", e.target.value)
                  }
                  className={classNames({
                    "p-error": Bankform.touched.swift_code,
                  })}
                />
                {Bankform.touched.swift_code && (
                  <small className="p-error">
                    {Bankform.errors.swift_code}
                  </small>
                )}
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Currency">
                  {" "}
                  {t("bankPage.Currency") ?? "Currency"}{" "}
                </label>
                <Dropdown
                  value={Bankform?.values?.currency}
                  onChange={(e) =>
                    Bankform.setFieldValue("currency", e.target.value)
                  }
                  options={options}
                  optionLabel="label"
                  optionValue="value"
                  name="currency"
                  placeholder={
                    t("bankPage.SelectACurrency") ?? "Select a Currency"
                  }
                  className={classNames("w-full", {
                    "p-error":
                      Bankform.touched.currency && Bankform.errors.currency,
                  })}
                />
                {typeof Bankform.errors.currency === "string" && (
                  <small className="p-error">{Bankform.errors.currency}</small>
                )}
              </div>
            </div>
            <div className="grid mt-2">
              <div className="col-6">
                <label className="mb-2" htmlFor="Balance">
                  {" "}
                  {t("bankPage.Balance") ?? "Balance"}{" "}
                </label>
                <InputNumber
                  placeholder={t("bankPage.Balance") ?? "Balance"}
                  name="balance"
                  value={Bankform?.values?.balance}
                  onChange={(e) => Bankform.setFieldValue("balance", e.value)}
                  min={1}
                  className={classNames({
                    "p-error": Bankform.touched.balance,
                  })}
                />
                {Bankform.touched.balance && (
                  <small className="p-error">{Bankform.errors.balance}</small>
                )}
              </div>
              <div className="col-6">
                <label className="pt-3" htmlFor="Primary">
                  {" "}
                  {t("bankPage.Primary") ?? "Primary"}{" "}
                </label>
                <InputSwitch
                  placeholder={t("bankPage.Primary") ?? "Primary"}
                  name="is_primary"
                  checked={Bankform?.values?.is_primary}
                  onChange={(e) =>
                    Bankform.setFieldValue("is_primary", e.value)
                  }
                  className="mt-2"
                />
              </div>
            </div>
          </Dialog>
          {/* Edit form */}
          <Dialog
            header={t("bankPage.EditBankAccount") ?? "Edit Bank Account"}
            visible={showEdit}
            className="	md:w-40rem lg:w-40rem"
            onHide={() => setShowEdit(false)}
            footer={
              <div className="mt-2">
                <Button
                  label={t("main.Edit") ?? "Edit"}
                  severity="danger"
                  type="submit"
                  size="small"
                  onClick={() => BankformEdit.handleSubmit()}
                  className="mt-4"
                ></Button>
                <Button
                  label={t("main.Cancel") ?? "Cancel"}
                  severity="danger"
                  outlined
                  size="small"
                  onClick={() => setShowEdit(false)}
                  className="mt-4"
                ></Button>
              </div>
            }
          >
            <div className="grid mt-3">
              <div className="col-6 md:col-4 lg:col-4 ">
                <label className="mb-2" htmlFor="Account Number">
                  {" "}
                  {t("bankPage.AccountNumber") ?? "Account Number"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.AccountNumber") ?? "Account Number"}
                  name="account_number"
                  value={BankformEdit?.values?.account_number}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("account_number", e.target.value)
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4 ">
                <label className="mb-2" htmlFor="Bank Name">
                  {" "}
                  {t("bankPage.BankName") ?? "Bank Name"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.BankName") ?? "Bank Name"}
                  name="bank_name"
                  value={BankformEdit?.values?.bank_name}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("bank_name", e.target.value)
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4 ">
                <label className="mb-2" htmlFor="Account Holder Name">
                  {" "}
                  {t("bankPage.AccountHolderName") ??
                    "Account Holder Name"}{" "}
                </label>
                <InputText
                  placeholder={
                    t("bankPage.AccountHolderName") ?? "Account Holder Name"
                  }
                  name="account_holder_name"
                  value={BankformEdit?.values?.account_holder_name}
                  onChange={(e) =>
                    BankformEdit.setFieldValue(
                      "account_holder_name",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Account Type">
                  {" "}
                  {t("bankPage.AccountType") ?? "Account Type"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.AccountType") ?? "Account Type"}
                  name="account_type"
                  value={BankformEdit?.values?.account_type}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("account_type", e.target.value)
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="Branch Name">
                  {" "}
                  {t("bankPage.BranchName") ?? "Branch Name"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.BranchName") ?? "Branch Name"}
                  name="branch_name"
                  value={BankformEdit?.values?.branch_name}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("branch_name", e.target.value)
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4">
                <label className="mb-2" htmlFor="IBAN">
                  {" "}
                  IBAN{" "}
                </label>
                <InputText
                  placeholder="IBAN"
                  name="iban"
                  value={BankformEdit?.values?.iban}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("iban", e.target.value)
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4 ">
                <label className="mb-2" htmlFor="IFSC">
                  {" "}
                  IFSC{" "}
                </label>
                <InputText
                  placeholder="IFSC"
                  name="ifsc_code"
                  value={BankformEdit?.values?.ifsc_code}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("ifsc_code", e.target.value)
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4 ">
                <label className="mb-2" htmlFor="Swift Code">
                  {" "}
                  {t("bankPage.SwiftCode") ?? "Swift Code"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.SwiftCode") ?? "Swift Code"}
                  name="swift_code"
                  value={BankformEdit?.values?.swift_code}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("swift_code", e.target.value)
                  }
                />
              </div>
              <div className="col-6 md:col-4 lg:col-4 ">
                <label className="mb-2" htmlFor="currency">
                  {" "}
                  {t("bankPage.Currency") ?? "Currency"}{" "}
                </label>
                <InputText
                  placeholder={t("bankPage.Currency") ?? "Currency"}
                  name="currency"
                  value={BankformEdit?.values?.currency}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("currency", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="grid mt-2">
              <div className="col-6">
                <label className="mb-2" htmlFor="balance">
                  {" "}
                  {t("bankPage.Balance") ?? "Balance"}{" "}
                </label>
                <InputNumber
                  placeholder={t("bankPage.Balance") ?? "Balance"}
                  name="balance"
                  value={BankformEdit?.values?.balance}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("balance", e.value)
                  }
                />
              </div>
              <div className="col-6 ">
                <label className="mb-2" htmlFor="Primary">
                  {" "}
                  {t("bankPage.Primary") ?? "Primary"}{" "}
                </label>
                <InputSwitch
                  placeholder={t("bankPage.Primary") ?? "Primary"}
                  name="is_primary"
                  checked={BankformEdit?.values?.is_primary}
                  onChange={(e) =>
                    BankformEdit.setFieldValue("is_primary", e.value)
                  }
                />
              </div>
            </div>
          </Dialog>
        </>
      )}
    </MainLayout>
  );
};

export default Banks;
