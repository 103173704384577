import { Carousel } from "primereact/carousel";
import { useTranslation } from "react-i18next";

import ProductTemplate from "./ProductTemplate";
import "../../styles/login.scss";

import log from "../../styles/images/store.svg";
import loginPhoto from "../../styles/images/login.svg";
function SliderAuth() {
  const { t } = useTranslation();

  const images = [
    { source: log, alt: "Store Image" },
    { source: loginPhoto, alt: "Login Image" },
  ];
  const productTemplate = (product: any) => {
    return (
      <div className="surface-border border-round text-center">
        <div className="">
          <img src={product?.source} alt={""} className="h-64 w-full" />
        </div>
      </div>
    );
  };
  return (
    <div className="shopping-cart-container slider-login">
      <h1 className=" login-tx">
        {t("login.WelcometoDOSHTUplatform!") ?? "Welcome in DOSHTU platform!"}
      </h1>
      <Carousel
        dir="ltr"
        value={images}
        className="custom-carousel"
        circular
        numVisible={1}
        numScroll={1}
        autoplayInterval={2000}
        itemTemplate={productTemplate}
      ></Carousel>
    </div>
  );
}

export default SliderAuth;
