import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Item, ItemTemp, ProductEditDTO } from "../modules/Retailer.modules";
import { concatenateAndMoveDimensionsToEnd } from "../helpers/helper";
import { useTranslation } from "react-i18next";

type Props = {
  item: Item;
  initialValues: ProductEditDTO;
  handleChange: (name: string, val: string | number) => void;
  itemTemp: ItemTemp;
};

const EditableTableTab = ({
  item,
  initialValues,
  handleChange,

  itemTemp,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState("" as any);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";

  let updatedSubProducts = item?.subProducts.map((subProduct) => {
    const updatedSubProduct =
      initialValues?.sub_products?.find(
        (temporary) => temporary.id === subProduct.id
      ) ||
      itemTemp?.sub_products_temporary?.find(
        (temporary) => temporary.sub_product_id === subProduct.id
      );

    if (updatedSubProduct) {
      return {
        ...subProduct,
        recommended_price: updatedSubProduct.price,
        sku: updatedSubProduct.sku,
        ratio: updatedSubProduct.ratio,
      };
    } else {
      return subProduct;
    }
  });
  const [updatedSubProductsEdit, setUpdatedSubProductsEdit] =
    useState(updatedSubProducts);

  const [expandedRows, setExpandedRows] = useState<any>(null);
  const allowExpansion = (rowData: any) => {
    return rowData.dimensions.length > 0;
  };

  const imageTemplate = (item: any) => {
    return (
      <img
        src={`${item.image} `}
        alt={item.title}
        className="w-6rem shadow-2 border-round"
      />
    );
  };
  const isPositiveInteger = (val: number) => {
    let str = String(val);

    str = str.trim();

    if (!str) {
      return false;
    }

    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));

    return n !== Infinity && String(n) === str && n >= 0;
  };
  const buttonTemplate = (rowData: any) => {
    return (
      <>
        <Button
          label={t("importListCard.Info") ?? "info"}
          type="button"
          onClick={() => {
            setVisible(true);
            setSelectedItem(rowData);
          }}
          disabled={rowData.dimensions.length > 0 ? false : true}
        />
      </>
    );
  };
  const priceBodyTemplate = (rowData: any) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(rowData.recommended_price);
  };

  const ratioBodyTemplate = (rowData: any) => {
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
    }).format(rowData.ratio / 100);
  };

  const textEditor1 = (options: any) => {
    return (
      <InputNumber
        value={
          updatedSubProductsEdit[options.rowIndex].recommended_price ??
          parseFloat(options.rowData.recommended_price)
        }
        onValueChange={(e: any) => {
          options.editorCallback(e.target.value);
          setUpdatedSubProductsEdit((prevProducts) =>
            prevProducts.map((product) => {
              return product.id === options.rowData.id
                ? {
                    ...product,
                    recommended_price: e.target.value,
                    ratio:
                      (e.target.value / options.rowData.listing_price - 1) *
                      100,
                  }
                : product;
            })
          );
          handleChange(`sub_products[${options.rowIndex}].price`, e.value);
          handleChange(
            `sub_products[${options.rowIndex}].ratio`,

            (e.target.value / options.rowData.listing_price - 1) * 100
          );
        }}
        name="price"
        id="price"
        min={0}
      />
    );
  };

  const ratioEditor = (options: any) => {
    return (
      <InputNumber
        value={
          updatedSubProductsEdit[options.rowIndex].ratio ??
          parseFloat(options.value)
        }
        onValueChange={(e: any) => {
          options.editorCallback(e.value);

          if (e.value && e.value !== 0) {
            options.rowData.recommended_price =
              options.rowData.listing_price * (1 + e.value / 100);

            setUpdatedSubProductsEdit((prevProducts) =>
              prevProducts.map((product) => {
                return product.id === options.rowData.id
                  ? {
                      ...product,
                      ratio: e.value,
                      recommended_price: options.rowData.recommended_price,
                    }
                  : product;
              })
            );

            handleChange(
              `sub_products[${options.rowIndex}].ratio`,
              options.rowData.ratio
            );
            handleChange(
              `sub_products[${options.rowIndex}].price`,
              options.rowData.recommended_price
            );
          }
        }}
        min={1}
        inputId="stacked-buttons"
        locale="en-US"
        name="ratio"
        id="ratio"
        useGrouping={false}
        pt={{
          input: {
            root: { autoComplete: "off" },
          },
        }}
        showButtons
        step={1}
        decrementButtonClassName="p-button-danger"
        incrementButtonClassName="p-button-danger"
      />
    );
  };
  const textEditor = (options: any) => {
    return (
      <InputText
        type="text"
        value={updatedSubProductsEdit[options.rowIndex].sku}
        width="150px"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          options.editorCallback(e.target.value);
          handleChange(`sub_products[${options.rowIndex}].sku`, e.target.value);
          setUpdatedSubProductsEdit((prevProducts) =>
            prevProducts.map((product) => {
              return product.id === options.rowData.id
                ? { ...product, sku: e.target.value }
                : product;
            })
          );
        }}
        name="sku"
        id="sku"
      />
    );
  };
  const cellEditor = (options: any) => {
    if (options.field === "ratio") return ratioEditor(options);
    if (options.field === "recommended_price") return textEditor1(options);
    return textEditor(options);
  };
  const onCellEditComplete = (e: any) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case "sku":
        rowData[field] = newValue;
        break;
      case "ratio":
        rowData[field] = newValue;
        break;
      case "recommended_price":
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        return false;
    }
  };

  let names = item.names || [];
  let keys = Object.keys(item.subProducts[0]) || {};
  let concatenatedArray = concatenateAndMoveDimensionsToEnd(keys, names);

  return (
    <div>
      <DataTable
        value={updatedSubProductsEdit}
        editMode="cell"
        //tableStyle={{ minWidth: "50rem" }}
        paginator
        rows={10}
        size="normal"
        dataKey="id"
        dir={isRTL ? "ltr" : "ltr"}
        expandedRows={expandedRows}
        showGridlines
        stripedRows
        responsiveLayout="scroll"
        breakpoint="960px"
      >
        {concatenatedArray
          .filter(
            (col: any, index: number, self: any[]) =>
              self.indexOf(col) === index
          )
          .filter(
            (col: any) =>
              col !== "default" &&
              col !== "subProductAttributes" &&
              col !== "attribute_text_style" &&
              col !== "price" &&
              col !== "id" &&
              col !== "active"
          )
          .map((col: any, index: number) => {
            const isSubNameCol = item.names.includes(col);

            return col === "recommended_price" ? (
              <Column
                key={index}
                field={col}
                header={t("importListCard.SalesPrice") ?? "Sales Price"}
                body={col === "recommended_price" && priceBodyTemplate}
                editor={(options) => cellEditor(options)}
                onCellEditComplete={onCellEditComplete}
                style={{ width: "15%" }}
              />
            ) : col === "sku" ? (
              <Column
                key={index}
                field={col}
                header={t("importListCard.SKU") ?? "SKU"}
                editor={(options) => textEditor(options)}
                onCellEditComplete={onCellEditComplete}
                style={{ width: "15%" }}
              />
            ) : col === "ratio" ? (
              <Column
                key={index}
                field={col}
                header={t("importListCard.Ratio") ?? "Ratio"}
                body={col === "ratio" && ratioBodyTemplate}
                editor={(options) => cellEditor(options)}
                onCellEditComplete={onCellEditComplete}
                style={{ width: "15%" }}
              />
            ) : col === "image" ? (
              <Column
                key={index}
                field="image"
                header={t("importListCard.Image") ?? "Image"}
                body={imageTemplate}
              />
            ) : col === "quantity" ? (
              <Column
                key={index}
                field="quantity"
                header={t("importListCard.Quantity") ?? "Quantity"}
              />
            ) : col === "listing_price" ? (
              <Column
                key={index}
                field="listing_price"
                header={t("productPage.ListingPrice") ?? "List Price"}
              />
            ) : col && isSubNameCol ? (
              <Column
                key={index}
                field={col}
                header={col}
                body={(rowData: any) => {
                  const attribute = rowData.subProductAttributes?.find(
                    (attr: any) => attr.name === col
                  );
                  return attribute ? attribute.value : null;
                }}
              />
            ) : col === "dimensions" ? (
              <Column
                expander={allowExpansion}
                header={t("importListCard.Details") ?? "Details"}
                body={buttonTemplate}
                key={index}
                field={col}
              />
            ) : (
              <Column key={index} field={col} header={col} />
            );
          })}
      </DataTable>
      <Dialog
        header={t("importListCard.Dimensions") ?? "Dimensions"}
        visible={visible}
        className="w-72 md:w-96 lg:w-96 xl:w-96"
        onHide={() => setVisible(false)}
      >
        {selectedItem && (
          <div className="p-4">
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="font-semibold">
                    {" "}
                    {t("importListCard.Height") ?? "Height:"}
                  </td>
                  <td>{selectedItem.dimensions?.height}</td>
                </tr>
                <tr>
                  <td className="font-semibold">
                    {" "}
                    {t("importListCard.Weight") ?? "Weight:"}
                  </td>
                  <td>{selectedItem.dimensions?.weight}</td>
                </tr>
                <tr>
                  <td className="font-semibold">
                    {t("importListCard.Length") ?? "Length:"}
                  </td>
                  <td>{selectedItem.dimensions?.length}</td>
                </tr>
                <tr>
                  <td className="font-semibold">
                    {t("importListCard.Width") ?? "Width:"}
                  </td>
                  <td>{selectedItem.dimensions?.width}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default EditableTableTab;
