import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useTranslation } from "react-i18next";

function Charts(props: any) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const { t } = useTranslation();
 const { classNameProp } = props;

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );

    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        t("charts.January") ?? "January",
        t("charts.February") ?? "February",
        t("charts.March") ?? "March",
        t("charts.April") ?? "April",
        t("charts.May") ?? "May",
        t("charts.June") ?? "June",
        t("charts.July") ?? "July",
      ],
      datasets: [
        {
          label: t("charts.MyFirstDataset") ?? "My First dataset",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: t("charts.MySecondDataset") ?? "My Second dataset",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <div className="card">
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        className={classNameProp}
      />
    </div>
  );
}
export default Charts;
