const getUser = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return JSON.parse(localStorage.getItem('user') as any);
  }
  return null;
};

const User = getUser();
export const permissions = Array.isArray(User?.authorizations?.permissions)
  ? User.authorizations.permissions
  : null;

const hasPermission = (key: string) => {
  return permissions?.some((permission: any) => permission[key]) ?? false;
};

const createPermissionChecker = (key: string) => () => hasPermission(key);

export const CREATE_BANK_ACCOUNT = createPermissionChecker('create_bank_accounts');
export const CREATE_AUTHORITIES = createPermissionChecker('create_authorities');
export const CREATE_COMPLAINTS = createPermissionChecker('create_complaints');
export const CREATE_CONNECT_STORES = createPermissionChecker('create_connect_stores');
export const CREATE_COUPONS = createPermissionChecker('create_coupons');
export const CREATE_END_CUSTOMERS = createPermissionChecker('create_end_customers');
export const CREATE_INTEGRATION_SUPPORTS = createPermissionChecker('create_integration_supports');
export const CREATE_EMPLOYEES = createPermissionChecker('create_employees');
export const CREATE_NOTIFICATIONS = createPermissionChecker('create_notifications');
export const CREATE_ORDERS = createPermissionChecker('create_orders');
export const CREATE_PRODUCTS = createPermissionChecker('create_products');
export const CREATE_SUB_PRODUCTS = createPermissionChecker('create_sub_products');
export const DELETE_AUTHORITIES = createPermissionChecker('delete_authorities');
export const DELETE_BANK_ACCOUNTS = createPermissionChecker('delete_bank_accounts');
export const DELETE_CONNECT_STORES = createPermissionChecker('delete_connect_stores');
export const DELETE_COMPLAINTS = createPermissionChecker('delete_complaints');
export const DELETE_COUPONS = createPermissionChecker('delete_coupons');
export const DELETE_END_CUSTOMERS = createPermissionChecker('delete_end_customers');
export const DELETE_EMPLOYEES = createPermissionChecker('delete_employees');
export const DELETE_INTEGRATION_SUPPORTS = createPermissionChecker('delete_integration_supports');
export const DELETE_SUB_PRODUCTS = createPermissionChecker('delete_sub_products');
export const DELETE_NOTIFICATIONS = createPermissionChecker('delete_notifications');
export const DELETE_ORDERS = createPermissionChecker('delete_orders');
export const DELETE_PRODUCTS = createPermissionChecker('delete_products');
export const READ_AUTHORITIES = createPermissionChecker('read_authorities');
export const READ_BANK_ACCOUNTS = createPermissionChecker('read_bank_accounts');
export const READ_COMPLAINTS = createPermissionChecker('read_complaints');
export const READ_EMPLOYEES = createPermissionChecker('read_employees');
export const READ_NOTIFICATIONS = createPermissionChecker('read_notifications');
export const READ_CONNECT_STORES = createPermissionChecker('read_connect_stores');
export const READ_COUPONS = createPermissionChecker('read_coupons');
export const READ_END_CUSTOMERS = createPermissionChecker('read_end_customers');
export const READ_INTEGRATION_SUPPORTS = createPermissionChecker('read_integration_supports');
export const READ_SUB_PRODUCTS = createPermissionChecker('read_sub_products');
export const READ_ORDERS = createPermissionChecker('read_orders');
export const READ_PRODUCTS = createPermissionChecker('read_products');
export const UPDATE_BANK_ACCOUNTS = createPermissionChecker('update_bank_accounts');
export const UPDATE_COMPLAINTS = createPermissionChecker('update_complaints');
export const UPDATE_EMPLOYEES = createPermissionChecker('update_employees');
export const UPDATE_NOTIFICATIONS = createPermissionChecker('update_notifications');
export const UPDATE_ORDERS = createPermissionChecker('update_orders');
export const UPDATE_PRODUCTS = createPermissionChecker('update_products');
export const UPDATE_CONNECT_STORES = createPermissionChecker('update_connect_stores');
export const UPDATE_COUPONS = createPermissionChecker('update_coupons');
export const UPDATE_INTEGRATION_SUPPORTS = createPermissionChecker('update_integration_supports');
