import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StoreCard from "../../components/Cards/StoreCard";
import StoreSearch from "../../components/SearchVeFilters/StoreSearch";
import {
  addToCart,
  ClearCart,
  increaseCartItem,
  liveProducts,
  restoreProductToImportList,
  showCart,
  showStore,
  decreaseCartItem,
  removeCartItem,
} from "../../services";
import { useFormik } from "formik";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import TextResult from "../../components/TextResult";
import {
  Category,
  FilterDTO,
  IPagination,
  Item,
} from "../../modules/Retailer.modules";
import PaginationTemplate from "../../components/templates/PaginationTemplate";
import MainLayout from "../MainLayout";
import LoadingComponent from "../../components/Loading";
import { useTranslation } from "react-i18next";

const StoreContainer = () => {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  const [store, setStore] = useState<any>([]);

  const [propEmpty, setPropEmpty] = useState<any>();
  const [propEmptySearch, setPropEmptySearch] = useState<any>();
  const [first, setFirst] = useState(0);
  const [pagination, setPagination] = useState<IPagination>();
  const [loading, setLoading] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState<any>([]);
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [selectedCategoriesEnd, setSelectedCategoriesEnd] = useState<
    Category[]
  >([]);
  const [selectedMinPrice, setSelectedMinPrice] = useState<number>(0);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState<number>(0);
  const [selectedAvailableProduct, setSelectedAvailableProduct] =
    useState<number>(0);
  const [selectedPublishedOnStore, setSelectedPublishedOnStore] =
    useState<number>(0);
  // cart related variables
  const [cartItemsCount, setCartItemsCount] = useState<number>(0);
  const [cartItems, setCartItems] = useState([]);
  const [cartProducts, setCartProducts] = useState<any>([]);
  let [cartOpen, setCartOpen] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  let basePage = 1;
  const initialValues: FilterDTO = {
    categories: [],
    minPrice: 0,
    maxPrice: 0,
    available: 0,
    publishedOnStore: 0,
  };
  const filterStoreFormik = useFormik<FilterDTO>({
    initialValues: initialValues,
    validateOnChange: true,
    onSubmit: (values) => {
      let selectedCategories = filterStoreFormik.values.categories;
      if (filterStoreFormik.values.categories!.length > 0) {
        selectedCategories = filterStoreFormik.values.categories!.map(
          (cat: any) => cat.id
        );
      } else {
        selectedCategories = [];
      }

      let dataToFilter =
        selectedCategories.length > 0
          ? {
              categories: selectedCategories,
              minPrice: values.minPrice,
              maxPrice: values.maxPrice,
              available: values.available,
              publishedOnStore: values.publishedOnStore,
            }
          : {
              minPrice: values.minPrice,
              maxPrice: values.maxPrice,
              available: values.available,
              publishedOnStore: values.publishedOnStore,
            };
      setSelectedMinPrice(filterStoreFormik.values.minPrice ?? 0);
      setSelectedMaxPrice(filterStoreFormik.values.maxPrice ?? 0);
      setSelectedAvailableProduct(filterStoreFormik.values.available ?? 0);
      setSelectedPublishedOnStore(
        filterStoreFormik.values.publishedOnStore ?? 0
      );
      setSelectedCategoriesEnd(filterStoreFormik.values.categories ?? []);
      liveProducts(id, basePage, undefined, dataToFilter).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }
            setPropEmpty("");
            setPropEmptySearch("");
            setStore(res);
            setFilteredProducts(res);
          }
        }
      });
    },
  });
  useEffect(() => {
    setLoading(true);

    showStore(id).then((res: any) => {
      liveProducts(id, basePage).then((_res: any) => {
        if ("image" in _res && "message" in _res) {
          setPropEmpty(_res);
        } else {
          if (_res.pagination) {
            setPagination(_res.pagination);
          }
          setStore(_res);
        }
        showCart(parseInt(id as any)).then((res) => {
          if (typeof res !== "string") {
            setCartProducts(res.cartItems);
            setCartItems(res.cartItems);
          }
        });
        setLoading(false);
      });

      setCartItemsCount(res?.cartItemsCount);
    });
  }, []);
  const onHideFilter = () => {
    setPropEmpty("");
    setPropEmptySearch("");
    setVisibleFilter(false);

    showStore(id).then((res: any) => {
      liveProducts(id, basePage).then((_res: any) => {
        if ("image" in _res && "message" in _res) {
          setPropEmpty(_res);
        } else {
          if (_res.pagination) {
            setPagination(_res.pagination);
          }
          setStore(_res);
        }
        showCart(parseInt(id as any)).then((res) => {
          if (typeof res !== "string") {
            setCartProducts(res.cartItems);
            setCartItems(res.cartItems);
          }
        });
      });

      setCartItemsCount(res?.cartItemsCount);
      setSelectedMinPrice(0);
      setSelectedMaxPrice(0);
      setSelectedAvailableProduct(0);
      setSelectedPublishedOnStore(0);
      setSelectedCategoriesEnd([]);
    });
  };
  const onPageChange = (text: string) => {
    let currentPage = 1;
    switch (text) {
      case "next":
        currentPage = pagination!.current_page + 1;
        break;
      case "prev":
        currentPage = pagination!.current_page - 1;
        break;
      case "first":
        currentPage = 1;
        break;
      case "last":
        currentPage = pagination!.total_pages;
        break;

      default:
      // Default case
    }

    liveProducts(id, currentPage).then((newProducts) => {
      setStore(newProducts.slice(0, -1));
      setPagination(newProducts!.pagination);
      setFirst(currentPage);
    });
  };
  function DeleteFromStore(data: any) {
    const productId = data.id;
    let productToRestore = {
      connect_store_id: id,
      product_id: data.id,
    };
    restoreProductToImportList(productToRestore).then((res) => {
      if (res) {
        setStore((prevItems: Item[]) =>
          prevItems.filter((e) => e.id !== productId)
        );
      }
    });
  }
  const handleSearch = (search: any) => {
    if (search.trim() !== "") {
      liveProducts(id, basePage, search).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }
            setStore(res);
          }
        }
      });
    } else {
      liveProducts(id, basePage).then((res) => {
        if (res !== undefined) {
          if ("image" in res && "message" in res) {
            setPropEmptySearch(res);
          } else {
            if (res.pagination) {
              setPagination(res.pagination);
            }
            setStore(res);
          }
        }
      });
    }
  };
  // Cart Functionality
  const addToCartStore = (data: any) => {
    addToCart(data).then((res: any) => {
      if (res) {
        setCartProducts(res?.cartItems);
        setCartItems(res?.cartItems);
        setCartItemsCount(res?.cart_items_count ?? 0);
      }
    });
  };
  const increaseCartStore = (data: any) => {
    const updatedData = {
      connect_store_id: id,
      ...data,
    };

    increaseCartItem(updatedData).then((res: any) => {
      if (res) {
        setCartProducts(res?.cartItems);
        setCartItems(res?.cartItems);
        setCartItemsCount(res?.cart_items_count ?? 0);
      }
    });
  };
  const decreaseCartStore = (data: any) => {
    const updatedData = {
      connect_store_id: id,
      ...data,
    };

    decreaseCartItem(updatedData).then((res: any) => {
      if (res) {
        setCartProducts(res?.cartItems);
        setCartItems(res?.cartItems);
        setCartItemsCount(res?.cart_items_count ?? 0);
      }
    });
  };
  const deleteFromStoreCart = (data: any) => {
    const updatedData = {
      connect_store_id: id,
      ...data,
    };
    /*  let filtered = cartItems.filter(
      (item: any) => item!.sub_product_id !== data.sub_products[0]?.id
    );
    console.log("filtered", filtered);
    setCartItems(filtered);
    setCartProducts(filtered);
    setCartItemsCount((prev) => prev - 1);*/
    removeCartItem(updatedData).then((res: any) => {
      /* if (res) {
        setCartProducts(res?.cartItems);
        setCartItems(res?.cartItems);
        setCartItemsCount(res?.cart_items_count ?? 0);
      } */
    });
  };

  const deleteAllCartItems = (data: any) => {
    ClearCart(data).then((res) => {
      if (res) {
        setCartItemsCount((prev) => 0);
        setCartItems((prev) => []);
        setCartOpen(false);
      }
    });
  };
  const renderGridItem = (product: any) => {
    return (
      <div className="col-10 md:col-12 lg:col-10">
        <StoreCard
          item={product}
          DeleteFromStore={DeleteFromStore}
          addToStoreCart={addToCartStore}
        />
      </div>
    );
  };
  return (
    <MainLayout>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {propEmptySearch && (
            <div className="mt-4 p-2 space-y-4">
              <StoreSearch
                storeItem={store}
                storeId={id}
                CartItemCount={cartItemsCount}
                handleSearch={handleSearch}
                handleFilter={filterStoreFormik.handleSubmit}
                handleChange={filterStoreFormik.setFieldValue}
                initialMinPrice={selectedMinPrice}
                initialMaxPrice={selectedMaxPrice}
                initialAvailableProduct={selectedAvailableProduct}
                initialPublishedOnStore={selectedPublishedOnStore}
                initialCategories={selectedCategoriesEnd}
                deleteAllCartItems={deleteAllCartItems}
                cartItems={cartItems}
                onClick={() => setCartOpen((prev) => !prev)}
                isOpen={cartOpen}
                deleteFromStoreCart={deleteFromStoreCart}
                increaseCartStore={increaseCartStore}
                decreaseCartStore={decreaseCartStore}
                visibleFilter={visibleFilter}
                onHideFilter={onHideFilter}
                setVisibleFilter={setVisibleFilter}
              />
              <div className="p-10 justify-content-center align-items-center mx-auto">
                <p className="mt-4 text-sm font-normal text-center">
                  {propEmptySearch.message === "No search found."
                    ? t("main.NoResultsFound") ?? "No search found."
                    : t("main.FilterResultNotFound") ??
                      "Filter result not found."}
                </p>
              </div>
            </div>
          )}
          {propEmpty && <TextResult body={propEmpty} />}
          {!propEmpty && !propEmptySearch && (
            <>
              <div className=" p-4 lg:p-16 md:p-10 xl:p-16 ">
                <StoreSearch
                  storeItem={store}
                  storeId={id}
                  CartItemCount={cartItemsCount}
                  handleSearch={handleSearch}
                  handleFilter={filterStoreFormik.handleSubmit}
                  handleChange={filterStoreFormik.setFieldValue}
                  initialMinPrice={selectedMinPrice}
                  initialMaxPrice={selectedMaxPrice}
                  initialAvailableProduct={selectedAvailableProduct}
                  initialPublishedOnStore={selectedPublishedOnStore}
                  initialCategories={selectedCategoriesEnd}
                  deleteAllCartItems={deleteAllCartItems}
                  cartItems={cartItems}
                  onClick={() => setCartOpen((prev) => !prev)}
                  isOpen={cartOpen}
                  deleteFromStoreCart={deleteFromStoreCart}
                  increaseCartStore={increaseCartStore}
                  decreaseCartStore={decreaseCartStore}
                  visibleFilter={visibleFilter}
                  onHideFilter={onHideFilter}
                  setVisibleFilter={setVisibleFilter}
                />
                {store.length > 0 && (
                  <>
                    <div className=" p-2 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                      <div className="">
                        <DataView
                          value={store}
                          itemTemplate={renderGridItem}
                          layout={"grid"}
                        />
                      </div>
                    </div>
                  </>
                )}{" "}
              </div>
              {pagination && store?.length > 0 && (
                <PaginationTemplate
                  onPageChange={onPageChange}
                  dataPagination={pagination!}
                />
              )}
            </>
          )}
        </>
      )}
    </MainLayout>
  );
};

export default StoreContainer;
