import { type } from "os";
import { Tag } from "primereact/tag";
import React from "react";
import { subCategory } from "../../modules/Retailer.modules";
type Props = {
  subCategories?: subCategory[];
  handleSubcategorySelect: (subCategory: any) => void;
};
const SubCategories: React.FC<Props> = ({
  subCategories,
  handleSubcategorySelect,
}: Props) => {
  return (
    <div className="card p-4 mt-2">
      {subCategories &&
        subCategories?.map((category: any) => (
          <Tag
            className="ml-2 bg-gray-500"
            key={category.id}
            value={category.title}            
            onClick={() => handleSubcategorySelect(category)}
          ></Tag>
        ))}
    </div>
  );
};

export default SubCategories;
