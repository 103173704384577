import { useFormik } from "formik";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  listSupportedIntegrations,
  createStore,
  tempSaveChange,
  Subscription,
} from "../../services";
import { Item, ItemTemp, ProductEditDTO } from "../../modules/Retailer.modules";
import CardEditor from "../CardEditor";
import EditableTableTab from "../EditableTableTab";
import ImageCard from "../ImageCard";
import ListCard from "./ListCard";
import { useNavigate } from "react-router-dom";
import SharedDataContext from "../../services/SharedData";
type Props = {
  item: Item;
  index: number;
  removeProduct: (data: any) => void;
  openModal: (data: any) => void;
  labelBtn: string;
  actionBtn: string;
  itemtemporary?: ItemTemp;
};

function ImportListCard({
  item,
  index,
  removeProduct,
  openModal,
  labelBtn,
  actionBtn,
  itemtemporary,
}: Props) {
  const basicRatio = item?.ratio;
  const initialValues: ProductEditDTO = {
    title: itemtemporary?.title ? itemtemporary.title : item.title,
    description: itemtemporary?.description
      ? itemtemporary.description
      : item.description,
    price: item.listing_price ? item.listing_price : 0,
    sub_products: itemtemporary?.sub_products_temporary
      ? itemtemporary?.sub_products_temporary?.map(
          ({ sub_product_id, price, sku, ratio }) => ({
            id: sub_product_id,
            price: price,
            sku,
            ratio,
          })
        )
      : (item.subProducts || [])?.map(
          ({ id, sku, ratio, recommended_price }) => ({
            id,
            price: recommended_price,
            sku,
            ratio: ratio ?? basicRatio,
          })
        ),
  };
  const [visible, setVisible] = useState(false);
  const [integrations, setIntegrations] = useState<any>([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const navigate = useNavigate();
  // Instead of using currentUser.wallet I will use balance from shared data
  /*
  const [currentUser, setCurrentUser] = useState(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      return JSON.parse(userString) || "";
    } else {
      return "";
    }
  });*/
  const { balance } = useContext(SharedDataContext);

  const [yourPlan, setYourPlan] = useState<any>([]);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";

  const editFormik = useFormik<ProductEditDTO>({
    initialValues: initialValues,
    validateOnChange: true,
    onSubmit: (values) => {
      const newItem = { product_id: item.id, ...editFormik.values };
      tempSaveChange(newItem).then((res) => {
        if (res) {
          editFormik.initialValues = { ...initialValues, ...newItem };
          item.title = newItem?.title ?? item.title;
          itemtemporary!.title = newItem?.title ?? item.title;
          item.description = newItem?.description ?? item.description;
          itemtemporary!.description = newItem?.description ?? item.description;
        }
      });
    },
  });

  // listSupportedIntegrations
  useEffect(() => {
    listSupportedIntegrations().then((res) => {
      setIntegrations(res);
    });
    Subscription().then((res) => {
      setYourPlan(res);
    });
  }, []);

  const connectToStore = (data: any) => {
    const dataToPush = {
      integration_supports_type: data,
    };

    createStore(dataToPush).then((response) => {
      setVisible(false);
    });
  };
  const footerContent = (item: any) => {
    return (
      <div>
        {yourPlan ? (
          <>
            <Button
              label={t("importListCard.Cancel") ?? "Cancel"}
              icon="pi pi-times"
              iconPos={isRTL ? "right" : "left"}
              onClick={() => {
                setVisible(false);
              }}
              severity="danger"
              outlined
            />
            <Button
              label={t("importListCard.ConnectToStore") ?? "Connect Store"}
              icon="pi pi-check"
              iconPos={isRTL ? "right" : "left"}
              severity="danger"
              onClick={() => {
                connectToStore(selectedIntegration);
              }}
              autoFocus
            />{" "}
          </>
        ) : (
          <>
            <Button
              label={t("importListCard.Cancel") ?? "Cancel"}
              icon="pi pi-times"
              iconPos={isRTL ? "right" : "left"}
              onClick={() => {
                setVisible(false);
              }}
              severity="danger"
              outlined
            />
            <Button
              label={t("importListCard.ChooseAplan") ?? "Choose a plan"}
              /* {currentUser.wallet > 0
                  ? t("importListCard.ChooseAplan") ?? "Choose a plan"
                  : t("importListCard.ChargeWallet") ?? "charge wallet"
              } */
              icon="pi pi-check"
              iconPos={isRTL ? "right" : "left"}
              severity="danger"
              onClick={() => {
                window.location.href = "/plans";
              }}
              /* {currentUser.wallet > 0
                  ? () => {
                      window.location.href = "/plans";
                    }
                  : () => {
                      window.location.href = "/wallet";
                    }
              } */
              autoFocus
            />{" "}
          </>
        )}
      </div>
    );
  };
  const pushintegrtionOptions = (integrations: any) => {
    return integrations?.map((opt: any) => ({
      name: opt.type,
      value: opt.type,
    }));
  };
  const openModalConnect = () => {
    // setVisible(true);
    navigate("/integrations");
  };
  const tab3HeaderTemplateWrapper = (item: Item) => {
    return (options: any) => {
      return (
        <div
          className="flex align-items-center gap-2 p-3 p-tabview-nav-link"
          style={{ cursor: "pointer" }}
          onClick={options.onClick}
        >
          <span className="white-space-nowrap">
            {t("importListCard.Variants") ?? "Variants"}
          </span>
          <Badge
            value={item?.subProducts?.length.toString()}
            severity="danger"
          />{" "}
        </div>
      );
    };
  };
  return (
    <div className="card sm:p-1 md:m-2 md:p-2 lg:m-2 lg:p-2 xl:p-2 xl:m-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
      <form className="" onSubmit={editFormik.handleSubmit}>
        <TabView className="change-color">
          <TabPanel header={t("importListCard.Product") ?? "Product"}>
            <ListCard
              key={index}
              item={item}
              index={index}
              itemtemporary={itemtemporary}
              initialValues={editFormik.initialValues}
              handleChange={editFormik.handleChange}
            />
          </TabPanel>
          <TabPanel header={t("importListCard.Description") ?? "Description"}>
            <CardEditor
              key={index}
              content={item?.description}
              initialValues={editFormik.initialValues}
              handleChange={editFormik.setFieldValue}
              itemtemp={itemtemporary?.description}
            />
          </TabPanel>
          <TabPanel
            headerTemplate={tab3HeaderTemplateWrapper(item)}
            headerClassName="flex align-items-center"
          >
            <div className="card">
              <EditableTableTab
                item={item}
                initialValues={editFormik.values}
                handleChange={editFormik.setFieldValue}
                itemTemp={itemtemporary!}
              />
            </div>
          </TabPanel>
          <TabPanel header={t("importListCard.Images") ?? "Images"}>
            <div className="flex justify-content-center flex-wrap gap-5">
              {item.images.length > 0
                ? item.images?.map((image, index) => (
                    <ImageCard key={index} image={image} />
                  ))
                : t("importListCard.NoImagesFound") ?? "No Images Found"}
            </div>
          </TabPanel>
        </TabView>
        <div className="flex justify-end items-center my-4 gap-x-2">
          <Button
            label={labelBtn}
            severity="danger"
            type="button"
            tooltip={
              actionBtn === "Push To Store"
                ? t("importListCard.PushThisProductToTheStore") ??
                  "Push this product to the store"
                : t("importListCard.EnableTheStoreToPushYourProduct") ??
                  "Enable the store to push your product"
            }
            tooltipOptions={{
              showDelay: 1000,
              hideDelay: 300,
              position: "top",
            }}
            onClick={
              actionBtn === "Push To Store"
                ? () => openModal(item)
                : () => openModalConnect()
            }
          />
          <Button
            type="submit"
            label={t("importListCard.SaveChanges") ?? "Save Changes"}
            severity="danger"
            outlined
          />
          <Button
            type="button"
            label={t("importListCard.RemoveProduct") ?? "Remove Product"}
            className="change-color-btn-outlined"
            onClick={() => removeProduct(item)}
          />
        </div>
      </form>
      <Dialog
        header={
          t("importListCard.PleaseSelectTheStoreToConnect") ??
          "Please Select the Store to connect"
        }
        visible={visible}
        className="w-72 md:w-96 lg:w-96 xl:w-96"
        onHide={() => setVisible(false)}
        footer={footerContent(integrations)}
        key={item.id}
      >
        <div
          className="col-6 sm:col-12"
          style={{ textAlign: isRTL ? "right" : "left" }}
        >
          <Dropdown
            value={selectedIntegration}
            onChange={(e) => setSelectedIntegration(e.value)}
            options={pushintegrtionOptions(integrations)}
            optionLabel="name"
            placeholder={t("importListCard.PushToStore") ?? "Push To Store"}
            className="w-full md:w-14rem"
          />
        </div>

        <span className={yourPlan?.length > 0 ? "hidden" : ""}>
          {" "}
          {balance > 0
            ? t("importListCard.PleaseChooseAPlan") ?? "Please choose a plan"
            : t("importListCard.YouNeedToChargeYourWalletFirst") ??
              "You Need to Charge Your Wallet First and make a subscription"}
        </span>
      </Dialog>
    </div>
  );
}

export default ImportListCard;
