import Pusher from "pusher-js";
import { Bounce, toast } from "react-toastify";

const AppPusher = () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  if (token && user.id) {
    const _channel = `private-retailer.${user.id}`;
    const _event = "broad-cast-retailer";

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER as string,
      authEndpoint: `${process.env.REACT_APP_API_BASE_URL}socket/auth/check`,
      auth: {
        headers: {
          Authorization: "Bearer " + token,
          "Api-Secret": process.env.REACT_APP_API_PASSWORD,
        },
      },
    });

    const channel = pusher.subscribe(_channel);

    const handleNotification = (data: any) => {
      const title = data.data.title;
      const description = data.data.description || "";

      if (data.data && data.data.title) {
        toast(` ${title}: ${description}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: data.data.notifyType == "success" ? "light" : "dark",
          transition: Bounce,
          style: { backgroundColor: "#caf1d8", color: "#1da750" },
        });
      } else {
        console.error("Invalid notification data format:", data);
      }
    };

    channel.bind(_event, handleNotification);

    return () => {
      channel.unbind(_event, handleNotification);
      pusher.unsubscribe(_channel);
    };
  }

  return null;
};

export default AppPusher;
