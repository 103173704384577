import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import React, { useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { useFormik } from "formik";
import {
  CatalogueCustomEditDTO,
  Category,
} from "../../modules/Retailer.modules";
import { Item } from "../../modules/Retailer.modules";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ClearCart, exportCatalogue } from "../../services/index";
import { Sidebar } from "primereact/sidebar";
import { ColorPicker } from "primereact/colorpicker";
import { useTranslation } from "react-i18next";
import SearchBar from "../SearchBar";
import Filter from "./Filter";
import { Panel } from "primereact/panel";
import Ncart from "../Ncart";

type Props = {
  storeItem: Item[];
  cartItems: any[];
  storeId?: string;
  CartItemCount: number;
  handleSearch: (searchInput: any) => void;
  handleFilter: (searchInput: any) => void;
  deleteAllCartItems: (searchInput: any) => void;
  onClick: () => void;
  handleChange: (
    name: string,
    val: string | number | boolean | Category[]
  ) => void;
  setVisibleFilter: React.Dispatch<React.SetStateAction<boolean>>;
  onHideFilter: () => void;
  initialMinPrice?: number;
  initialMaxPrice?: number;
  initialAvailableProduct?: number;
  initialPublishedOnStore?: number;
  initialCategories?: Category[];
  isOpen: boolean;
  deleteFromStoreCart: (data: any) => void;
  increaseCartStore: (data: any) => void;
  decreaseCartStore: (data: any) => void;
  visibleFilter: boolean;
};
const StoreSearch = ({
  storeItem,
  cartItems,
  storeId,
  CartItemCount,
  handleSearch,
  handleFilter,
  handleChange,
  initialMinPrice,
  initialMaxPrice,
  initialAvailableProduct,
  initialPublishedOnStore,
  initialCategories,
  deleteAllCartItems,
  onClick,
  isOpen,
  decreaseCartStore,
  deleteFromStoreCart,
  increaseCartStore,
  onHideFilter,
  visibleFilter,
  setVisibleFilter,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visibleProduct, setVisibleProduct] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedLogo, setCheckedLogo] = useState(false);
  const [checkedProduct, setCheckedProduct] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Item[]>([]);
  const [visibleCart, setVisibleCart] = useState(isOpen);
  const [visibleFilterImport, setVisibleFilterImport] =
    useState<boolean>(false);

  const [panelVisible, setPanelVisible] = useState(true);
  const [filterNumber, setFilterNumber] = useState<string>(
    t("main.Filter") ?? "Filter"
  );
  const ref = useRef<Panel>(null);
  const isRTL = i18n.language === "ar";
  const formik = useFormik<CatalogueCustomEditDTO>({
    initialValues: new CatalogueCustomEditDTO(),
    onSubmit: (data) => {
      let datatoSend = {
        format: "pdf",
        connect_store_id: storeId,
        logo: data.showLogo,
        price: data.showPrice,
        color: `#${data.showColor ? data.showColor : "f50c44"}`,
        products: data.subProducts ? data.subProducts : [],
      };
      exportCatalogue(datatoSend).then((res) => {
        window.open(`${res.filePath}`, "_blank");
        setVisible(false);
      });
      // formik.resetForm();
    },
  });
  const exportCustomCatalogue = async () => {
    let dataToSend = {
      format: "pdf",
      connect_store_id: storeId,
    };

    let res = await exportCatalogue(dataToSend);
    window.open(`${res.filePath}`, "_blank");
  };
  const Clear = () => {
    let data = {
      connect_store_id: storeId,
    };
    deleteAllCartItems(data);
  };
  const items = [
    {
      label: t("storeSearch.PDFExport") ?? "PDFExport",
      icon: "pi pi-print",
      command: () => {
        exportCustomCatalogue();
      },
    },
    {
      label: t("storeSearch.CustomExport") ?? "Custom Export",
      icon: "pi pi-upload",
      command: () => {
        setVisible(true);
      },
    },
  ];

  const endContent = (
    <React.Fragment>
      <SplitButton
        label={t("storeSearch.Catalogue") ?? "Catalogue"}
        model={items}
        icon="pi pi-book"
        severity="danger"
        outlined
      ></SplitButton>
    </React.Fragment>
  );
  const footerContent = (
    <div className="mt-3">
      <Button
        label={t("storeSearch.No") ?? "No"}
        icon="pi pi-times"
        iconPos={isRTL ? "right" : "left"}
        onClick={() => setVisible(false)}
        className="p-button-text"
        severity="danger"
        outlined
      />
      <Button
        label={t("storeSearch.Yes") ?? "Yes"}
        type="submit"
        icon="pi pi-check"
        severity="danger"
        iconPos={isRTL ? "right" : "left"}
        onClick={(e: any) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        autoFocus
      />
    </div>
  );
  const footerContent1 = (
    <div className="mt-3">
      <Button
        label={t("storeSearch.No") ?? "No"}
        icon="pi pi-times"
        onClick={() => setVisibleProduct(false)}
        className="p-button-text"
        severity="danger"
        iconPos={isRTL ? "right" : "left"}
        outlined
      />
      <Button
        label={t("storeSearch.Select") ?? "Select"}
        type="button"
        icon="pi pi-check"
        severity="danger"
        onClick={() => {
          const productsId = selectedProducts?.map((product) => product.id);
          if (!productsId || !productsId.length) return;
          formik.setFieldValue("subProducts", productsId);
          setVisibleProduct(false);
        }}
        autoFocus
      />
    </div>
  );
  const toggleVisibility = (e: any) => {
    setVisibleFilter((prev: any) => !prev);
    ref.current?.expand(e);
  };
  return (
    <>
      <div className="flex justify-between flex-col md:flex-row lg:flex-row xl:flex-row mx-4 mb-2">
        <div className="flex flex-row md:w-1/2 lg:w-1/2 ">
          <SearchBar handleSearch={handleSearch} />
          <div
            className="flex items-start mb-3 ml-3"
            style={{ marginRight: "10px" }}
          >
            <Button
              label={filterNumber}
              severity="danger"
              icon="pi pi-filter"
              iconPos={isRTL ? "right" : "left"}
              raised
              className="text-white w-full"
              onClick={toggleVisibility}
            />
          </div>
        </div>
        <div className="flex flex-row md:flex-row lg:flex-row items-end">
          <div className="flex items-start mb-3 ml-3">
            <Button
              severity="danger"
              icon=" pi pi-bold pi-shopping-cart"
              iconPos={isRTL ? "right" : "left"}
              raised
              style={{ marginRight: "10px" }}
              label={String(CartItemCount)}
              onClick={onClick}
              className="text-white icon-font-size w-full px-4 py-2"
            ></Button>
          </div>

          <div
            className="flex items-end mb-3"
            style={{ direction: isRTL ? "ltr" : "ltr" }}
          >
            {endContent}
          </div>
        </div>

        {isOpen && (
          <div className="card flex justify-content-center">
            <Sidebar
              visible={isOpen}
              onHide={onClick}
              position="right"
              className="w-full md:w-30rem lg:w-40rem"
              header={
                <Button
                  icon="pi pi-trash"
                  onClick={() => Clear()}
                  severity="danger"
                  size="small"
                  outlined
                  className="mt-3"
                />
              }
            >
              <p>{isOpen}</p>

              {isOpen && (
                <Ncart
                  cartItems={cartItems}
                  deleteFromStoreCart={deleteFromStoreCart}
                  increaseCartStore={increaseCartStore}
                  decreaseCartStore={decreaseCartStore}
                />
              )}
            </Sidebar>
          </div>
        )}
      </div>
      <Dialog
        header={
          t("storeSearch.LetChooseWhatToCustomize") ??
          "Let's choose what to customize:"
        }
        visible={visible}
        className="w-96 sm:w-[400px] md:w-[450px] lg:w-[600px] xl:w-[650px]"
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <div
            className="flex flex-col px-3 py-5 gap-4"
            style={{ height: "43vh", overflowY: "auto" }}
          >
            <div className="flex flex-row justify-between gap-2">
              <label
                htmlFor="showLogo"
                className=" font-semibold text-slate-700"
              >
                {t("storeSearch.WouldYouLikeToAddAlogo") ??
                  "Would you like to add a logo?:"}
              </label>
              <InputSwitch
                checked={checkedLogo}
                onChange={(e) => {
                  formik.setFieldValue("showLogo", e.value);
                  setCheckedLogo(!checkedLogo);
                }}
                id="showLogo"
                name="showLogo"
              />
            </div>
            <div className="flex flex-row justify-between gap-2 text-slate-700">
              <label htmlFor="showPrice" className=" font-semibold">
                {t("storeSearch.WouldYouLikeToAddPriceToYourProducts") ??
                  "Would you like to add price to your products?"}
              </label>

              <InputSwitch
                id="showPrice"
                name="showPrice"
                checked={checked}
                onChange={(e) => {
                  formik.setFieldValue("showPrice", e.value);
                  setChecked(e.value);
                }}
                className="slider-color"
              />
            </div>
            <div className="flex flex-row justify-between gap-2 text-slate-700">
              <label htmlFor="showPrice" className=" font-semibold">
                {t("storeSearch.WouldYouLikeToChooseColorForTheCatalogue") ??
                  "Would you like to choose color for the catalogue?"}
              </label>

              <ColorPicker
                id="showColor"
                name="showColor"
                value={formik.values.showColor}
                onChange={(e) => {
                  formik.setFieldValue("showColor", e.value);
                }}
                size={5}
              />
            </div>
            <div className="flex flex-row justify-between gap-2">
              <label
                htmlFor="showProduct"
                className=" font-semibold text-slate-700"
              >
                {t("storeSearch.WouldYouLikeToChooseSpecificProducts") ??
                  "Would you like to choose specific products?"}
              </label>

              <Button
                id="showProduct"
                name="showProduct"
                label={t("storeSearch.BrowseProducts") ?? "Browse Products"}
                severity="danger"
                type="button"
                outlined
                onClick={(e) => {
                  setCheckedProduct(true);
                  setVisibleProduct(true);
                }}
              />
            </div>
          </div>
        </form>
      </Dialog>
      <Dialog
        header={
          t("storeSearch.LetChooseYourProduct") ?? "Let's choose Your Product:"
        }
        visible={visibleProduct}
        className="w-72 md:w-96 lg:w-96 xl:w-96"
        onHide={() => {
          setVisibleProduct(false);
        }}
        footer={footerContent1}
      >
        <div className="card">
          <DataTable
            value={storeItem}
            dataKey="id"
            selectionMode="checkbox"
            selection={selectedProducts}
            onSelectionChange={(e) => {
              setSelectedProducts([...e.value]);
            }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column field="id" header="Id" sortable />
            <Column
              field="title"
              header={t("storeSearch.Title") ?? "Title"}
              sortable
            />
          </DataTable>
        </div>
      </Dialog>
      {visibleFilter && (
        <Panel
          ref={ref}
          header={t("main.SelectYourFilter") ?? "Select Your Filter"}
          className="hide-close"
          toggleable={true}
          onToggle={() => setPanelVisible(false)}
        >
          <Filter
            visible={visibleFilter}
            onHide={() => {
              setVisibleFilter(false);
            }}
            handleFilter={handleFilter}
            handleChange={handleChange}
            setFilterNum={setFilterNumber}
            initialMinPrice={initialMinPrice}
            initialMaxPrice={initialMaxPrice}
            initialAvailableProduct={initialAvailableProduct}
            initialPublishedOnStore={initialPublishedOnStore}
            initialCategories={initialCategories}
            visibleFilter={visibleFilter}
            onHideFilter={onHideFilter}
          />
        </Panel>
      )}
    </>
  );
};

export default StoreSearch;
