import { InputText } from "primereact/inputtext";
import { Menubar } from "primereact/menubar";
import { Avatar } from "primereact/avatar";
import { useContext, useEffect, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext/AuthContext";
import { WalletInfo, getCoupon, listNotifications } from "../services";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import {
  CREATE_BANK_ACCOUNT,
  READ_BANK_ACCOUNTS,
  READ_COUPONS,
  READ_EMPLOYEES,
  READ_NOTIFICATIONS,
} from "../permissions/permissions";
import { Dropdown } from "primereact/dropdown";
import { Badge } from "primereact/badge";
import { OverlayPanel } from "primereact/overlaypanel";
import SharedDataContext from "../services/SharedData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBank, faBell, faGift, faSignOut, faUser, faUsers, faWallet } from "@fortawesome/free-solid-svg-icons";
const NavBar = () => {
  const menuLeft = useRef<any>(null);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [logo, setLogo] = useState<string>("");
  //const [balance, setBalance] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<any>();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { t, i18n } = useTranslation();

  const currentLang = localStorage.getItem("i18nextLng");

  const [selected, setSelected] = useState(
    currentLang === "ar" || currentLang === "ar-sy"
      ? "SA"
      : currentLang === "en"
      ? "US"
      : currentLang === "tr"
      ? "TR"
      : "US"
  );
  const [notifications, setNotifications] = useState<any>();
  const [displayedNotifications, setDisplayedNotifications] = useState<any>();
  const op = useRef<OverlayPanel | null>(null);
  const { balance } = useContext(SharedDataContext);

  useEffect(() => {
    if (!currentLang) {
      localStorage.setItem("i18nextLng", "en");
      i18n.changeLanguage("en");
      setSelected("US");
    }
    listNotifications().then((res) => {
      if (res !== undefined) {
        setNotifications(res);
        setDisplayedNotifications(res?.slice(0, 5));
      }
    });
  }, [currentLang, i18n]);
  const toggleOverlay = (e: any) => {
    if (op.current) {
      op.current.toggle(e);
    }
  };
  const isRTL = i18n.language === "ar";
  const logOut = () => {
    logout();
  };
  const notificationCount = notifications?.reduce(
    (acc: any, currentItem: any) => {
      return acc + 1;
    },
    0
  );

  const GetFullName = () => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      const userObject = JSON.parse(storedUser);
      const userFullName = userObject.fullName;
      const userFirstName = userObject.first_name;
      setLogo(userObject.retailer.logo);
      setFullName(userFullName);
      setFirstName(userFirstName);
    }
  };

  /* const GetBalance = () => {
    WalletInfo().then((res) => setBalance(res?.balance));
  }; */

  useEffect(() => {
    GetFullName();
    // GetBalance();
    getCoupon().then((res) => setCoupon(res));
  }, []);

  const items = [
    {
      label: fullName,
      items: [
        {
          label: t("profile.MyProfile") ?? "My Profile  asd",
          icon:<FontAwesomeIcon
          icon={faUser}
          className="mr-2"
        ></FontAwesomeIcon>,
          command: () => navigate("/profile"),
        },
        {
          label: t("profile.BanksAccounts") ?? "Banks Accounts",
          icon:<FontAwesomeIcon
          icon={faBank}
          className="mr-2"
        ></FontAwesomeIcon>,          command: () => navigate("/bank-accounts"),
          condition: READ_BANK_ACCOUNTS(),
        },
        {
          label: t("profile.MyCoupons") ?? "My Coupons",
          icon:<FontAwesomeIcon
          icon={faGift}
          className="mr-2"
        ></FontAwesomeIcon>,
          command: () => setShow(true),
          condition: READ_COUPONS(),
        },
        {
          label: t("profile.Employees") ?? "My Employees",
          icon:<FontAwesomeIcon
          icon={faUsers}
          className="mr-2"
        ></FontAwesomeIcon>,
          command: () => navigate("/employees"),
          condition: READ_EMPLOYEES(),
        },
        {
          label: t("profile.Logout") ?? "Log Out",
          icon:<FontAwesomeIcon
          icon={faSignOut}
          className="mr-2"
        ></FontAwesomeIcon>,
          command: () => logOut(),
        },
      ].filter((e: any) => (e.condition === undefined ? true : e.condition)),
    },
  ];

  const handleButtonClick = (e: any) => {
    toggleOverlay(e);
    navigate("/notifications");
  };

  const end = (
    <div className="flex flex-row flex-wrap" dir={isRTL ? "rtl" : "ltr"}>
      <h3 style={{ color: "red", marginLeft: "5px" }}>
        {" "}
        {balance === undefined ? 0 : balance}
      </h3>
      :<FontAwesomeIcon
          icon={faWallet}
          className="mx-2 mt-2"
          onClick={() => navigate("/wallet")}
          style={{ cursor: "pointer"}}
        ></FontAwesomeIcon>
      {READ_NOTIFICATIONS() ? (
        <i
          className="pi pi-bold pi-bell p-overlay-badge mx-2 mt-3 badge-style"
          style={{ cursor: "pointer", fontSize: "1rem" }}
          onClick={toggleOverlay}
        >
          <Badge value={notificationCount} severity="danger"></Badge>
        </i>
      ) : (
        <></>
      )}
      <OverlayPanel ref={(overlayRef) => (op.current = overlayRef)}>
        <div>
          {displayedNotifications?.map((notify: any, index: any) => (
            <div key={index} className="min-w-[300px] custom-box-shadow">
              <div className="flex items-center py-4 relative my-2">
                <div className={`${isRTL ? "mr-5" : "ml-5"}`}>
                  <h4 className="text-lg font-semibold leading-tight text-gray-900">
                    {notify.title}
                  </h4>
                  <p className="text-sm text-gray-600">{notify.description}</p>
                  <i
                    className={`pi pi-bold pi-bell p-overlay-badge mx-2 mt-3 absolute bottom-0 ${
                      isRTL ? "left-2 ml-3 top-3" : "right-2 mr-3 top-3"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={handleButtonClick}
                  ></i>
                  <span
                    className={`text-xs font-semibold uppercase m-1 py-1 text-gray-500 absolute bottom-0 ${
                      isRTL ? "left-0 ml-3" : "right-0 mr-3"
                    }`}
                  >
                    {notify.created_at}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {
            <div className="mt-2">
              <button
                onClick={handleButtonClick}
                style={{ color: "red", fontWeight: "bold", margin: "4px" }}
              >
                {t("profile.ShowAllNotifications") ?? "Show All Notifications"}
              </button>
            </div>
          }
        </div>
      </OverlayPanel>

      <ReactFlagsSelect
        selected={selected}
        onSelect={(code) => {
          setSelected(code);
          if (code === "SA") {
            i18n.changeLanguage("ar");
            window.location.reload();
          } else if (code === "US") {
            i18n.changeLanguage("en");
            window.location.reload();
          } else {
            i18n.changeLanguage(code.toLowerCase());
            window.location.reload();
          }
        }}
        countries={["US", "SA", "TR"]}
      />

      <Avatar
        image={logo}
        className="mx-2"
        onClick={(event) => menuLeft.current.toggle(event)}
        shape="circle"
        style={{ cursor: "pointer" }}
      />
      <p
        className={`margin-res mt-2 text-md font-bold ${
          isRTL ? "ml-4 " : "mr-4"
        }	`}
      >
        {" "}
        {firstName}
      </p>
    </div>
  );

  return (
    <div className="card p-2">
      <Menu model={items} popup ref={menuLeft} className="popup-left" />
      <Menubar
        //start={start}
        end={end}
        className="grid"
        dir={isRTL ? "rtl" : "ltr"}
      />

      <Dialog
        header={t("navbarSection.Coupons") ?? "Coupons"}
        visible={show}
        className="	md:w-40rem lg:w-40rem"
        onHide={() => setShow(false)}
        footer={
          <>
            <div>
              <Button
                label={t("navbarSection.Cancel") ?? "Cancel"}
                severity="danger"
                outlined
                size="small"
                onClick={() => setShow(false)}
                className="mt-4"
              ></Button>
            </div>
          </>
        }
      >
        <div dir={isRTL ? "rtl" : "ltr"}>
          {coupon && coupon.length > 0 ? (
            <DataTable
              value={coupon}
              stripedRows
              showGridlines
              className="mt-4"
            >
              <Column
                field="code"
                header={t("navbarSection.Coupon") ?? "Coupon"}
              ></Column>
              <Column
                field="type"
                header={t("navbarSection.Type") ?? "Type"}
              ></Column>
              <Column
                field="quantity"
                header={t("navbarSection.Quantity") ?? "Quantity"}
              ></Column>
              <Column
                field="discount_type"
                header={t("navbarSection.DiscountType") ?? "Discount Type"}
              ></Column>
              <Column
                field="discount_value"
                header={t("navbarSection.DiscountValue") ?? "Discount Value"}
              ></Column>
              <Column
                field="valid_from"
                header={t("navbarSection.ValidFrom") ?? "Valid From"}
              ></Column>
              <Column
                field="valid_until"
                header={t("navbarSection.ValidTo") ?? "Valid To"}
              ></Column>
            </DataTable>
          ) : (
            <p>{t("navbarSection.NoCouponsFound") ?? "No Coupons Found!"}</p>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default NavBar;
