import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useTranslation } from "react-i18next";

type Props = {
  classNameProp : string,
  done: any,
  pending:any;
  progress:any
}

export default function DoughnutChartDemo(props: Props) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    console.log('hi', props.progress)
    const data = {
      labels: [
        "Done Complaints",
         "Pending Complaints",
         "IN PROGRESS Complaints",
      ],
      datasets: [
        {
          data: [props.done, props.pending, props.progress],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };
    const options = {
      cutout: "70%",
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <div className="card flex justify-content-center">
      <Chart
        type="doughnut"
        data={chartData}
        options={chartOptions}
       className={props.classNameProp}
      />
    </div>
  );
}
