import React from "react";

function TurkeyFlag() {
  return (
    <div style={{ width: 20, height: "20", padding: 0, margin: 0 }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800">
        <path
          strokeMiterlimit={10}
          strokeWidth={12}
          fill="#E30A17"
          d="M0 0h1200v800H0z"
        />
        <circle cx="425" cy="400" r="200" fill="#fff" />
        <circle cx="475" cy="400" r="160" fill="#e30a17" />
        <path
          fill="#fff"
          d="M583.334 400l180.901 58.779-111.804-153.885v190.212l111.804-153.885z"
        />
      </svg>
    </div>
  );
}

export default TurkeyFlag;
