import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

interface Props {
  image: string;
}

function ImageCard(props: Props) {
  const [value, setValue] = useState("");
  const [showTextarea, setShowTextarea] = useState(false);
  const [showText, setShowText] = useState("Click to add alt");

  function showField(): React.ReactNode {
    if (showTextarea)
      return (
        <div className="flex">
          <textarea
            className="mt-2 p-2 border rounded"
            placeholder="Type something..."
            onBlur={() => setShowTextarea(false)}
            onFocus={() => setShowTextarea(true)}
            onChange={(e) => setShowText(e.target.value)}
          />
        </div>
      );
  }

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg">
      <div className="card flex justify-content-center">
        <img src={props.image} alt="Image" width="200" />
      </div>

      {/*  <div className="grid px-6 py-2 mb-8">
        <div className="card flex justify-content-center ">
          <button
            className="text-md"
            onClick={() => setShowTextarea(!showTextarea)}
          >
            {showText}{" "}
          </button>
        </div>
        {showField()}
      </div> */}
    </div>
  );
}

export default ImageCard;
