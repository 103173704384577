import React, { useContext, useRef, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import logo from "../styles/images/doshtulogo.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  CREATE_COMPLAINTS,
  READ_COMPLAINTS,
  READ_CONNECT_STORES,
  READ_END_CUSTOMERS,
  READ_INTEGRATION_SUPPORTS,
  READ_ORDERS,
  READ_PRODUCTS,
} from "../permissions/permissions";
import { Avatar } from "primereact/avatar";
import "primeicons/primeicons.css";
import { Link } from "react-router-dom";
import SharedDataContext from "../services/SharedData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faChartSimple, faFileEdit, faList, faRocket, faStore, faTruckField, faUser } from "@fortawesome/free-solid-svg-icons";

function NSideBar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { stores } = useContext(SharedDataContext);
  const [isRTL, setIsRTL] = useState(i18n.language === "ar");

  const storeItems = Array.isArray(stores)
    ? stores?.map((store: any) => ({
        label: `${store.integrationSupport.type}`,
        icon: <span className="pi pi-cloud"></span>,
        command: () => (window.location.href = `/stores/${store.id}`),
      }))
    : [];

  const sideLinks = [
    {
      label: t("Analytics"),
      icon: <FontAwesomeIcon
      icon={faChartSimple}
    ></FontAwesomeIcon>,
      command: () => navigate("/home"),
    },
    {
      label: t("sidebar.Products") ?? "Products",
      icon: <FontAwesomeIcon
      icon={faCartShopping}
    ></FontAwesomeIcon>,
      command: () => navigate("/products"),
      condition: READ_PRODUCTS(),
    },
    {
      label: t("sidebar.ImportList") ?? "Import List",
      icon: <FontAwesomeIcon
      icon={faList}
    ></FontAwesomeIcon>,
      command: () => navigate("/import-list"),
    },
    {
      label: t("sidebar.Orders") ?? "Orders",
      icon: <FontAwesomeIcon
      icon={faTruckField}
    ></FontAwesomeIcon>,
      command: () => navigate("/orders"),
      condition: READ_ORDERS(),
    },
    {
      label: t("sidebar.Stores") ?? "Stores",
      icon: <FontAwesomeIcon
      icon={faStore}
    ></FontAwesomeIcon>,
      items: storeItems,
      condition: READ_CONNECT_STORES(),
    },
    {
      label: t("sidebar.Integrations") ?? "Integrations",
      icon: <FontAwesomeIcon
      icon={faStore}
    ></FontAwesomeIcon>,
      command: () => navigate("/integrations"),
      condition: READ_INTEGRATION_SUPPORTS(),
    },
    {
      label: t("sidebar.Plans") ?? "Plans",
      icon: <FontAwesomeIcon
      icon={faRocket}
    ></FontAwesomeIcon>,
      command: () => navigate("/plans"),
      condition: READ_END_CUSTOMERS(),
    },
    {
      label: t("sidebar.EndCustomers") ?? "End Customers",
      icon: <FontAwesomeIcon
      icon={faUser}
    ></FontAwesomeIcon>,
      command: () => navigate("/end-customers"),
      condition: READ_END_CUSTOMERS(),
    },
    {
      label: t("sidebar.Support") ?? "Support",
      icon: <FontAwesomeIcon
      icon={faFileEdit}
    ></FontAwesomeIcon>,
      command: () => navigate("/support"),
      condition: READ_COMPLAINTS(),
    },
  ].filter((e: any) => (e.condition === undefined ? true : e.condition));
  return (
    <Sidebar collapsed={isCollapsed} rtl={isRTL}>
      <div
        className="flex align-items-center gap-2 py-2 px-4  "
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div>
          <Avatar image={logo} shape="circle" />
        </div>
        <h2 className="font-bold px-3">DOSHTU</h2>
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ display: "fixed" }}
        >
          <span className="pi pi-times"></span>
        </div>
      </div>
      <Menu
        menuItemStyles={{
          button: {
            // the active class will be added automatically by react router
            // so we can use it to style the active menu item
            [`&.active`]: {
              backgroundColor: "#fff",
              color: "#b6c8d9",
            },
          },
        }}
      >
        {sideLinks.map((item) =>
          item.items && item.items?.length > 0 ? (
            <SubMenu label={item.label} key={item.label}>
              {item.items.map((subItem, index) => {
                return (
                  <MenuItem
                    key={`subitem-${index}`}
                    icon={subItem.icon}
                    onClick={subItem.command}
                  >
                    {subItem.label}
                  </MenuItem>
                );
              })}
            </SubMenu>
          ) : (
            <MenuItem key={item.label} icon={item.icon} onClick={item.command}>
              {item.label}
            </MenuItem>
          )
        )}
      </Menu>
    </Sidebar>
  );
}

export default NSideBar;
