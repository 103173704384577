import { loadavg } from "os";
import React, {
  createContext,
  useState,
  ReactNode,
  useRef,
  useEffect,
} from "react";
import { boolean } from "yup";
import { getCategories, listStores, WalletInfo } from ".";
import { Category, Item, ItemStore } from "../modules/Retailer.modules";

interface SharedDataContextType {
  items: Item[];
  stores: ItemStore[];
  categories: Category[];
  balance: number;
  isSuccess: boolean;
  addItem: (item: Item) => void;
  addStore: (store?: ItemStore) => void;
  removeItem: (item: Item) => void;
  updateAmount: (num: number) => void;
  updateBalance: (num: number) => void;
  updateChargeState: (chargeState: boolean) => void;
}

export const SharedDataContext = createContext<SharedDataContextType>({
  items: [] as Item[],
  stores: [] as ItemStore[],
  categories: [] as Category[],
  balance: 0,
  isSuccess: false,
  addItem: () => {},
  addStore: () => {},
  removeItem: () => {},
  updateAmount: () => {},
  updateBalance: () => {},
  updateChargeState: () => {},
});

interface SharedDataProviderProps {
  children: ReactNode;
}

export const SharedDataProvider: React.FC<SharedDataProviderProps> = ({
  children,
}) => {
  let [items, setItems] = useState<Item[]>([]);
  let [stores, setStores] = useState<ItemStore[]>([]);
  let [balance, setBalance] = useState<any>();
  let [amount, setAmount] = useState<any>();
  let [isSuccess, setIsSuccess] = useState<boolean>(false);
  let [categories, setCategories] = useState<Category[]>([]);
  let userInfo = localStorage.getItem("user");
  let userWallet = JSON.parse(userInfo!);
  useEffect(() => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      listStores().then((res: any) => {
        if (res !== undefined) {
          if (!("image" in res)) {
            setStores(res);
          }
        }
      });
      WalletInfo().then((res) => {
        setBalance(res?.balance);
        userWallet.wallet = res?.balance;
        localStorage.setItem("user", JSON.stringify(userWallet));
      });

      getCategories().then((res) => {
        setCategories(res);
      });
    }
  }, []);

  const addStore = (newStore?: any) => {
    if (stores.length === 0) {
      setStores([newStore]);
    } else {
      setStores((prevItems) => [...prevItems, newStore]);
    }
  };

  const addItem = (item: Item) => {
    setItems((prevItems) => [...prevItems, item]);
  };
  const removeItem = (data: Item) => {
    setItems((prevItems: Item[]) =>
      prevItems.filter((item) => item.id !== data.id)
    );
  };
  const updateAmount = (num: number) => {
    setAmount(num);
  };
  const updateChargeState = (chargeState: boolean) => {
    setIsSuccess((prev) => chargeState);

    if (chargeState === true) {
      setBalance((prev: number) => prev + amount);
    }
  };
  const updateBalance = (num: number) => {
    setBalance((prev: number) => num);
  };

  return (
    <SharedDataContext.Provider
      value={{
        items,
        addItem,
        removeItem,
        stores,
        addStore,
        balance,
        isSuccess,
        updateAmount,
        updateChargeState,
        categories,
        updateBalance,
      }}
    >
      {children}
    </SharedDataContext.Provider>
  );
};

export default SharedDataContext;
