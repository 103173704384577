import React, { ChangeEvent, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import { Category, FilterDTO } from "../../modules/Retailer.modules";
import SearchBar from "../SearchBar";
import { Panel } from "primereact/panel";

type Props = {
  handleSearch: (searchInput: any) => void;
  handleFilter: (searchInput: any) => void;
  handleChange: (
    name: string,
    val: string | number | boolean | Category[]
  ) => void;
  onHideFilter: () => void;
  setVisibleFilter: React.Dispatch<React.SetStateAction<boolean>>;
  categories: Category[];
  initialMinPrice?: number;
  initialMaxPrice?: number;
  initialAvailableProduct?: number;
  initialPublishedOnStore?: number;
  initialCategories?: Category[];
  visibleFilter: boolean;
};
const Search: React.FC<Props> = ({
  handleSearch,
  categories,
  handleFilter,
  handleChange,
  onHideFilter,
  setVisibleFilter,
  initialMinPrice,
  initialMaxPrice,
  initialAvailableProduct,
  initialPublishedOnStore,
  initialCategories,
  visibleFilter,
}) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";
  const [visible, setVisible] = useState<boolean>(false);
  const [filterNumber, setFilterNumber] = useState<string>(
    t("main.Filter") ?? "Filter"
  );
  const ref = useRef<Panel>(null);
  const toggleVisibility = (e: any) => {
    setVisibleFilter((prev: any) => !prev);
    ref.current?.expand(e);
  };
  return (
    <>
      <div className="flex justify-between flex-col md:flex-row lg:flex-row xl:flex-row mb-2">
        <SearchBar handleSearch={handleSearch} />
        <div className="flex flex-row md:flex-row lg:flex-row items-end">
          <div
            className="flex items-start mb-3 ml-3"
            style={{ marginRight: "10px" }}
          >
            <Button
              //label={t("main.Filter") ?? "Filter" }
              label={filterNumber}
              severity="danger"
              icon="pi pi-filter"
              iconPos={isRTL ? "right" : "left"}
              raised
              className="text-white w-full"
              onClick={toggleVisibility}
            ></Button>
          </div>
        </div>
      </div>

      {visibleFilter && (
        <Panel
          ref={ref}
          header={t("main.SelectYourFilter") ?? "Select Your Filter"}
          toggleable={true}
          className="hide-close"
        >
          <Filter
            visible={visibleFilter}
            onHide={() => setVisibleFilter(false)}
            handleFilter={handleFilter}
            handleChange={handleChange}
            setFilterNum={setFilterNumber}
            initialMinPrice={initialMinPrice}
            initialMaxPrice={initialMaxPrice}
            initialAvailableProduct={initialAvailableProduct}
            initialPublishedOnStore={initialPublishedOnStore}
            initialCategories={initialCategories}
            visibleFilter={visibleFilter}
            onHideFilter={onHideFilter}
          />
        </Panel>
      )}
    </>
  );
};

export default Search;
